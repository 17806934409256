import styled from 'styled-components';
import { Row, FlexItem } from 'styles/commons';
import { screenSizeSmall } from 'styles/screenSize';
import { Link } from 'react-router-dom';
import { LightBlack, Pink, White, Orange, Background } from '../../styles/colors';

export const GlobalSearchWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${Background};
    z-index: 60;
`;

export const GlobalSearchInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const CloseGlobalSearchButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -32px;

    &:hover {
        svg {
            path {
                fill: #c70b6f;
                transition: 0.6s ease;
            }
        }
    }
    @media screen and (max-width: 800px) {
        margin-top: -42px;

        & svg {
            width: 30px;
            height: 30px;
        }
    }
`;
export const GlobalSearchInputWrapper = styled.div`
    padding: 2rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 87.5rem;

    &:hover {
        transition: 0.3s ease;
    }

    @media screen and (max-width: 800px) {
        min-width: auto;
        padding-top: 0px;
        width: 100%;
        padding-right: 60px;
    }

    @media screen and (max-width: 400px) {
        padding-right: 20px;
    }
`;
export const GlobalSearchButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.5rem;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-bottom: 20px;
    margin-top: -30px;
    z-index: 30;
    overflow: inherit;

    @media screen and (max-width: 800px) {
        padding-bottom: 20px;
        margin-top: -30px;
    }

    @media screen and (max-width: 400px) {
        padding-bottom: 20px;
        margin-top: -30px;
    }
`;

export const GlobalSearchButtonInner = styled.button`
    &:hover {
        cursor: pointer;
        transition: 0.3s ease;
        background: #ff7100;
        color: #fff;
    }
    cursor: pointer;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #292c2f;
    background: none;
    padding: 0.8rem 1.6rem;
    border: 0.1rem solid #ff7100;
    white-space: nowrap;
    margin-right: 1rem;
    border-radius: 0.8rem;
    &.active {
        cursor: pointer;
        transition: 0.3s ease;
        background: #ff7100;
        color: #fff;
    }
    &.active:active {
        cursor: pointer;
        transition: 0.3s ease;
        background: #ff7100;
        color: #fff;
    }
`;
export const GlobalSearchItems = styled.div`
    overflow-y: scroll;
`;
export const NewsBlock = styled.div`
    padding-top: 4rem;
    overflow: auto;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
        width: 0;  
        background: transparent;  
    overflow-x: hidden;
    flex-grow: 1;
`;
export const Item = styled(FlexItem)`
    margin-top: 5rem;
    display: flex;
    flex: 1 100%;
    @media screen and (max-width: ${screenSizeSmall}) {
        flex-direction: column;
    }
`;

export const Image = styled.img`
    max-width: 33rem;
    @media screen and (max-width: ${screenSizeSmall}) {
        max-width: 100%;
    }
`;

export const SliderBlock = styled.div`
    max-width: 33rem;
    /* padding: 1rem; */
    position: relative;
    /* height: fit-content; */
    ${'' /* background: linear-gradient(to right, #ef7c00, #c9106b); */}
    padding: 0.3rem;
    margin-right: 3rem;
    .image-gallery-slides {
        width: 32rem;
    }
    @media screen and (max-width: ${screenSizeSmall}) {
        width: 100%;

        .image-gallery-slides {
            width: 100%;
        }
    }
`;

export const RowBlock = styled(Row)`
    flex-wrap: nowrap;
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    position: relative;
    flex: 1 100%;
    @media screen and (max-width: ${screenSizeSmall}) {
        padding: 0;
        margin-top: 1rem;
    }
`;

export const Title = styled(Link)`
    color: ${LightBlack};
    font-size: 2.6rem;
    margin: 0 0 1rem 0;
`;

export const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0rem;
    @media screen and (max-width: ${screenSizeSmall}) {
        margin: 1.5rem 0rem;
        position: relative;
    }
`;

export const Button = styled(Link)`
    background-color: ${Pink};
    color: ${White};
    text-align: center;
    /* position: absolute; */
    /* width: 100%; */
    padding: 1.5rem;
    margin-right: 2rem;
    /* bottom: 0rem; */
    min-width: 17rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: ${screenSizeSmall}) {
        position: relative;
        padding: 1rem;
        min-width: 10rem;
        font-size: 1.5rem;
    }
`;

export const ButtonImg = styled.a`
    bottom: 0rem;
    display: flex;
    flex-direction: row;
    margin-right: 2rem;
    justify-content: space-between;
    @media screen and (max-width: ${screenSizeSmall}) {
        margin-right: 1rem;

        img {
            width: 13rem;
            height: 4.3rem;
        }
    }
`;

export const TitleButton = styled.p`
    color: ${White};
    font-weight: 800;
`;

export const ButtonImage = styled(Link)`
    min-width: 33rem;
    line-height: 0;
    @media screen and (max-width: ${screenSizeSmall}) {
        width: 100%;
    }
`;

export const Pagination = styled.div`
    margin: 3rem auto;
    text-align: center;
    padding-top: 3.2rem;
    @media (max-width: 768px) {
        padding-top: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-top: 2rem;
    }
`;

export const ContainerLetters = styled.div`
    display: flex;
    flex-wrap: wrap;
    alignitems: center;
    margin-top: 1rem;
`;

export const ButtonLetter = styled.button`
    color: #000;
    background: #fff;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-right: 1rem;
`;

export const MatchedLetter = styled.span`
    color: ${Background};
    background-color: ${Orange};
    transition: 0.6s ease;
`;

export const Date = styled.p`
    color: ${White};
    display: flex;
    flex-direction: row;
`;

export const Icon = styled.div`
    margin-right: 0.5rem;
`;

export const AutocompleteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 6.2rem;
    align-items: start;

    border-radius: 0.8rem;
    background: var(--unnamed, #fff);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    z-index: 1000;

    @media screen and (max-width: 400px) {
        width: calc(100vw - 3.5rem);
    }

    @media screen and (max-width: 370px) {
        width: calc(100vw - 2.2rem);
    }

    @media screen and (max-width: 330px) {
        width: calc(100vw - 3rem);
    }
`;

export const AutocompleteItem = styled.p`
    color: #2b2d33;
    font-family: 'HelveticaNeueCyr';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
    width: 100%;
    padding: 1.6rem;
    cursor: pointer;

    :hover {
        color: #ff7100;
    }
`;
