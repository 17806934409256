/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef, useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import RemoveSlash from '../components/HOC/RemoveSlash/RemoveSlash';
import HomePage from './HomePage/HomePage';
import Contacts from './ContactsPage/ContactsPage';
import { MissionPage } from './AboutDropdown/MissionPage';
import { OurHistoryPage } from './AboutDropdown/OurHistoryPage';
import { PartnersPage } from './PartnersPage';
import TeamPage from './AboutDropdown/TeamPage/TeamPage';
import CareerPage from './AboutDropdown/CareerPage/CareerPage';
import { ProductsListPage } from './ProductsListPage';
import { ProductItemPage } from './ProductsListPage/ProductsItemPage';
import PublicationItemPage from './PromohubPage/PublicationsListPage/PublicationItemPage/PublicationItemPage';
import VideoItemPage from './PromohubPage/VideosListPage/VideosItemPage/VideoItemPage';
import NewsItemPage from './PromohubPage/NewsListPage/NewsItemPage/NewsItemPage';
import { ThanksPage } from './ThanksPage';
import ErrorPage from './ErrorPage/ErrorPage';
import PromohubPage from './PromohubPage/PromohubPage';
import PoliticsCookie from './PoliticsCookie';
import { Agreement } from './Agreement';
import { CalculatorRakaGrudiPage } from './CalculatorRakaGrudiPage';
import RiskRakaMZ from './RiskRakaMZ/index';

export const Routes = {
    HOME: '/',
    OUR_MISSION: '/our-values',
    OUR_HISTORY: '/our-history',
    TEAM: '/team',
    CAREER: '/career',
    CONTACTS: '/contacts',
    PARTNERS: '/partners',
    PRODUCTS: '/products',
    PRODUCT: '/products/:catalogId',
    NEWS_ID: '/news/:newsId',
    VIDEO_ID: '/video/:videoId',
    PROMOHUB: '/promohub',
    PUBLICS_ID: '/publics/:publicsId',
    THANKS: '/thanks-page',
    COOKIE_POLICY: '/cookie-policy',
    NEWS: '/news',
    VIDEO: '/video',
    PUBLICS: '/publics',
    AGREEMENT: '/agreement',
    CALCULATOR_RAKA_GRUDI: '/calculator-dlya-rascheta-raka-grudi',
    CALCULATOR_MOLOCHNOI_ZHELEZY: '/risk-raka-molochnoy-zhelezy',
};

const Router = () => {
    const location = useLocation();
    const prevLocation = useRef(location);
    useLayoutEffect(() => {
        if (prevLocation.current !== null && prevLocation.current.pathname !== location.pathname) {
            window.scrollTo(0, 0);
        }
        prevLocation.current = location;
    }, [location]);

    return (
        <>
            <RemoveSlash />
            <Switch>
                <Route exact path={Routes.HOME} component={HomePage} />
                <Route exact path={Routes.OUR_MISSION} component={MissionPage} />
                <Route exact path={Routes.OUR_HISTORY} component={OurHistoryPage} />
                <Route exact path={Routes.TEAM} component={TeamPage} />
                <Route exact path={Routes.CAREER} component={CareerPage} />
                <Route exact path={Routes.CONTACTS} component={Contacts} />
                <Route exact path={Routes.PARTNERS} component={PartnersPage} />
                <Route exact path={Routes.PRODUCTS} component={ProductsListPage} />
                <Route exact path={Routes.PRODUCT} component={ProductItemPage} />
                <Route exact path={Routes.PROMOHUB} component={PromohubPage} />
                <Route exact path={Routes.NEWS_ID} component={NewsItemPage} />
                <Route exact path={Routes.VIDEO_ID} component={VideoItemPage} />
                <Route exact path={Routes.PUBLICS_ID} component={PublicationItemPage} />
                <Route exact path={Routes.THANKS} component={ThanksPage} />
                <Route exact path={Routes.COOKIE_POLICY} component={PoliticsCookie} />
                <Route exact path={Routes.AGREEMENT} component={Agreement} />
                <Route exact path={Routes.CALCULATOR_RAKA_GRUDI} component={CalculatorRakaGrudiPage} />
                <Route exact path={Routes.CALCULATOR_MOLOCHNOI_ZHELEZY} component={RiskRakaMZ} />
                <Route exact path={Routes.PUBLICS}>
                    <Redirect to="/promohub?category=0" />
                </Route>
                <Route exact path={Routes.VIDEO}>
                    <Redirect to="/promohub?category=1" />
                </Route>
                <Route exact path={Routes.NEWS}>
                    <Redirect to="/promohub?category=2" />
                </Route>
                <Route component={ErrorPage} />
            </Switch>
        </>
    );
};

export default Router;
