/* eslint-disable react/prop-types */ //
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { BreadCrumbsUl, BreadCrumbsWrapper, BreadCrumbsSeparator, BreadCrumbsContainer } from './BreadCrumbs.styles';

export default function BreadCrumbs({ value, colorDefault, colorActive }) {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const breadcrumbs = pathnames.map((name, index) => {
        let currentName;
        switch (name) {
            case 'our-values':
                currentName = 'Наши ценности';
                break;
            case 'our-history':
                currentName = 'Наша история';
                break;
            case 'team':
                currentName = 'Команда';
                break;
            case 'career':
                currentName = 'Карьера';
                break;
            case 'partners':
                currentName = 'Партнерам';
                break;
            case 'cardiology':
                currentName = 'Болезни сердца';
                break;
            case 'endokrinology':
                currentName = 'Эндокринология';
                break;
            case 'gynecology':
                currentName = 'Гинекология';
                break;
            case 'gastroenterology':
                currentName = 'Гастроэнтерология';
                break;
            case 'neurology':
                currentName = 'Неврология';
                break;
            case 'dermatology':
                currentName = 'Дерматология';
                break;
            case 'products':
                currentName = 'Наши продукты';
                break;
            case 'vacancy':
                currentName = 'Вакансии';
                break;
            case 'promohub':
                currentName = 'PromoHub';
                break;
            case 'publics':
                currentName = 'Публикации';
                break;
            case 'video':
                currentName = 'Видео';
                break;
            case 'news':
                currentName = 'Новости';
                break;
            case 'contacts':
                currentName = 'Контакты';
                break;
            case 'calculator-dlya-rascheta-raka-grudi':
                currentName = 'Калькулятор для расчета риска рака груди';
                break;
            default:
                currentName = '';
                break;
        }

        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const key = uuidv4();
        return (
            <BreadCrumbsWrapper key={key} colorDefault={colorDefault}>
                <BreadCrumbsSeparator>&nbsp;/&nbsp;</BreadCrumbsSeparator>
                <Link to={routeTo}>{currentName}</Link>
            </BreadCrumbsWrapper>
        );
    });

    if (value) {
        breadcrumbs.push(
            <BreadCrumbsWrapper key="additional-breadcrumbs" colorDefault={colorDefault}>
                <Link href="/">{value}</Link>
            </BreadCrumbsWrapper>,
        );
    }

    return (
        <BreadCrumbsContainer>
            <nav style={{ width: '100%' }}>
                <BreadCrumbsUl colorActive={colorActive}>
                    {breadcrumbs.length > 0 && (
                        <BreadCrumbsWrapper colorDefault={colorDefault}>
                            <Link to="/">Главная</Link>
                        </BreadCrumbsWrapper>
                    )}
                    {breadcrumbs}
                </BreadCrumbsUl>
            </nav>
        </BreadCrumbsContainer>
    );
}
