/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
import { fetchVideoId } from 'services/NewsService';
import { PromohubSingle } from '../../../../components/common/PromohubSingle';
// import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
// import { Container, Row } from 'styles/commons';
// import Loader from 'components/atoms/Loader';
// import PopularNewsRightBlock from 'components/molecules/PopularNewsRightBlock';
// import { Redirect } from 'react-router-dom';
// import Header from '../../../components/common/Header/Header';
// import Footer from '../../../components/common/Footer/Footer';
// import { PostContainer, NewsPageContainer, News, Title, Text } from './styles';
// import { BreadCrumbs } from '../../../components/common/BreadCrumbs';

const VideoItemPage = (props) => {
    const [loader, setLoader] = useState(true);
    const [video, setVideo] = useState([]);

    const getVideo = (id) => {
        fetchVideoId(id)
            .then((response) => {
                setVideo(response.data.video);
                setLoader(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    useEffect(() => {
        const idVideo = props.match.params.videoId;
        getVideo(idVideo);
    }, [props.match.params.videoId]);

    return <PromohubSingle promohub={video} loader={loader} />;
};

export default VideoItemPage;
