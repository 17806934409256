import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container, ContainerFull } from '../../../../styles/commons';
import HistorySectionItem from './HistorySectionItem/HistorySectionItem';
import { ourHistory } from '../../../../commons/ourHistory';
import { HistorySectionContainer, HistorySectionSeparator } from './HistorySection.styles';

export default function HistorySection() {
    return (
        <ContainerFull paddingBottom="12rem">
            <Container>
                <HistorySectionContainer>
                    {ourHistory.map((item) => {
                        const key = uuidv4();
                        const { year, paragraph, paragraphBold } = item;
                        return (
                            <HistorySectionItem
                                key={key}
                                title={year}
                                paragraph={paragraph}
                                paragraphBold={[paragraphBold]}
                                justifyContent="flex-end"
                            />
                        );
                    })}
                    <HistorySectionSeparator />
                </HistorySectionContainer>
            </Container>
        </ContainerFull>
    );
}
