import styled from 'styled-components';
// import { LightBlack } from 'styles/colors';
// import { screenSizeSmall } from 'styles/screenSize';
import { Pink, Orange, White } from 'styles/colors';
import { screenSizeSmall } from 'styles/screenSize';

export const CalculatorHeader = styled.div`
    color: ${White};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    h1 {
        margin-bottom: 8rem;
        @media (max-width: 768px) {
            margin-bottom: 6rem;
        }
        @media (max-width: 480px) {
            margin-bottom: 6rem;
        }
    }
`;
export const CalculatorOptions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    & > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    h3 {
        margin-bottom: 1.6rem;
        @media (max-width: 768px) {
            margin-bottom: 0.8rem;
        }
    }
`;
export const CalculatorSelect = styled.select`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem;
    margin-right: 0.8rem;
    border: 0.1rem solid #ff7100;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
    @media (max-width: 480px) {
        margin-bottom: 0.4rem;
    }
`;
export const HeadlineLightDesc = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const HeadlineLight = styled.div`
    padding: 2rem 0;
    font-weight: 600;
    text-align: center;
`;
export const FactorRiska = styled.div`
    display: flex;
    & > p {
        strong {
        }
    }
`;

export const CalculatorContainer = styled.section`
    margin-top: 12rem;
`;

export const ResultFlex = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: ${screenSizeSmall}) {
        flex-direction: column;
    }
`;

export const ResultBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 8rem;
    margin-bottom: 8rem;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 6rem;
        margin-bottom: 6rem;
        gap: 2rem;
    }
`;

export const ResultCountContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${screenSizeSmall}) {
        width: 100%;
    }
    background-color: #c70b6f;
    border-radius: 3.2rem;
    padding: 3rem;
`;
export const ResultTitle = styled.h3`
    font-size: 3.2rem;
    line-height: 3.8rem;
    margin-bottom: 3.2rem;
    color: ${White};
    @media (max-width: 768px) {
        margin-bottom: 2.8rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 2.4rem;
    }
`;

export const Result = styled.div`
    background-color: ${White};
    color: ${Pink};
    padding: 4rem;
    font-size: 15rem;
    font-weight: 900;
    text-align: center;
    border-radius: 2.4rem;
    @media (max-width: 768px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        font-size: 5rem;
        line-height: 6.2rem;
    }
`;

export const FormulaDescriptionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 2rem;
    }
    & > p {
        width: 48%;
        gap: 2rem;
        padding: 2rem;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f0f0f0;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
`;
export const FormulaDesc = styled.div`
    width: 48%;
    padding: 1rem;
    background-color: ${White};
    border-radius: 2.4rem;
    background-color: #d9dadb;
    font-size: 3.2rem;
    line-height: 3.8rem;
`;

export const PinkSpan = styled.span`
    color: ${Pink};
    font-weight: 900;
`;

export const FormulaDescriptionBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormulaDescription = styled.p`
    color: #666b6e;
    font-size: 1.4rem;
    margin-bottom: 2rem;
`;

export const Select = styled.select`
    /* styling */
    background-color: white;
    border: thin solid blue;
    border-radius: 0.4rem;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    width: 100%;

    /* reset */

    margin: 0;
    margin-bottom: 2rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 2rem) calc(1em + 0.2rem), calc(100% - 1.5rem) calc(1em + 0.2rem),
        calc(100% - 2.5em) 0.5em;
    background-size: 0.5rem 0.5rem, 0.5rem 0.5rem, 0.1rem 1.5em;
    background-repeat: no-repeat;

    &:active {
        background-image: linear-gradient(45deg, green 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, #ccc, #ccc);
        background-position: calc(100% - 1.5rem) 1em, calc(100% - 2rem) 1em, calc(100% - 2.5em) 0.5em;
        background-size: 0.5rem 0.5rem, 0.5rem 0.5rem, 0.1rem 1.5em;
        background-repeat: no-repeat;
        border-color: green;
        outline: 0;
    }
`;

export const Option = styled.option``;

export const Title = styled.h3`
    color: ${Pink};
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media screen and (max-width: ${screenSizeSmall}) {
        // margin-top: 3rem;
    }
`;

export const TitleBlock = styled.div`
    width: 100%;
    margin: 1.5rem 0;
`;

export const BoldOrange = styled.span`
    color: ${Orange};
    font-weight: 900;
`;
