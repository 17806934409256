/* eslint-disable no-undef */
import styled from 'styled-components';
import { Background, HoverColor, PrimaryColor } from '../../../../styles/colors';

export const ModalContainer = styled.div`
    border: 0.1rem solid ${PrimaryColor};
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.08);
    width: 43rem;
    height: 31rem;
    background: ${Background};
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding: 4.4rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    transition: 0.6s ease;
    :hover {
        border-color: ${HoverColor};
        transition: 0.6s ease;
    }
    @media (max-width: 768px) {
        border-color: ${PrimaryColor};
        transition: 0.6s ease;
    }
    svg {
        position: absolute;
        right: 1.6rem;
        top: 1.6rem;
        cursor: pointer;
        path {
            transition: 0.6s ease;
        }
        :hover {
            path {
                transition: 0.6s ease;
                fill: ${HoverColor};
                @media (max-width: 768px) {
                    fill: ${PrimaryColor};
                }
            }
        }
    }
    @media (max-width: 768px) {
        width: 32rem;
        height: 32rem;
        padding: 3.2rem;
    }
    @media (max-width: 480px) {
        width: 28rem;
        height: 28rem;
    }
`;

export const Title = styled.h2`
    font-size: 3.2rem;
    line-height: 3.8rem;
    padding-bottom: 1.6rem;
    @media (max-width: 480px) {
        font-size: 2.8rem;
        line-height: 3.4rem;
    }
`;
export const Subtitle = styled.p`
    padding-bottom: 3.2rem;
    @media (max-width: 768px) {
        padding-bottom: 2.3rem;
    }
    a {
        color: ${PrimaryColor};
        transition: 0.6s ease;
        :hover {
            transition: 0.6s ease;
            color: ${HoverColor};
            @media (max-width: 768px) {
                color: ${PrimaryColor};
            }
        }
    }
`;

export const Button = styled.a`
    cursor: pointer;
    border-radius: 3.2rem;
    font-size: 2rem;
    line-height: 2rem;
    width: 20.7rem;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Background};
    background-color: ${PrimaryColor};
    :hover {
        background-color: ${HoverColor};
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
        }
    }
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 1.8rem;
        width: 23.4rem;
        height: 6rem;
    }
    @media (max-width: 480px) {
        font-size: 1.6rem;
        line-height: 1.6rem;
        width: 21.2rem;
        height: 4.8rem;
    }
`;
