import React, { useEffect } from 'react';
import ReactGa from 'react-ga';

const GoogleAnalitycs = () => {
    useEffect(() => {
        ReactGa.initialize('UA-178664780-1');
        ReactGa.pageview(window.location.pathname + window.location.search);
    }, []);
    return <div />;
};

export default GoogleAnalitycs;
