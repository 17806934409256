import React from 'react';
import { PropTypes } from 'prop-types';
import { StyledBurger } from '../../MobileMenu/MobileMenu.styles';

export default function Burger({ open, onClick }) {
    return (
        <StyledBurger open={open} onClick={onClick}>
            <div />
            <div />
            <div />
        </StyledBurger>
    );
}

Burger.propTypes = {
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};
