import React from 'react';
import ContentLoader from 'react-content-loader';
import { isMobile, isTablet } from '../../../../commons/constants';

export default function ProductsSectionSliderSkeleton() {
    return (
        <>
            {isMobile && (
                <ContentLoader
                    speed={3}
                    width="21.1rem"
                    height="29.4rem"
                    viewBox="0 0 211 294"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="27.2rem" rx="0" ry="0" width="21.1rem" height="2.2rem" />
                    <rect x="0" y="0" rx="0" ry="0" width="21.1rem" height="25.6rem" />
                </ContentLoader>
            )}

            {isTablet && !isMobile && (
                <ContentLoader
                    speed={3}
                    width="27rem"
                    height="36.8rem"
                    viewBox="0 0 270 368"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="34.6rem" rx="0" ry="0" width="27rem" height="2.2rem" />
                    <rect x="0" y="0" rx="0" ry="0" width="27rem" height="33rem" />
                </ContentLoader>
            )}

            {!isMobile && !isTablet && (
                <ContentLoader
                    speed={3}
                    width="39.8rem"
                    height="47.6rem"
                    viewBox="0 0 398 476"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="44.7rem" rx="0" ry="0" width="39.8rem" height="2.9rem" />
                    <rect x="0" y="0" rx="0" ry="0" width="39.8rem" height="42.7rem" />
                </ContentLoader>
            )}
        </>
    );
}
