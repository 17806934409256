import React from 'react';
import DynamicTitle from '../../components/molecules/DynamicTitle/DynamicTitle';
import { Container, ContainerFull, GlobalContainer } from '../../styles/commons';
import { ThanksPageContainer, ThanksPageContainerSubtitle, ThanksPageContainerText } from './ThanksPage.styles';
import { Button } from '../../components/common/Button';

const ThanksPage = () => {
    return (
        <>
            <DynamicTitle title="Спасибо" description="Спасибо за вашу заявку" />
            <GlobalContainer>
                <ContainerFull paddingBottom="12rem">
                    <Container>
                        <ThanksPageContainer>
                            <ThanksPageContainerSubtitle>Спасибо за вашу заявку</ThanksPageContainerSubtitle>
                            <ThanksPageContainerText>
                                Мы с вами свяжемся по указанному телефону в течении дня.
                            </ThanksPageContainerText>
                            <Button title="Вернуться на главную" link="/" />
                        </ThanksPageContainer>
                    </Container>
                </ContainerFull>
            </GlobalContainer>
        </>
    );
};

export default ThanksPage;
