import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { SecondaryColor, Background, HoverColor, PrimaryColor } from '../../../styles/colors';

export const MissionSectionSliderContainerOuter = styled.div`
    position: relative;
    width: 140.8rem;
    display: flex;
    justify-content: center;
    :hover {
        .mission-section-slider-button-prev,
        .mission-section-slider-button-next {
            visibility: visible;
            opacity: 1;
            transition: 0.6s ease;
        }
    }
`;
export const MissionSectionSliderContainer = styled.div`
    padding-top: 12rem;
    padding-bottom: 12rem;
    @media (max-width: 768px) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
    @media (max-width: 480px) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
`;
export const MissionSectionSliderSlideContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: ${(props) => props.flexDirection || 'row'};
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
export const MissionSectionSliderSlideLeft = styled.div`
    position: relative;
    width: 65rem;
    height: 62rem;
    @media (max-width: 768px) {
        margin-right: 0;
        height: 62rem;
    }
    @media (max-width: 480px) {
        width: 28.8rem;
        height: 27.6rem;
    }
`;
export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
export const ButtonCareerSectionOpen = styled.button`
    font-size: 2rem;
    line-height: 2rem;
    cursor: pointer;
    color: ${Background};
    background-color: ${PrimaryColor};
    border: 0.2rem solid #fff;
    padding: 2rem 5.6rem;
    border-radius: 3.2rem;
    transition: 0.6s ease;
    :hover {
        transition: 0.6s ease;
        background-color: ${HoverColor};
        color: ${Background};
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
            color: ${Background};
        }
    }
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    @media (max-width: 480px) {
        font-size: 1.6rem;
        line-height: 1.6rem;
        width: 100%;
        text-align: center;
    }
`;
export const MissionSectionSliderSlideContent = styled.div`
    position: relative;
    width: 52.2rem;
    height: 62rem;
    margin: 0 auto;
    p:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 768px) {
        padding-top: 2.4rem;
        width: 100%;
        height: auto;
        margin: 0;
    }
    @media (max-width: 480px) {
        padding-top: 2rem;
    }
`;
export const MissionSectionSliderSlidePreTitle = styled.p`
    font-size: 2rem;
    line-height: 2.6rem;
    color: ${SecondaryColor};
    margin-bottom: 2rem;
`;

export const MissionSectionSliderSlideOverhead = styled.h3`
    margin-bottom: 0.8rem;
`;
export const MissionSectionSliderSlideTitle = styled.h2`
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        padding-top: 0;
        margin-bottom: 1.6rem;
    }
`;
export const MissionSectionSliderSlideText = styled.p`
    margin-bottom: 1.6rem;
    color: ${(props) => (props.color ? props.color : '#292c2f')};
`;
export const MissionSectionSliderSlideSignature = styled.p`
    margin-top: 6.4rem;
    @media (max-width: 768px) {
        margin-top: 4.4rem;
    }
`;

export const VideoBlock = styled.div`
    width: 100%;
    height: 100%;
`;

export const Player = styled(ReactPlayer)`
    width: 100%;
`;
