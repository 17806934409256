import React from 'react';
import { RequestContent, RequestContentButton, RequestContentSubtitle, RequestContentTitle } from './styles';
import { Container, ContainerFull, FlexColumn, FlexRow } from '../../../../styles/commons';
import { content } from '../../../../commons/content';

export const RequestSection = () => {
    return (
        <ContainerFull backgroundColor="linear-gradient(74.77deg, #FF7100 9.99%, #C70B6F 89.98%)" paddingBottom="12rem">
            <Container>
                <FlexRow alignItems="center" justifyContent="space-between">
                    <FlexColumn alignItems="flex-start" justifyContent="flex-start">
                        <RequestContent>
                            <RequestContentTitle>{content.REQUEST_TITLE}</RequestContentTitle>
                            <RequestContentSubtitle>{content.REQUEST_TEXT}</RequestContentSubtitle>
                        </RequestContent>
                    </FlexColumn>
                    <RequestContentButton
                        href="https://acino-request-forms-qual.azurewebsites.net/request"
                        target="_blank"
                    >
                        {content.REQUEST_BUTTON}
                    </RequestContentButton>
                </FlexRow>
            </Container>
        </ContainerFull>
    );
};
export default RequestSection;
