/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactPlayer from 'react-player';
import { PropTypes } from 'prop-types';
import {
    ButtonCareerSectionOpen,
    Img,
    MissionSectionSliderSlideContainer,
    MissionSectionSliderSlideContent,
    MissionSectionSliderSlideLeft,
    MissionSectionSliderSlidePreTitle,
    MissionSectionSliderSlideSignature,
    MissionSectionSliderSlideText,
    MissionSectionSliderSlideTitle,
} from './SingleSlide.styles';
import { Container, ContainerFull } from '../../../styles/commons';
import Button from '../Button/Button';
import { ButtonPopupOpen } from '../HeaderSlide/HeaderSlide.styles';

export const SingleSlide = ({
    reactPlayerUrl,
    imgLeft,
    preTitle,
    title,
    text,
    signature,
    buttonText,
    buttonLink,
    backgroundColor,
    flexDirection,
    handlePopupOpen,
    textColor,
    buttonBgColor,
    border,
    onClick,
}) => {
    return (
        <ContainerFull backgroundColor={backgroundColor} paddingBottom="12rem">
            <Container>
                <MissionSectionSliderSlideContainer flexDirection={flexDirection}>
                    {reactPlayerUrl && (
                        <MissionSectionSliderSlideLeft>
                            <ReactPlayer url={reactPlayerUrl} width="100%" height="100%" />
                        </MissionSectionSliderSlideLeft>
                    )}
                    {!reactPlayerUrl && imgLeft && (
                        <MissionSectionSliderSlideLeft>
                            <Img src={imgLeft} alt={text} />
                        </MissionSectionSliderSlideLeft>
                    )}
                    <MissionSectionSliderSlideContent>
                        {preTitle ? (
                            <MissionSectionSliderSlidePreTitle>{preTitle}</MissionSectionSliderSlidePreTitle>
                        ) : (
                            ''
                        )}
                        {title ? <MissionSectionSliderSlideTitle>{title}</MissionSectionSliderSlideTitle> : ''}
                        {text &&
                            (typeof text === 'string' ? (
                                <MissionSectionSliderSlideText color={textColor}>{text}</MissionSectionSliderSlideText>
                            ) : (
                                text.map((sentence) => (
                                    <MissionSectionSliderSlideText color={textColor} key={sentence}>
                                        {sentence}
                                    </MissionSectionSliderSlideText>
                                ))
                            ))}
                        {buttonText && (
                            <MissionSectionSliderSlideSignature>{signature}</MissionSectionSliderSlideSignature>
                        )}
                        {handlePopupOpen && buttonText ? (
                            <ButtonCareerSectionOpen onClick={handlePopupOpen}>{buttonText}</ButtonCareerSectionOpen>
                        ) : buttonText && onClick ? (
                            <ButtonPopupOpen onClick={onClick}>{buttonText}</ButtonPopupOpen>
                        ) : buttonText ? (
                            <Button
                                backgroundcolorbutton={buttonBgColor}
                                link={buttonLink}
                                title={buttonText}
                                border={border}
                                target="_blank"
                                onClick={onClick}
                            />
                        ) : (
                            ''
                        )}
                    </MissionSectionSliderSlideContent>
                </MissionSectionSliderSlideContainer>
            </Container>
        </ContainerFull>
    );
};
SingleSlide.defaultProps = {
    reactPlayerUrl: undefined,
    imgLeft: undefined,
    preTitle: undefined,
    title: undefined,
    text: undefined,
    signature: undefined,
    buttonText: undefined,
    buttonLink: undefined,
    backgroundColor: undefined,
    flexDirection: undefined,
};

SingleSlide.propTypes = {
    reactPlayerUrl: PropTypes.string,
    imgLeft: PropTypes.string,
    preTitle: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string || PropTypes.array,
    signature: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    backgroundColor: PropTypes.string,
    flexDirection: PropTypes.string,
};

export default SingleSlide;
