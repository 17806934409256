import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HoverColor, PrimaryColor } from '../../../../../styles/colors';

export const CurrentVacanciesCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: calc(33.33% - 1.4rem);
    padding: 3.2rem;
    border: 0.1rem solid #d9dadb;
    @media (max-width: 768px) {
        flex-basis: calc(50% - 1rem);
    }
    @media (max-width: 480px) {
        padding: 2.4rem;
        flex-basis: 100%;
    }
`;
export const CurrentVacanciesCardTitle = styled.h3`
    margin-bottom: 2rem;
    @media (max-width: 480px) {
        margin-bottom: 1.6rem;
    }
`;
export const CurrentVacanciesCardText = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0.4rem;
    }
    margin-bottom: 2rem;
    @media (max-width: 480px) {
        margin-bottom: 1.6rem;
    }
`;
export const CurrentVacanciesCardContentMore = styled(Link)`
    font-size: 2rem;
    line-height: 2.6rem;
    transition: 0.6s ease;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    svg {
        margin-left: 0.4rem;
        path {
            transition: 0.6s ease;
        }
    }
    :hover {
        transition: 0.6s ease;
        color: ${HoverColor};
        @media (max-width: 768px) {
            color: ${PrimaryColor};
        }
        svg {
            path {
                transition: 0.6s ease;
                stroke: ${HoverColor};
                @media (max-width: 768px) {
                    stroke: ${PrimaryColor};
                }
            }
        }
    }
`;
