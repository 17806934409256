import styled from 'styled-components';
import { HoverColor, PrimaryColor } from '../../../styles/colors';

export const BreadCrumbsWrapper = styled.li`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #666b6e;
    color: ${(props) => props.colorDefault || '#666b6e'};
    white-space: nowrap;
    span {
        color: ${(props) => props.colorDefault || '#666b6e'};
    }
    :hover {
        a {
            transition: 0.6s ease;
            color: ${HoverColor};
            @media (max-width: 768px) {
                color: ${PrimaryColor};
            }
        }
    }
    a {
        transition: 0.6s ease;
        color: ${(props) => props.colorDefault || '#666b6e'};
    }
`;

export const BreadCrumbsSeparator = styled.span`
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${(props) => props.colorDefault || '#666b6e'};
`;

export const BreadCrumbsUl = styled.ul`
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 480px) {
        overflow: scroll;
    }
    li:last-child {
        span {
            cursor: default;
            color: ${(props) => props.colorActive || `#000`};
        }
        a {
            cursor: default;
            color: ${(props) => props.colorActive || `#000`};
        }
    }
    li {
        span {
            color: ${(props) => (props.colorActive ? '#FFFFFF80' : '#666b6e')};
        }
    }
`;
export const BreadCrumbsContainer = styled.div`
    position: absolute;
    top: 13.2rem;
    width: 132rem;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 768px) {
        top: 10.1rem;
        width: 64.8rem;
    }
    @media (max-width: 480px) {
        top: 8.4rem;
        width: 28.8rem;
    }
`;
export const BreadCrumbsSpan = styled.span`
    font-size: 1.6rem;
    line-height: 2rem;
`;
