/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Button } from '../../common/Button';
import Publication from '../../common/Publication/Publication';
import SearchButton from '../Button/SearchButton';
import { MatchedLetter } from '../styles';
import { NotFoundText, PromohubSearchSingleResultCardsContainer, PromohubSearchSingleResultContainer } from './styles';

const PromohubSearchSection = ({ objectKey, collection, term, promoData }) => {
    const returnSectionInfo = (inspectKey) => {
        switch (inspectKey) {
            case 'news':
                return { sectionTitle: 'Новости', linkPrefix: 'news', param: 'select-category-promohub=2' };
            case 'publics':
                return { sectionTitle: 'Публикации', linkPrefix: 'publics', param: 'select-category-promohub=0' };
            case 'videos':
                return { sectionTitle: 'Видео', linkPrefix: 'video', param: 'select-category-promohub=1' };
            default:
                return { sectionTitle: 'Промохаб', linkPrefix: '', param: '' };
        }
    };

    const highlightMatches = (text, search) => {
        const regex = new RegExp(`(${search})`, 'gi');
        return text.split(regex).map((match) => {
            if (match.toLowerCase() === search.toLowerCase()) {
                return <MatchedLetter>{match}</MatchedLetter>;
            }
            return match;
        });
    };

    return (
        <PromohubSearchSingleResultContainer>
            <h2>{returnSectionInfo(objectKey).sectionTitle}</h2>
            <PromohubSearchSingleResultCardsContainer>
                {collection.length > 0 ? (
                    collection.map((item, i) => (
                        <Publication
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            img={item.image}
                            shortText={item.shortText}
                            createdAt={item.createdAt}
                            title={item.title}
                            searchValue={term}
                            link={`${item.type}/${item.id}`}
                            highlightMatches={highlightMatches}
                        />
                    ))
                ) : (
                    <div style={{ marginTop: '-2.75rem' }}>
                        <p>{`По запросу «${term}» ничего не найдено`}</p>
                    </div>
                )}
            </PromohubSearchSingleResultCardsContainer>

            {collection.length < 3 ? (
                <></>
            ) : (
                <Button
                    search
                    link={`/promohub?term=${term}&${returnSectionInfo(objectKey).param}`}
                    title="Смотреть все"
                />
            )}
        </PromohubSearchSingleResultContainer>
    );
};

export default PromohubSearchSection;
