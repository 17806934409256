/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
import React from 'react';
import Header from 'components/common/Header';
import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
// import BannerContentHub from 'components/atoms/BannerContentHub';
import Footer from 'components/common/Footer';
import { Container, Row, FlexItem, RowCenter } from 'styles/commons';
import photoWoman from 'assets/images/photo_woman_1.png';
import ic1 from 'assets/images/rak-mz-icons/ic1.png';
import ic2 from 'assets/images/rak-mz-icons/ic2.png';
import ic3 from 'assets/images/rak-mz-icons/ic3.png';
import ic4 from 'assets/images/rak-mz-icons/ic4.png';
import ic5 from 'assets/images/rak-mz-icons/ic5.png';
import photoWoman2 from 'assets/images/photo_woman_2.png';
import ImageAlt from '../../components/HOC/ImageAlt/ImageAlt';

import {
    BannerBackground,
    BannerTextContainer,
    BannerImage,
    Title,
    ButonContainer,
    BannerSmallText,
    FlexBlock,
    Headline,
    TextBlock,
    PinkText,
    TextColumn,
    Ul,
    Li,
    Text,
    FactorsIconsContainer,
    FactorsIconsContainerSecond,
    IconBlock,
    PinkTextSmall,
    IconSmallText,
    Icon,
    NextActionsBlock,
    LeftBlock,
    RightBlock,
    PinkTextLarge,
    TestContainer,
} from './styles';
import ButtonWhite from './WhiteButton';
import ButtonPink from './PinkButton';
import { ContactsSection } from '../../components/common/Section';

const RiskRakaMZ = () => {
    return (
        <>
            {/* <BannerContentHub height="25rem" backgroundImage={newsBanner}> */}
            {/* <BannerContentHub> */}
            <BannerBackground>
                <Container>
                    <Row>
                        <FlexBlock>
                            <BannerTextContainer>
                                <Title>Определение риска развития рака молочной железы</Title>
                                <ButonContainer>
                                    <ButtonWhite title="Пройти тест" link="/calculator-dlya-rascheta-raka-grudi" />
                                    <BannerSmallText>
                                        Пройдите тест на определение риска возникновения рака молочной железы
                                    </BannerSmallText>
                                </ButonContainer>
                            </BannerTextContainer>
                            <BannerImage>
                                <ImageAlt src={photoWoman} width="100%" alt={photoWoman} />
                            </BannerImage>
                        </FlexBlock>
                    </Row>
                </Container>
            </BannerBackground>
            <Headline>Важно!</Headline>
            <TextBlock>
                <Container>
                    <TextColumn>
                        <PinkText>ТЕСТ НЕ РАССЧИТАН:</PinkText>
                        <Ul>
                            <Li>на женщин младше 35 лет</Li>
                            <Li>на женщин у которых уже диагностирован рак груди</Li>
                        </Ul>
                        <PinkText>ТЕСТ НЕ ПОЗВОЛЯЕТ ОЦЕНИТЬ:</PinkText>
                        <Ul>
                            <Li>риск рака молочной железы для женщин с мутациями в генах BRCA 1, BRCA2</Li>
                            <Li>
                                синдромами (синдром Ли-Фраумени, синдромом Коудена или синдромом Баннаян-
                                Рилей-Рувалькаба), связанными с высоким риском развития заболевания
                            </Li>
                        </Ul>
                    </TextColumn>
                </Container>
            </TextBlock>
            <Headline>
                <Container>
                    О тесте на определение риска
                    <br />
                    возникновения рака молочной железы
                </Container>
            </Headline>
            <TextBlock>
                <Container>
                    <TextColumn>
                        <Text>
                            Этот тест разработан на основе статистической модели Гейла и позволяет математически
                            просчитать индивидуальный риск развития инвазивного* рака молочной железы в ближайшие пять
                            лет и на протяжении жизни. Для оценки рисков модель опирается на личную историю каждой
                            женщины, включая репродуктивные и семейные факторы.
                        </Text>
                        <Text>
                            Модель Гейла была создана в Национальном институте рака США и протестирована на множестве
                            женщин разного возраста. Было доказано, что она дает точные оценки риска рака молочной
                            железы. Поэтому ее используют многие известные онкологические центры по всему миру.
                        </Text>
                        <Text>Для тестирования используются ключевые факторы риска возникновения рака груди:</Text>
                    </TextColumn>
                </Container>
            </TextBlock>
            <Headline>
                <Container>КЛЮЧЕВЫЕ ФАКТОРЫ РИСКА</Container>
            </Headline>
            <TextBlock>
                <Container>
                    <FactorsIconsContainer>
                        <IconBlock>
                            <PinkTextSmall>
                                Семейная
                                <br />
                                история
                            </PinkTextSmall>
                            <IconSmallText>
                                (есть ли случаи <br /> возникновения заболевания <br /> у мамы, сестры, тети)
                            </IconSmallText>
                            <Icon src={ic1} alt="Семейная история" width="16.4rem" />
                        </IconBlock>
                        <IconBlock>
                            <PinkTextSmall>
                                Количество биопсий
                                <br />
                                молочной железы
                                <br />в прошлом
                            </PinkTextSmall>
                            <IconSmallText>
                                (показывающих атипичную <br /> гиперплазию)
                            </IconSmallText>
                            <Icon src={ic2} alt="Количество биопсий молочной железы в прошлом" width="16.4rem" />
                        </IconBlock>
                        <IconBlock>
                            <PinkTextSmall>
                                Возраст на момент
                                <br />
                                рождения первенца
                            </PinkTextSmall>
                            <IconSmallText>
                                (или информация
                                <br /> об отсутствии
                                <br /> родов)
                            </IconSmallText>
                            <Icon src={ic3} alt="Количество биопсий молочной железы в прошлом" width="16.4rem" />
                        </IconBlock>
                    </FactorsIconsContainer>
                    <FactorsIconsContainerSecond>
                        <IconBlock>
                            <PinkTextSmall>
                                Возраст <br />
                                первой
                                <br /> менструации
                            </PinkTextSmall>
                            <Icon src={ic4} alt="Возраст первой менструации" width="16.4rem" />
                        </IconBlock>
                        <IconBlock>
                            <PinkTextSmall>
                                Возраст <br />
                                на момент <br />
                                прохождения теста
                            </PinkTextSmall>

                            <Icon src={ic5} alt="Возраст на момент прохождения теста" width="16.4rem" />
                        </IconBlock>
                    </FactorsIconsContainerSecond>
                    <TextColumn>
                        <Text>
                            Обратите внимание, что тест не включает факторы риска, связанные с образом жизни, к которым
                            относятся: питание, употребление алкоголя, физическая активность, сон, уровень стресса,
                            экология и другие.
                        </Text>
                        <Text>
                            *Инвазивный рак молочной железы – опухоль, которая прорастает и вторгается в здоровую
                            окружающую соединительную ткань. Он может распространяться в другие органы тела, если его не
                            лечить.
                        </Text>
                        <Text>
                            Биопсия – метод исследования, при котором проводится прижизненное взятие клеток или тканей
                            (биоптата) из организма с диагностической или исследовательской целью. Биопсия является
                            обязательным методом подтверждения диагноза при подозрении на наличие онкологических
                            заболеваний.
                        </Text>
                        <Text>
                            Атипичная гиперплазия – это состояние, при котором в протоках или дольках молочной железы
                            находится больше клеток, чем обычно. При атипичной гиперплазии лишние клетки отличаются от
                            нормальных клеток молочной железы.
                        </Text>
                        <Text>
                            Степень родства. Лица первой степени родства считаются родители (мама) и родные сестры и
                            дочери.
                        </Text>
                    </TextColumn>
                </Container>
            </TextBlock>
            <Headline>
                <Container>результаты теста и дальнейшие действия</Container>
            </Headline>
            <NextActionsBlock>
                <Container>
                    <Row>
                        <FlexBlock>
                            <LeftBlock>
                                <img src={photoWoman2} alt={photoWoman2} width="100%" />
                            </LeftBlock>
                            <RightBlock>
                                <BannerSmallText>
                                    По итогам прохождения теста вы получите информацию о вашем личном уровне риска
                                    возникновения рака молочной железы в течение ближайших пяти лет и на протяжении
                                    жизни. Также в результатах теста вы получите рекомендации по дальнейшим действиям.
                                    Женщинам с низким уровнем риска необходимо проходить стандартные ежегодные
                                    обследования у маммолога: до 50 лет делать УЗИ-диагностику молочных желёз, после 50
                                    – проходить рентгеновскую маммографию, если нет отягощенного анамнеза.
                                </BannerSmallText>
                            </RightBlock>
                        </FlexBlock>
                    </Row>
                </Container>
            </NextActionsBlock>
            <TextBlock>
                <Container>
                    <TestContainer>
                        <PinkTextLarge>
                            Пройдите тест на определение риска
                            <br /> возникновения рака молочной железы
                        </PinkTextLarge>
                        <ButtonPink title="Пройти тест" link="/calculator-dlya-rascheta-raka-grudi" />
                    </TestContainer>
                </Container>
            </TextBlock>
            <ContactsSection backgroundColor="#F0F0F0" />
            <GoogleAnalitycs />
        </>
    );
};

export default RiskRakaMZ;
