/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { content } from 'commons/content';
import { useLocation } from 'react-router';
import {
    PromohubSimilarItemsTitle,
    Img,
    PromohubSimilarItemsContainer,
    PromohubSimilarItem,
    PromohubSimilarItemTitle,
    PromohubSimilarItemImg,
    PromohubSimilarItemContent,
    PromohubSimilarItemText,
} from './PromohubSimilarItems.styles';

const PromohubSimilarItems = ({ id }) => {
    const location = useLocation();
    const [type, setType] = useState();
    const pathnames = location.pathname.split('/').filter((x) => x);
    useEffect(() => {
        if (pathnames.includes('publics')) {
            setType('publics');
        } else if (pathnames.includes('video')) {
            setType('video');
        } else if (pathnames.includes('news')) {
            setType('news');
        }
        console.log(type);
    }, [pathnames]);
    const [similar, setSimilar] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const request = await fetch(
                // eslint-disable-next-line radix
                `${process.env.REACT_APP_BACKEND_URL}/promohub`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                },
            );
            const response = await request.json();
            if (response.data !== undefined) {
                if (type === 'publics') {
                    setSimilar(response.data.publics.filter((i) => i.id !== parseInt(id)).slice(0, 4));
                }
                if (type === 'video') {
                    setSimilar(response.data.videos.filter((i) => i.id !== parseInt(id)).slice(0, 4));
                }
                if (type === 'news') {
                    setSimilar(response.data.news.filter((i) => i.id !== parseInt(id)).slice(0, 4));
                }
            }
        }
        fetchData();
    }, [type]);

    return (
        <PromohubSimilarItemsContainer>
            <PromohubSimilarItemsTitle>
                {type === 'publics'
                    ? content.LATEST_PUBLICATION
                    : type === 'video'
                    ? content.LATEST_VIDEO
                    : content.LATEST_NEWS}
            </PromohubSimilarItemsTitle>
            {similar &&
                similar.map((item) => {
                    const date = new Date(item.createdAt);
                    const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}.${date.getFullYear()}`;
                    return (
                        <PromohubSimilarItem to={`../${type}/${item.id}`} key={item.id}>
                            <PromohubSimilarItemImg>
                                <Img src={item.image} alt={item.title} />
                            </PromohubSimilarItemImg>
                            <PromohubSimilarItemContent>
                                <PromohubSimilarItemTitle>{item.title}</PromohubSimilarItemTitle>
                                <PromohubSimilarItemText>{formattedDate}</PromohubSimilarItemText>
                            </PromohubSimilarItemContent>
                        </PromohubSimilarItem>
                    );
                })}
        </PromohubSimilarItemsContainer>
    );
};

export default PromohubSimilarItems;
