import React from 'react';
import LatestPublicationsFetch from './LatestPublications/LatestPublications';
import { Container, ContainerFull } from '../../../../styles/commons';
import { content } from '../../../../commons/content';
import { Button } from '../../Button';
import { PublicationsSectionTitle } from './PublicationsSection.styles';

export const PublicationsSection = () => {
    return (
        <ContainerFull paddingBottom="12rem">
            <Container>
                <PublicationsSectionTitle>{content.PUBLICATIONS_SECTION_TITLE}</PublicationsSectionTitle>
                <LatestPublicationsFetch />
                <Button link="/promohub" title={content.PUBLICATIONS_SECTION_BUTTON} />
            </Container>
        </ContainerFull>
    );
};

export default PublicationsSection;
