// export default ProductsListPage;
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useContext, useMemo } from 'react';
import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
import { BottomModalContext } from 'context/context';
import { fetchAllCatalog } from 'services/NewsService';
import { content } from 'commons/content';
import { Container } from 'styles/commons';
import tabletka_button from 'assets/images/tabletka_button.png';
// import adel_button from 'assets/images/adel_button.png';
import { Link } from 'react-router-dom';
import { useSearch, useSort, usePagination, useSelectCategoriesProduct } from '../../commons/listHooks';
import { Button } from '../../components/common/Button';
import {
    ButtonImg,
    MatchedLetter,
    ProductsListPageTitle,
    ProductsListPageSearchContainer,
    ProductsListPageCardsSwitcher,
    ProductsListPageCardsColumnButton,
    ProductsListPageCardsRowButton,
    ProductsListPageCategories,
    ProductsListPageLetters,
    ProductsListPageLettersContainer,
    ProductsListPageLetter,
    ProductsListPageCards,
    ProductsListPageLettersReset,
    ProductsListPageCardImg,
    ProductsListPageCard,
    ProductsListPageCardContent,
    ProductsListPageCardContentTitle,
    ProductsListPageCardContentButtonsContainer,
    ProductsListPageCardsPagination,
    ProductsListPageCardContentInner,
    ProductsListPageAlphabet,
    ProductsListPageCardNothing,
    ProductsListPageCardNothingText,
} from './ProductsListPage.styles';
import ImageAlt from '../../components/HOC/ImageAlt/ImageAlt';
import { BreadCrumbs } from '../../components/common/BreadCrumbs';
import { ContainerFull, GlobalContainer } from '../../styles/commons';
import { isMobile, isTablet } from '../../commons/constants';
import DynamicTitle from '../../components/molecules/DynamicTitle/DynamicTitle';
import { SearchPanel } from '../../components/common/SearchPanel';
import ProductsListPageCardsColumnButtonSvg from './ProductsListPageCardsRowButtonSvg/ProductsListPageCardsRowButtonSvg';
import ProductsListPageCardsRowButtonSvg from './ProductsListPageCardsColumnButtonSvg/ProductsListPageCardsColumnButtonSvg';
import ProductsListPageSkeleton from './ProductsListPageSkeleton/ProductsListPageSkeleton';
import { PromohubInfoText } from '../PromohubPage/PromohubListPage.styles';
import PromohubInfoSvg from '../PromohubPage/PromohubInfoSvg/PromohubInfoSvg';
import GlobalNotFoundBlock from '../../components/GlobalSearch/SearchSection/NotFoundBlocks/GlobalNotFound';

const ProductsListPage = () => {
    const { isBottomModal, modalBottomClose } = useContext(BottomModalContext);
    const { isBottomCookiesModal, modalBottomCookiesClose } = useContext(BottomModalContext);
    const [activeButton, setActiveButton] = useState('column');
    const [showLetters, setShowLetters] = useState(true);
    const [isSvgRotated, setIsSvgRotated] = useState(false);
    const [loader, setLoader] = useState(true);
    const [selectedLetter, setSelectedLetter] = useState('');
    const { renderSearch, searchValue, resetSearch } = useSearch(setSelectedLetter);
    const { renderSort, sortValue, resetSort } = useSort();
    const { renderPagination, pageValue, setLastPage, resetPagination } = usePagination(1);
    const { renderSelectCategoriesProduct, categoryValue, resetSelectCategoriesProduct } = useSelectCategoriesProduct();
    const [displayMode, setDisplayMode] = useState('column');
    const [searchData, setSearchData] = useState({});
    const [all, setAll] = useState([]);
    const [recipe, setRecipe] = useState([]);
    const [noRecipe, setNoRecipe] = useState([]);
    const [dietarySupplements, setDietarySupplements] = useState([]);
    const [allMeta, setAllMeta] = useState([]);
    const [recipeMeta, setRecipeMeta] = useState([]);
    const [noRecipeMeta, setNoRecipeMeta] = useState([]);
    const [dietarySupplementsMeta, setDietarySupplementsMeta] = useState([]);

    useEffect(() => {
        if (isMobile) {
            setShowLetters(false);
        } else {
            setShowLetters(true);
        }
    }, [isMobile]);

    useEffect(() => {
        const getAllCatalog = async () => {
            const request = await fetch(
                // eslint-disable-next-line radix
                `${process.env.REACT_APP_BACKEND_URL}/product?page=${pageValue}&term=${searchValue}&char=${selectedLetter}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                },
            );
            const response = await request.json();
            if (response.data !== undefined) {
                setAll(response.data.all);
                setAllMeta(response.metadata.all);
                setRecipe(response.data.recipe);
                setRecipeMeta(response.metadata.recipe);
                setNoRecipe(response.data.noRecipe);
                setNoRecipeMeta(response.metadata.noRecipe);
                setDietarySupplements(response.data.dietarySupplements);
                setDietarySupplementsMeta(response.metadata.dietarySupplements);
            }
        };
        getAllCatalog();
        setLoader(false);
    }, [searchValue, sortValue, pageValue, categoryValue, selectedLetter]);

    useEffect(() => {
        resetPagination();
    }, [categoryValue]);
    const [data, metadata] = useMemo(() => [searchData.data, searchData.metadata], searchData);
    const handleAlphabetClick = () => {
        setShowLetters(!showLetters);
        setIsSvgRotated(!isSvgRotated);
    };

    const highlightMatches = (text, search) => {
        const regex = new RegExp(`(${search})`, 'gi');
        return text.split(regex).map((match) => {
            if (match.toLowerCase() === search.toLowerCase()) {
                return <MatchedLetter>{match}</MatchedLetter>;
            }
            return match;
        });
    };
    const handleColumnButtonClick = () => {
        if (activeButton !== 'column') {
            setActiveButton('column');
        }
        setDisplayMode('column');
    };

    const handleRowButtonClick = () => {
        if (activeButton !== 'row') {
            setActiveButton('row');
        }
        setDisplayMode('row');
    };

    const handleResetFilters = () => {
        setSelectedLetter('');
        resetPagination();
        resetSelectCategoriesProduct();
        resetSort();
        resetSearch();
    };

    const [selectedData, linkPrefix] = useMemo(() => {
        if (categoryValue === '0') {
            setLastPage(allMeta.totalPages);
            return [all, 'publics'];
        }
        if (categoryValue === '1') {
            setLastPage(recipeMeta.totalPages);
            return [recipe, 'video'];
        }
        if (categoryValue === '2') {
            setLastPage(noRecipeMeta.totalPages);
            return [noRecipe, 'news'];
        }
        if (categoryValue === '3') {
            setLastPage(dietarySupplementsMeta.totalPages);
            return [dietarySupplements, 'news'];
        }
        return [[], ''];
    }, [all, recipe, noRecipe, dietarySupplements]);

    const alphabet = 'абвгдеёжзийклмнопрстуфхцчшэюя'.split('');

    return (
        <>
            <DynamicTitle title="Наши продукты" />
            <GlobalContainer>
                <ContainerFull paddingBottom="12rem">
                    <Container>
                        <BreadCrumbs />
                        <ProductsListPageTitle>{content.PRODUCTS_LIST_PAGE_TITLE}</ProductsListPageTitle>
                        <PromohubInfoText>
                            <PromohubInfoSvg />
                            {content.PROMOHUB_LIST_PAGE_INFO}
                        </PromohubInfoText>
                        <ProductsListPageSearchContainer>
                            <SearchPanel>{renderSearch()}</SearchPanel>
                            <ProductsListPageCardsSwitcher>
                                <ProductsListPageCardsColumnButton
                                    onClick={handleColumnButtonClick}
                                    className={activeButton === 'column' ? 'active' : ''}
                                >
                                    <ProductsListPageCardsColumnButtonSvg />
                                </ProductsListPageCardsColumnButton>
                                <ProductsListPageCardsRowButton
                                    onClick={handleRowButtonClick}
                                    className={activeButton === 'row' ? 'active' : ''}
                                >
                                    <ProductsListPageCardsRowButtonSvg />
                                </ProductsListPageCardsRowButton>
                            </ProductsListPageCardsSwitcher>
                        </ProductsListPageSearchContainer>
                        <ProductsListPageCategories>
                            {renderSelectCategoriesProduct(
                                allMeta.count,
                                recipeMeta.count,
                                noRecipeMeta.count,
                                dietarySupplementsMeta.count,
                            )}
                        </ProductsListPageCategories>
                        {isMobile && (
                            <ProductsListPageAlphabet onClick={handleAlphabetClick} isSvgRotated={isSvgRotated}>
                                Фильтр по алфавиту
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                >
                                    <path d="M4 6.5L8 10.5L12 6.5" stroke="#FF7100" strokeWidth="2" />
                                </svg>
                            </ProductsListPageAlphabet>
                        )}
                        <>
                            {showLetters && (
                                <ProductsListPageLetters>
                                    <ProductsListPageLettersContainer>
                                        {alphabet.map((letter) => (
                                            <ProductsListPageLetter
                                                key={letter}
                                                onClick={() => setSelectedLetter(letter)}
                                                selected={selectedLetter === letter}
                                            >
                                                {letter}
                                            </ProductsListPageLetter>
                                        ))}
                                        <ProductsListPageLettersReset onClick={handleResetFilters}>
                                            Сбросить
                                        </ProductsListPageLettersReset>
                                    </ProductsListPageLettersContainer>
                                </ProductsListPageLetters>
                            )}
                        </>
                        <ProductsListPageCards displayMode={displayMode}>
                            {loader ? (
                                [...new Array(20)].map((_, i) => <ProductsListPageSkeleton key={i} />)
                            ) : selectedData && selectedData.length > 0 ? (
                                selectedData.map((item) => (
                                    <ProductsListPageCard displayMode={displayMode}>
                                        <ProductsListPageCardImg displayMode={displayMode}>
                                            <img src={item.img1} alt={item.title} />
                                        </ProductsListPageCardImg>

                                        <ProductsListPageCardContent displayMode={displayMode}>
                                            <ProductsListPageCardContentTitle to={`products/${item.cpu}`} data={item}>
                                                {highlightMatches(item.title, searchValue)}
                                            </ProductsListPageCardContentTitle>
                                            <ProductsListPageCardContentInner>
                                                <div dangerouslySetInnerHTML={{ __html: item.short_text }} />
                                                <ProductsListPageCardContentButtonsContainer>
                                                    <Button
                                                        link={`products/${item.cpu}`}
                                                        data={item}
                                                        title={content.PRODUCTS_LIST_PAGE_PRODUCT_ITEM_BUTTON}
                                                    />
                                                    {item.link_tabletka && (
                                                        <>
                                                            <ButtonImg href={item.link_tabletka}>
                                                                <ImageAlt
                                                                    src={tabletka_button}
                                                                    alt={tabletka_button}
                                                                    width="160px"
                                                                    height="55px"
                                                                />
                                                            </ButtonImg>
                                                        </>
                                                    )}
                                                </ProductsListPageCardContentButtonsContainer>
                                            </ProductsListPageCardContentInner>
                                        </ProductsListPageCardContent>
                                    </ProductsListPageCard>
                                ))
                            ) : (
                                <>
                                    <GlobalNotFoundBlock state={{ state: true }} />
                                </>
                            )}
                            <ProductsListPageCardsPagination>{renderPagination()}</ProductsListPageCardsPagination>
                        </ProductsListPageCards>
                        <GoogleAnalitycs />
                    </Container>
                </ContainerFull>
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
};

export default ProductsListPage;
