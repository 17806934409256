/* eslint-disable react/prop-types */
import React from 'react';
import { GlobalNotFound } from './styles';

const GlobalNotFoundBlock = ({ state }) => {
    return (
        <GlobalNotFound style={state.state === true ? { display: 'inline-flex' } : { display: 'none' }}>
            <h3>Поиск не дал результатов</h3>
            <p>Пожалуйста, попробуйте использовать другой термин</p>
        </GlobalNotFound>
    );
};

export default GlobalNotFoundBlock;
