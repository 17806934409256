import styled from 'styled-components';

export const CalculatorRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 2.4rem;
    }
    @media (max-width: 480px) {
        gap: 2rem;
    }
`;

export const CalculatorContentItem = styled.div`
    width: 65rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    @media (max-width: 768px) {
        width: 64.8rem;
    }
    @media (max-width: 480px) {
        width: 28.8rem;
    }
`;

export const CalculatorSwiper = styled.div`
    width: 100%;
    height: 100%;
    border: 0.1rem solid #d9dadb;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    padding: 4.8rem;
    @media (max-width: 768px) {
        gap: 2.4rem;
    }
    @media (max-width: 480px) {
        padding: 2.4rem;
        gap: 1.6rem;
    }
`;
export const CalculatorSwiperInner = styled.div`
    width: 100%;
    display: block;
`;
export const CalculatorSlideContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    @media (max-width: 768px) {
        gap: 2.4rem;
    }
    @media (max-width: 480px) {
        gap: 1.6rem;
    }
    h3 {
        @media (max-width: 768px) {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
    }
`;

export const CalculatorNavigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CalculatorSlideValues = styled.div`
    display: flex;
    flex-direction: ${({ current }) => (current === 0 ? 'row' : 'column')};
    gap: ${({ current }) => (current === 0 ? '0.8rem 6.4rem' : '0.8rem')};
    flex-wrap: wrap;
    width: ${({ current }) => (current === 0 ? '21.8rem' : '100%')};
`;

export const CalculatorSlideResultsDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.6rem;
`;

export const CalculatorSlideResultsDescriptionItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.8rem;
    }
`;
