/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyle from '../styles/index';

function RouterContainer({ children }) {
    return (
        <div>
            <GlobalStyle />
            {children}
        </div>
    );
}

RouterContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default RouterContainer;
