import styled from 'styled-components';
import { White, Orange, Default } from 'styles/colors';
import { Link, NavLink } from 'react-router-dom';
import { Background, HoverColor, PrimaryColor } from '../../../../styles/colors';

export const MenuBackground = styled.div`
    display: none;
`;
export const WebMenu = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;
export const Dropdown = styled.div`
    position: relative;
    display: inline-block;
    &:hover {
        div {
            display: block;
        }
        button {
            transition: 0.6s ease;
            cursor: pointer;
            color: ${HoverColor};
            background-color: ${Background};
            svg {
                transition: 0.4s ease;
                transform: rotate(180deg);
            }
        }
    }
`;
export const DropdownBtn = styled.button`
    padding: 1rem 1rem;
    color: #292c2f;
    background-color: ${Background};
    font-size: 1.6rem;
    line-height: 2.4rem;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.6s ease;
    svg {
        margin-left: 0.4rem;
        transition: 0.4s ease;
    }
    &.active {
        transition: 0.6s ease;
        color: ${PrimaryColor};
    }
`;
export const UL = styled.ul`
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    padding-left: 0;
    li {
        padding: 1rem;
        color: #555;
        a {
            transition: 0.6s ease;
        }
        a:hover {
            transition: 0.6s ease;
            color: ${HoverColor};
        }
        a.active {
            transition: 0.6s ease;
            color: ${PrimaryColor};
        }
    }
`;

export const LinkTo = styled(Link)`
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${Default};
    transition: 0.6s ease;
    &:hover {
        color: ${Orange};
        transition: 0.6s ease;
    }
`;
export const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: ${Background};
    min-width: 19.4rem;
    border-radius: 0.8rem;
    box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: 0.6s ease;
    a:nth-child(1):hover {
        border-radius: 0.8rem 0.8rem 0 0;
    }
    a:nth-child(4):hover {
        border-radius: 0 0 0.8rem 0.8rem;
    }
    a.active {
        transition: 0.6s ease;
        color: ${PrimaryColor};
    }
`;

export const SubMenuBlock1 = styled.p`
    position: absolute;
    background-color: ${White};
    /* padding: 2rem; */
    box-shadow: 0.2rem 0.1rem 0.3rem 0rem rgb(0 0 0 / 32%);
    margin-left: -5rem;
`;
export const SubMenuUl = styled.ul`
    list-style: none;
    padding: 0rem;
    background-color: ${White};
`;

export const SubMenLi = styled.li`
    border-bottom: 0.1rem solid #f3f3f3;
    padding: 0rem 2rem;
`;

export const SubMenuLinkTo = styled(NavLink)`
    padding: 0.8rem 1.6rem;
    text-align: left;
    text-decoration: none;
    display: block;
    &:hover {
        background-color: #ddd;
    }
`;

export const ExternalLinkBold = styled.a`
    color: ${Default};
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    transition: 0.6s ease;
    &:hover {
        color: ${Orange};
        transition: 0.6s ease;
    }
`;

export const StyledBurger = styled.div`
    position: relative;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.45rem 0.1rem;
    margin-left: 2rem;
    display: none;
    :hover {
        div {
            background-color: ${HoverColor};
        }
    }
    div {
        display: ${({ open }) => (open ? `none` : 'block')};
        height: 0.2rem;
        background-color: ${({ open }) => (open ? `${PrimaryColor}` : '#666B6E')};
        transform-origin: 0.1rem;
        transition: all 0.3s linear;
        &:nth-child(1) {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }
        &:nth-child(2) {
            transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
            opacity: ${({ open }) => (open ? 0 : 1)};
        }
        &:nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`;
