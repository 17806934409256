import styled from 'styled-components';
import { DarkGrey, White, Orange, Default } from 'styles/colors';
import { screenSizeSmall } from 'styles/screenSize';
import { Link, NavLink } from 'react-router-dom';
import { Background, HoverColor, PrimaryColor } from '../../../../styles/colors';

export const MobMenu = styled.div`
    flex-flow: column nowrap;
    z-index: 10;
    background-color: ${DarkGrey};
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    left: 0;
    background-color: transparent;
    transform: translateY(-100%);
    @media (max-width: ${screenSizeSmall}) {
        transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
    }
`;

export const MenuBackground = styled.div`
    display: none;
    @media (max-width: 768px) {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
    }
`;
export const Dropdown = styled.div`
    position: relative;
    display: inline-block;
    &:hover {
        div {
            display: block;
        }
        button {
            transition: 0.6s ease;
            cursor: pointer;
            color: ${HoverColor};
            background-color: ${Background};
            @media (max-width: 768px) {
                color: ${PrimaryColor};
            }
            svg {
                transition: 0.4s ease;
                transform: rotate(180deg);
            }
        }
    }
`;
export const DropdownBtn = styled.button`
    padding: 1rem 1rem;
    color: #292c2f;
    background-color: ${Background};
    font-size: 1.6rem;
    line-height: 2.4rem;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.6s ease;
    @media (max-width: 768px) {
        padding: 0;
    }
    svg {
        margin-left: 0.4rem;
        transition: 0.4s ease;
    }
    @media (max-width: 768px) {
        font-size: 2rem;
        line-height: 2.6rem;
        padding-bottom: 3.2rem;
    }
    @media (max-width: 480px) {
        font-size: 1.8rem;
        line-height: 2.3rem;
        padding-bottom: 2.8rem;
    }
    &.active {
        transition: 0.6s ease;
        color: ${PrimaryColor};
    }
    @media (max-width: 768px) {
        transition: 0;
        :hover {
            transition: 0;
            padding-bottom: 1.6rem;
        }
    }
    @media (max-width: 480px) {
        transition: 0;
        :hover {
            transition: 0;
            padding-bottom: 1.2rem;
        }
    }
`;
export const UL = styled.ul`
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    padding-left: 0;
    li {
        padding: 1rem;
        @media (max-width: 768px) {
            padding: 0;
        }
        color: #555;
        a {
            transition: 0.6s ease;
        }
        a:hover {
            transition: 0.6s ease;
            color: ${HoverColor};
            @media (max-width: 768px) {
                color: ${PrimaryColor};
            }
        }
        a.active {
            transition: 0.6s ease;
            color: ${PrimaryColor};
        }
    }

    @media (max-width: ${screenSizeSmall}) {
        transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
        flex-flow: column nowrap;
        z-index: 10;
        background-color: #fff;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        /* margin-top: 0rem; */
        /* transition: transform 0.3s ease-in-out; */
        overflow-y: scroll;
        li {
            color: ${Background};
        }
    }
    @media (max-width: 768px) {
        width: 50%;
        left: 0;
        & > div:first-child {
            position: absolute;
            top: 2.4rem;
            left: 6rem;
            @media (max-width: 480px) {
                left: 1.6rem;
            }
        }
        background-color: ${Background};
        padding-top: 16rem;
        padding-left: 6rem;
        padding-right: 6rem;
        li {
            padding-bottom: 3.2rem;
            @media (max-width: 480px) {
                padding-bottom: 2.8rem;
            }
            a {
                font-size: 2rem;
                line-height: 2.6rem;
                color: #292c2f;
                @media (max-width: 480px) {
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                }
            }
        }
        li:last-child {
            padding-bottom: 0;
        }
        .close-nav {
            position: absolute;
            top: 3.6rem;
            right: 4rem;
            z-index: 11;
            @media (max-width: 480px) {
                top: 3.3rem;
                right: 1.6rem;
            }
            svg {
                path {
                    transition: 0.6s ease;
                }
            }
            &:hover {
                svg {
                    path {
                        transition: 0.6s ease;
                        fill: ${HoverColor};
                        @media (max-width: 768px) {
                            fill: ${PrimaryColor};
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 480px) {
        width: 100%;
        padding-top: 11.1rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
`;

export const LinkTo = styled(Link)`
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${Default};
    transition: 0.6s ease;
    &:hover {
        color: ${Orange};
        transition: 0.6s ease;
    }
    @media (max-width: ${screenSizeSmall}) {
        color: ${Background};
    }
`;
export const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: ${Background};
    min-width: 19.4rem;
    border-radius: 0.8rem;
    box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: 0.6s ease;
    a:nth-child(1):hover {
        border-radius: 0.8rem 0.8rem 0 0;
    }
    a:nth-child(4):hover {
        border-radius: 0 0 0.8rem 0.8rem;
    }
    a.active {
        transition: 0.6s ease;
        color: ${PrimaryColor};
    }
    @media (max-width: 768px) {
        a {
            font-size: 2rem;
            line-height: 2.6rem;
            padding: 1.6rem 2.4rem;
            padding-top: 0;
        }
        a:first-child {
            padding-top: 0;
        }
        a:last-child {
            padding-bottom: 3.2rem;
        }

        padding: 0;
        position: static;
        box-shadow: none;
    }
    @media (max-width: 480px) {
        a {
            font-size: 1.8rem;
            line-height: 2.3rem;
            padding-bottom: 0.8rem;
            padding-top: 0;
        }
        a:last-child {
            padding-bottom: 2.8rem;
        }
    }
`;

export const SubMenuBlock1 = styled.p`
    position: absolute;
    background-color: ${White};
    /* padding: 2rem; */
    box-shadow: 0.2rem 0.1rem 0.3rem 0rem rgb(0 0 0 / 32%);
    margin-left: -5rem;
    @media (max-width: ${screenSizeSmall}) {
        position: inherit;
        margin-left: 0rem;
        background-color: inherit;
        box-shadow: none;
        margin-top: 2.5rem;
    }
`;
export const SubMenuUl = styled.ul`
    list-style: none;
    padding: 0rem;
    background-color: ${White};
    @media (max-width: ${screenSizeSmall}) {
        background-color: inherit;
    }
`;

export const SubMenLi = styled.li`
    border-bottom: 0.1rem solid #f3f3f3;
    padding: 0rem 2rem;
    @media (max-width: ${screenSizeSmall}) {
        border-bottom: none;
    }
`;

export const SubMenuLinkTo = styled(NavLink)`
    padding: 0.8rem 1.6rem;
    text-align: left;
    text-decoration: none;
    display: block;
    &:hover {
        background-color: #ddd;
    }
`;

export const ExternalLinkBold = styled.a`
    color: ${Default};
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    transition: 0.6s ease;
    &:hover {
        color: ${Orange};
        transition: 0.6s ease;
    }
    @media (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
`;

export const StyledBurger = styled.div`
    position: relative;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.45rem 0.1rem;
    margin-left: 2rem;
    display: none;
    :hover {
        div {
            background-color: ${HoverColor};
            @media (max-width: 768px) {
                background-color: ${PrimaryColor};
            }
        }
    }
    @media screen and (max-width: ${screenSizeSmall}) {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }

    div {
        display: ${({ open }) => (open ? `none` : 'block')};
        height: 0.2rem;
        background-color: ${({ open }) => (open ? `${PrimaryColor}` : '#666B6E')};
        transform-origin: 0.1rem;
        transition: all 0.3s linear;

        &:nth-child(1) {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        &:nth-child(2) {
            transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
            opacity: ${({ open }) => (open ? 0 : 1)};
        }
        &:nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`;
