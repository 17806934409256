import React from 'react';
import ContentLoader from 'react-content-loader';
import { isMobile, isTablet } from '../../../../commons/constants';

const PromohubSingleSkeleton = () => {
    return (
        <>
            {isMobile && (
                <ContentLoader
                    speed={3}
                    width="28.8rem"
                    height="150rem"
                    viewBox="0 0 2880 1500"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="28.8rem" height="5.8rem" />
                    <rect x="0" y="7.8rem" rx="0" ry="0" width="10.1rem" height="2.6rem" />
                    <rect x="0" y="16.8rem" rx="0" ry="0" width="28.8rem" height="19.2rem" />
                    <rect x="0" y="42.4rem" rx="0" ry="0" width="28.8rem" height="120rem" />
                </ContentLoader>
            )}

            {isTablet && !isMobile && (
                <ContentLoader
                    speed={3}
                    width="76.8rem"
                    height="180rem"
                    viewBox="0 0 768 1800"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="64.8rem" height="5.8rem" />
                    <rect x="0" y="7.8rem" rx="0" ry="0" width="10.1rem" height="2.6rem" />
                    <rect x="0" y="16.8rem" rx="0" ry="0" width="64.8rem" height="43rem" />
                    <rect x="0" y="66.2rem" rx="0" ry="0" width="64.8rem" height="110rem" />
                </ContentLoader>
            )}

            {!isMobile && !isTablet && (
                <ContentLoader
                    speed={3}
                    width="132rem"
                    height="200rem"
                    viewBox="0 0 1320 2000"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="67rem" height="5.8rem" />
                    <rect x="0" y="7.8rem" rx="0" ry="0" width="10.1rem" height="2.6rem" />
                    <rect x="0" y="16.8rem" rx="0" ry="0" width="87.3rem" height="58rem" />
                    <rect x="0" y="81.2rem" rx="0" ry="0" width="87.3rem" height="110rem" />
                    <rect x="89.3rem" y="0" rx="0" ry="0" width="42.7rem" height="2.9rem" />
                    <rect x="89.3rem" y="7.7rem" rx="0" ry="0" width="42.7rem" height="10.6rem" />
                    <rect x="89.3rem" y="21.5rem" rx="0" ry="0" width="42.7rem" height="10.6rem" />
                    <rect x="89.3rem" y="35.3rem" rx="0" ry="0" width="42.7rem" height="10.6rem" />
                    <rect x="89.3rem" y="49.1rem" rx="0" ry="0" width="42.7rem" height="10.6rem" />
                </ContentLoader>
            )}
        </>
    );
};

export default PromohubSingleSkeleton;
