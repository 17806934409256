import styled from 'styled-components';
import { Background, HoverColor, PrimaryColor } from '../../../../styles/colors';

export const RequestContent = styled.div`
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 768px) {
        margin-bottom: 4.4rem;
    }
`;
export const RequestContentTitle = styled.h2`
    color: ${Background};
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        margin-bottom: 1.6rem;
    }
`;
export const RequestContentSubtitle = styled.p`
    color: ${Background};
`;
export const RequestContentButton = styled.a`
    cursor: pointer;
    color: ${Background};
    font-size: 2rem;
    line-height: 2rem;
    border-radius: 3.2rem;
    transition: 0.6s ease;
    border: 0.2rem solid ${Background};
    padding: 2rem 5.6rem;
    :hover {
        transition: 0.6s ease;
        background-color: ${Background};
        color: ${HoverColor};
        @media (max-width: 768px) {
            background-color: ${Background};
            color: ${PrimaryColor};
        }
    }
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    @media (max-width: 480px) {
        font-size: 1.6rem;
        line-height: 1.6rem;
        width: 100%;
        text-align: center;
        padding: 1.6rem 6rem;
    }
`;
