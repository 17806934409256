import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HoverColor, PrimaryColor } from '../../../styles/colors';
import {
    ProductsListPageCardContentInner,
    ProductsListPageCardContentTitle,
} from '../../../pages/ProductsListPage/ProductsListPage.styles';
import { PublicationContentMore } from '../../common/Publication/Publication.styles';

export const CardItem = styled.li`
    list-style-type: none;
    width: 100%;
    border-bottom: 0.1rem solid #d9dadb;
    height: 30.8rem;
    padding: 4.8rem 0;
    @media (max-width: 768px) {
        height: 24.5rem;
        padding: 2.8rem 0;
    }
    @media (max-width: 480px) {
        height: auto;
    }
`;
export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
export const ImgWrapper = styled.div`
    width: 42.7rem;
    height: 21.2rem;
    margin-right: 2rem;
    @media (max-width: 768px) {
        width: 31.4rem;
        height: 18.9rem;
    }
    @media (max-width: 480px) {
        width: 28.8rem;
        height: 17.4rem;
        margin-right: 0;
    }
`;

export const CardContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @media (max-width: 480px) {
        height: auto;
    }
`;
export const CardContentTop = styled.div`
    width: 87.3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 31.4rem;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;
export const CardTitle = styled(Link)`
    font-weight: 550;
    font-style: normal;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #292c2f;
    margin-bottom: 2rem;
    transition: 0.6s ease;
    :hover {
        color: ${HoverColor};
        transition: 0.6s ease;
        @media (max-width: 768px) {
            color: ${PrimaryColor};
        }
    }
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 1.6rem;
    }
    @media (max-width: 480px) {
        width: 100%;
        margin-top: 1.6rem;
    }
`;
export const CardSubtitle = styled.p`
    color: #292c2f;
    @media (max-width: 480px) {
        padding-bottom: 2rem;
    }
`;
export const CardContentBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const CardContentDate = styled.h4`
    color: #666b6e;
`;
export const CardContentMore = styled(Link)`
    font-size: 2rem;
    line-height: 2.6rem;
    transition: 0.6s ease;
    display: flex;
    align-items: center;
    color: ${PrimaryColor};
    @media (max-width: 768px) {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    svg {
        margin-left: 0.4rem;
        path {
            transition: 0.6s ease;
        }
    }
    :hover {
        transition: 0.6s ease;
        color: ${HoverColor};
        @media (max-width: 768px) {
            color: ${PrimaryColor};
        }
        svg {
            path {
                transition: 0.6s ease;
                stroke: ${HoverColor};
                @media (max-width: 768px) {
                    stroke: ${PrimaryColor};
                }
            }
        }
    }
`;

export const CardsPageContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6.4rem;
    @media (max-width: 768px) {
        margin-bottom: 4.4rem;
    }
    @media (max-width: 480px) {
    }
`;

export const CardsSectionTitle = styled.h2`
    padding-bottom: 3.2rem;
    @media (max-width: 768px) {
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 2rem;
    }
`;

// promohub search section

export const PromohubSearchResultContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7rem;
`;

export const PromohubSearchSingleResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
    @media (max-width: 768px) {
        gap: 3.8rem;
    }
    @media (max-width: 480px) {
        gap: 4rem;
    }
`;

export const PromohubSearchSingleResultCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ProductsSearchSectionContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
    padding-bottom: 60px;
`;

export const NotFoundText = styled.p`
    color: var(--unnamed, #292c2f);
    /* Web/Text large */
    font-family: 'HelveticaNeueCyr';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 1.625rem */
`;

export const ProductSearchCardContainer = styled.div`
    display: flex;
    flex-direction: ${({ displayMode }) => (displayMode === 'column' ? 'row' : 'column')};
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 3rem;
    height: 23rem;
    max-width: ${({ displayMode }) => (displayMode === 'column' ? '100%' : '42.7rem')};
    flex-basis: ${({ displayMode }) => (displayMode === 'column' ? '' : '100%')};
    align-self: ${({ displayMode }) => (displayMode === 'column' ? '' : 'stretch')};
    @media (max-width: 768px) {
        max-width: ${({ displayMode }) => (displayMode === 'column' ? '100%' : '31.4rem')};
        height: auto;
    }
    @media (max-width: 480px) {
        max-width: 100%;
        flex-direction: column;
        height: auto;
    }
    border-bottom: 1px solid var(--line, #d9dadb);
`;

export const ProductSearchCardImage = styled.div`
    width: 42.7rem;
    height: 21.2rem;
    margin-right: 2rem;
    @media (max-width: 768px) {
        width: 31.4rem;
        height: 18.9rem;
    }
    @media (max-width: 480px) {
        width: 28.8rem;
        height: 17.4rem;
        margin-right: 0;
    }
    & img {
        height: 100%;
        object-fit: cover;
    }
`;

export const ProductSearchCardContent = styled.div`
    min-width: ${({ displayMode }) => (displayMode === 'column' ? '87.3rem' : '100%')};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-basis: 100%;
    @media (max-width: 768px) {
        min-width: ${({ displayMode }) => (displayMode === 'column' ? '31.4rem' : '100%')};
    }
    @media (max-width: 480px) {
        min-width: 100%;
    }
    & > div {
        display: flex;
        align-items: flex-start;
        & > div {
            @media (max-width: 768px) {
                padding-bottom: ${({ displayMode }) => (displayMode === 'column' ? '1.6rem' : '2.4rem')};
            }
            @media (max-width: 480px) {
                padding-bottom: 1.6rem;
            }
        }
        &:last-child {
            width: 100%;
            gap: 1.8rem;
            div:last-child {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
        }
        p {
            br {
                padding-bottom: 0.8rem;
            }
        }
    }
`;

export const ProductSearchCardTopContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
`;

export const ProductSearchCardContentBottom = styled.div``;

export const ProductLinkWrapper = styled(Link)`
display: flex;
&:last-of-type div {
        border: none;
}
&:hover {
    ${ProductSearchCardContainer} ${ProductSearchCardContent} ${ProductsListPageCardContentTitle} {
        color: ${HoverColor};
        transition: 0.6s ease;
    }
    ${ProductSearchCardContainer} ${ProductSearchCardContent} ${ProductsListPageCardContentInner} ${ProductSearchCardContentBottom} ${PublicationContentMore} {
        color: ${HoverColor};
        transition: 0.6s ease;
    }
}

`;
