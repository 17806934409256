import Img1 from '../assets/images/OurTeamPage/staff/1.png';
import Img2 from '../assets/images/OurTeamPage/staff/2.png';
import Img3 from '../assets/images/OurTeamPage/staff/3.png';
import Img4 from '../assets/images/OurTeamPage/staff/4.png';
import Img5 from '../assets/images/OurTeamPage/staff/5.png';
import Img6 from '../assets/images/OurTeamPage/staff/6.png';
import Img7 from '../assets/images/OurTeamPage/staff/7.png';
import Img8 from '../assets/images/OurTeamPage/staff/8.png';
import Img9 from '../assets/images/OurTeamPage/staff/9.png';
import Img10 from '../assets/images/OurTeamPage/staff/10.png';
import Img11 from '../assets/images/OurTeamPage/staff/11.png';
import Img12 from '../assets/images/OurTeamPage/staff/12.png';
import Img13 from '../assets/images/OurTeamPage/staff/13.png';
import Img14 from '../assets/images/OurTeamPage/staff/14.png';
import Img15 from '../assets/images/OurTeamPage/staff/15.png';
import Img16 from '../assets/images/OurTeamPage/staff/16.png';
import Img17 from '../assets/images/OurTeamPage/staff/17.png';
import Img18 from '../assets/images/OurTeamPage/staff/18.png';
import Img19 from '../assets/images/OurTeamPage/staff/19.png';
import Img20 from '../assets/images/OurTeamPage/staff/20.png';
import Img21 from '../assets/images/OurTeamPage/staff/21.png';
import Img22 from '../assets/images/OurTeamPage/staff/22.png';
import Img23 from '../assets/images/OurTeamPage/staff/23.png';
import Img24 from '../assets/images/OurTeamPage/staff/24.png';
import Img25 from '../assets/images/OurTeamPage/staff/25.png';
import Img26 from '../assets/images/OurTeamPage/staff/26.png';
import Img27 from '../assets/images/OurTeamPage/staff/27.png';
import Img28 from '../assets/images/OurTeamPage/staff/28.png';
import Img30 from '../assets/images/OurTeamPage/staff/30.png';
import Img31 from '../assets/images/OurTeamPage/staff/31.png';
import Img32 from '../assets/images/OurTeamPage/staff/32.png';
import Img33 from '../assets/images/OurTeamPage/staff/33.png';
import Img34 from '../assets/images/OurTeamPage/staff/34.png';
import Img35 from '../assets/images/OurTeamPage/staff/35.png';
import Img36 from '../assets/images/OurTeamPage/staff/36.png';
import Img37 from '../assets/images/OurTeamPage/staff/37.png';
import Img38 from '../assets/images/OurTeamPage/staff/38.png';
import Img39 from '../assets/images/OurTeamPage/staff/39.png';
import Img40 from '../assets/images/OurTeamPage/staff/40.png';
import Img41 from '../assets/images/OurTeamPage/staff/41.png';
import Img42 from '../assets/images/OurTeamPage/staff/42.png';

export const staff = [
    {
        id: 1,
        name: 'Чупахин Вадим',
        post: 'ГЛАВА ПРЕДСТАВИТЕЛЬСТВА',
        photo: Img1,
    },
    {
        id: 2,
        name: 'Лихолап Максим',
        post: 'НАЧАЛЬНИК ОТДЕЛА МАРКЕТИНГА',
        photo: Img2,
    },
    {
        id: 3,
        name: 'Тарасова Анна',
        post: 'МЕНЕДЖЕР',
        photo: Img3,
    },
    {
        id: 4,
        name: 'Патоцкий Андрей',
        post: 'ГЛАВНЫЙ БУХГАЛТЕР',
        photo: Img4,
    },
    {
        id: 5,
        name: 'Батура Антон',
        post: 'МЕНЕДЖЕР ПО МАРКЕТИНГУ',
        photo: Img5,
    },
    {
        id: 6,
        name: 'Яковлева Светлана',
        post: 'СТАРШИЙ МЕНЕДЖЕР ПО МАРКЕТИНГУ',
        photo: Img6,
    },
    {
        id: 7,
        name: 'Руцкая Дарья',
        post: 'МЕНЕДЖЕР ПО МАРКЕТИНГУ',
        photo: Img7,
    },
    {
        id: 8,
        name: 'Лукашик Юрий',
        post: 'МЕНЕДЖЕР ПО МАРКЕТИНГУ ',
        photo: Img8,
    },
    {
        id: 9,
        name: 'Малыщик Ася',
        post: 'МЕНЕДЖЕР ПО КАЧЕСТВУ И ФАРМАКОНАДЗОРУ',
        photo: Img9,
    },
    {
        id: 10,
        name: 'Борисова Елена',
        post: 'ПОМОЩНИК РУКОВОДИТЕЛЯ',
        photo: Img10,
    },
    {
        id: 11,
        name: 'Корхова Лариса',
        post: 'БУХГАЛТЕР',
        photo: Img11,
    },
    {
        id: 12,
        name: 'Дубовская Анна',
        post: 'ТРЭЙД-МАРКЕТИНГ МЕНЕДЖЕР',
        photo: Img12,
    },
    {
        id: 13,
        name: 'Панковец Антон',
        post: 'МАРКЕТОЛОГ-АНАЛИТИК',
        photo: Img41,
    },
    {
        id: 14,
        name: 'Савищева Елена',
        post: 'SALES FORCE MANAGER ',
        photo: Img13,
    },
    {
        id: 15,
        name: 'Миронович Артем',
        post: 'РЕГИОНАЛЬНЫЙ МЕНЕДЖЕР',
        photo: Img14,
    },
    {
        id: 16,
        name: 'Шаковец Алексей',
        post: 'РЕГИОНАЛЬНЫЙ МЕНЕДЖЕР',
        photo: Img15,
    },
    {
        id: 17,
        name: 'Ibolit',
        post: 'ВИРТУАЛЬНЫЙ ЭКСПЕРТ',
        photo: Img16,
    },
    {
        id: 18,
        name: 'Кокотова Наталья',
        post: 'МЕНЕДЖЕР ПО ДОСТУПУ ПРЕПАРАТОВ НА РЫНОК И РАБОТЕ С КЛЮЧЕВЫМИ КЛИЕНТАМИ',
        photo: Img17,
    },
    {
        id: 19,
        name: 'Федорук Сергей',
        post: 'МЕНЕДЖЕР ПО РЕГИСТРАЦИИ ЛЕКАРСТВЕННЫХ СРЕДСТВ',
        photo: Img18,
    },
    {
        id: 20,
        name: 'Казак Наталья',
        post: 'МЕНЕДЖЕР ПО РЕГИСТРАЦИИ ЛЕКАРСТВЕННЫХ СРЕДСТВ',
        photo: Img19,
    },
    {
        id: 21,
        name: 'Едейко Марина',
        post: 'СТАРШИЙ МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img20,
    },
    {
        id: 22,
        name: 'Якименко Виктория',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img21,
    },
    {
        id: 23,
        name: 'Стаёха Алеся',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img22,
    },
    {
        id: 24,
        name: 'Григорьева Екатерина',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img23,
    },
    {
        id: 25,
        name: 'Лухверчик Виталий',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img24,
    },
    {
        id: 25,
        name: 'Малеж Алексей',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img25,
    },
    {
        id: 27,
        name: 'Кулешевская Анна',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img26,
    },
    {
        id: 28,
        name: 'Рутенберг Анна',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img27,
    },
    {
        id: 29,
        name: 'Гайдученко Елена',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img28,
    },
    {
        id: 30,
        name: 'Евсеенко Инна',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img30,
    },
    {
        id: 31,
        name: 'Лапко Алеся',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img31,
    },
    {
        id: 32,
        name: 'Решетник Ольга',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img32,
    },
    {
        id: 33,
        name: 'Иванов Игорь',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img33,
    },
    {
        id: 34,
        name: 'Иванчик Александра',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img34,
    },
    {
        id: 35,
        name: 'Шибко Артем',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img35,
    },
    {
        id: 36,
        name: 'Рощина Марина',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img36,
    },
    {
        id: 37,
        name: 'Фурман Андрей',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img37,
    },
    {
        id: 38,
        name: 'Труханович Анастасия',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img38,
    },
    {
        id: 39,
        name: 'Ашуркевич Анна',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img39,
    },
    {
        id: 40,
        name: 'Четырбок Надежда',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img40,
    },
    {
        id: 41,
        name: 'Холодинская Наталья',
        post: 'МЕДИЦИНСКИЙ ПРЕДСТАВИТЕЛЬ',
        photo: Img42,
    },
];
