/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
import { fetch } from 'services/NewsService';
import { PromohubSingle } from '../../../../components/common/PromohubSingle';
// import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
// import { Container, Row } from 'styles/commons';
// import Loader from 'components/atoms/Loader';
// import PopularNewsRightBlock from 'components/molecules/PopularNewsRightBlock';
// import { Redirect } from 'react-router-dom';
// import Header from '../../../components/common/Header/Header';
// import Footer from '../../../components/common/Footer/Footer';
// import { PostContainer, NewsPageContainer, News, Title, Text } from './styles';
// import { BreadCrumbs } from '../../../components/common/BreadCrumbs';

const NewsItemPage = (props) => {
    const [loader, setLoader] = useState(true);
    const [news, setNews] = useState([]);
    // const [error, setError] = useState(false);

    const getNews = (id) => {
        fetch(id)
            .then((response) => {
                setNews(response.data.news);
                setLoader(false);
            })
            .catch((e) => {
                // setError(true);
                console.log(e);
            });
    };
    useEffect(() => {
        const idNews = props.match.params.newsId;
        getNews(idNews);
    }, [props.match.params.newsId]);

    return <PromohubSingle promohub={news} loader={loader} />;
};

export default NewsItemPage;
