import styled from 'styled-components';
import { Grey, White } from 'styles/colors';
import { screenSizeSmall } from 'styles/screenSize';
import ReactPlayer from 'react-player';

export const MissionSectionWrapper = styled.section`
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const MissionBlock = styled.section`
    background-color: ${White};
`;

export const RightBlock = styled.div`
    padding-right: 1rem;
    width: 50%;
    margin-bottom: 1.5rem;
    h3 {
        font-size: 3rem;
        margin-bottom: 0.5rem;
        margin: 2.4rem 0rem -0.8rem 0rem;
        color: ${Grey};
    }
    p {
        color: ${Grey};
        margin: 2rem 0rem 0rem 0rem;
        font-size: 1.7rem;
        line-height: 2.5rem;
    }
    @media screen and (max-width: ${screenSizeSmall}) {
        margin: 2rem 2rem;
        width: 90%;
        height: fit-content;
    }
`;

export const VideoBlock = styled.div`
    width: 50%;
    position: absolute;
    right: 0;
    @media screen and (max-width: ${screenSizeSmall}) {
        height: 30rem;
        width: 100%;
        position: inherit;
    }
`;

export const Player = styled(ReactPlayer)`
    @media screen and (max-width: ${screenSizeSmall}) {
        width: 100%;
    }
`;
