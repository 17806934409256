/* eslint-disable react/prop-types */
import React from 'react';

export default function PopupPartnersFormCloseSvg({ handlePopupClose }) {
    const handleKeyDown = (event) => {
        if (event.key === 'Esc' || event.key === ' ') {
            handlePopupClose();
        }
    };
    return (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <div onClick={handlePopupClose} onKeyDown={handleKeyDown} role="button" tabIndex={0} className="close-popup">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M18.36 19.7797L12 13.4097L5.63997 19.7797L4.21997 18.3597L10.59 11.9997L4.21997 5.63973L5.63997 4.21973L12 10.5897L18.36 4.22973L19.77 5.63973L13.41 11.9997L19.77 18.3597L18.36 19.7797Z"
                    fill="#FF7100"
                />
            </svg>
        </div>
    );
}
