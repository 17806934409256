import React from 'react';
import { Container, ContainerFull } from '../../../../styles/commons';
import CurrentVacanciesCard from './CurrentVacanciesCard/CurrentVacanciesCard';
import { content } from '../../../../commons/content/index';
import { CurrentVacanciesContainer, CurrentVacanciesTitle } from './CurrentVacancies.styles';
import { currentVacancies } from '../../../../commons/currentVacancies';

export default function CurrentVacancies() {
    return (
        <ContainerFull paddingBottom="12rem">
            <Container>
                <CurrentVacanciesTitle>{content.CURRENT_VACANCIES_TITLE}</CurrentVacanciesTitle>
                <CurrentVacanciesContainer>
                    {currentVacancies &&
                        currentVacancies.map((item) => {
                            return (
                                <CurrentVacanciesCard
                                    key={item.id}
                                    title={item.title}
                                    category={item.category}
                                    workingFormat={item.workingFormat}
                                    postDate={item.postDate}
                                    location={item.location}
                                    buttonLink={item.buttonLink}
                                />
                            );
                        })}
                </CurrentVacanciesContainer>
            </Container>
        </ContainerFull>
    );
}
