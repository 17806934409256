import styled from 'styled-components';

export const HistorySectionContainer = styled.div`
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.2rem 0;
    @media (max-width: 768px) {
        gap: 2.4rem 0;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;
export const HistorySectionSeparator = styled.div`
    position: absolute;
    top: 3rem;
    z-index: 1;
    width: 0.4rem;
    height: 320rem;
    background-color: #d9dadb;
    @media (max-width: 768px) {
        height: 243rem;
        left: -0.2rem;
    }
    @media (max-width: 480px) {
        height: 316rem;
    }
`;
