import React from 'react';
import PropTypes from 'prop-types';
import {
    HistorySectionItemContainer,
    HistorySectionItemText,
    HistorySectionItemTextBold,
    HistorySectionItemTitle,
} from './HistorySectionItem.styles';

const HistorySectionItem = ({ title, paragraph, paragraphBold, justifyContent }) => {
    return (
        <HistorySectionItemContainer justifyContent={justifyContent}>
            {title && <HistorySectionItemTitle>{title}</HistorySectionItemTitle>}
            {paragraph &&
                paragraph.map((item) => {
                    return <HistorySectionItemText key={item}>{item}</HistorySectionItemText>;
                })}
            {paragraphBold && <HistorySectionItemTextBold>{paragraphBold}</HistorySectionItemTextBold>}
        </HistorySectionItemContainer>
    );
};

HistorySectionItem.defaultProps = {
    title: '',
    paragraph: [],
    paragraphBold: '',
};
HistorySectionItem.propTypes = {
    title: PropTypes.string,
    justifyContent: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    paragraph: PropTypes.arrayOf(PropTypes.any),
    // eslint-disable-next-line react/forbid-prop-types
    paragraphBold: PropTypes.arrayOf(PropTypes.any),
};

export default HistorySectionItem;
