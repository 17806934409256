import styled from 'styled-components';

export const ButtonContainer = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3.2rem 0rem;
`;

export const Icon = styled.img`
    width: 7rem;
`;
export const Text = styled.p``;
