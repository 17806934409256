import React from 'react';
import ContentLoader from 'react-content-loader';
import { isMobile, isTablet } from '../../../../commons/constants';

export default function ProductItemPageSkeleton() {
    return (
        <>
            {isMobile && (
                <ContentLoader
                    speed={3}
                    width="28.8rem"
                    height="250rem"
                    viewBox="0 0 288 2500"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="28.8rem" height="28.8rem" />
                    <rect x="0" y="30.4rem" rx="0" ry="0" width="8.5rem" height="8.5rem" />
                    <rect x="20.3rem" y="30.4rem" rx="0" ry="0" width="8.5rem" height="8.5rem" />
                    <rect x="10.2rem" y="30.4rem" rx="0" ry="0" width="8.5rem" height="8.5rem" />
                    <rect x="0" y="43.3rem" rx="0" ry="0" width="28.8rem" height="3.8rem" />
                    <rect x="0" y="49rem" rx="0" ry="0" width="28.8rem" height="200rem" />
                </ContentLoader>
            )}

            {isTablet && !isMobile && (
                <ContentLoader
                    speed={3}
                    width="76.8rem"
                    height="120rem"
                    viewBox="0 0 768 1200"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="6rem" y="0" rx="0" ry="0" width="42.7rem" height="42.7rem" />
                    <rect x="6rem" y="44.7rem" rx="0" ry="0" width="12.9rem" height="12.9rem" />
                    <rect x="21rem" y="44.7rem" rx="0" ry="0" width="12.9rem" height="12.9rem" />
                    <rect x="36rem" y="44.7rem" rx="0" ry="0" width="12.9rem" height="12.9rem" />
                    <rect x="6rem" y="62.4rem" rx="0" ry="0" width="64.8rem" height="3.8rem" />
                    <rect x="6rem" y="69.2rem" rx="0" ry="0" width="64.8rem" height="113rem" />
                </ContentLoader>
            )}

            {!isMobile && !isTablet && (
                <ContentLoader
                    speed={3}
                    width="132rem"
                    height="139.6rem"
                    viewBox="0 0 1320 1396"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="42.7rem" height="42.7rem" />
                    <rect x="0" y="44.7rem" rx="0" ry="0" width="12.9rem" height="12.9rem" />
                    <rect x="14.9rem" y="44.7rem" rx="0" ry="0" width="12.9rem" height="12.9rem" />
                    <rect x="29.8rem" y="44.7rem" rx="0" ry="0" width="12.9rem" height="12.9rem" />
                    <rect x="44.7rem" y="0" rx="0" ry="0" width="87.3rem" height="6rem" />
                    <rect x="44.7rem" y="9.2rem" rx="0" ry="0" width="87.3rem" height="112.4rem" />
                </ContentLoader>
            )}
        </>
    );
}
