import styled from 'styled-components';

export const TeamSliderContainer = styled.div`
    position: relative;
    img {
        width: 100%;
        object-fit: cover;
    }
`;

export const TeamSliderContainerInner = styled.div`
    max-width: 123.2rem;
    margin: 0 auto;
`;
