import React from 'react';
import { content } from 'commons/content';
import {
    MultiSiteNav,
    CurrentSite,
    ArrowDown,
    Belarus,
    SiteDropDown,
    Li,
    Global,
    Ukraine,
    Kazakhstan,
    LinkTo,
} from './DropDownMenuCountry.styles';

const DropDownMenu = () => {
    return (
        <MultiSiteNav>
            <CurrentSite>
                <Belarus />
                {content.SELECT_COUNTRY_BELARUS}
                <ArrowDown />
            </CurrentSite>
            <SiteDropDown>
                <Li>
                    <Global />
                    <LinkTo href="https://acino.swiss">{content.SELECT_COUNTRY_GLOBAL}</LinkTo>
                </Li>
                <Li>
                    <Ukraine />
                    <LinkTo href="http://acino.ua/">{content.SELECT_COUNTRY_UKRAINE}</LinkTo>
                </Li>
                <Li>
                    <Kazakhstan />
                    <LinkTo href="https://acino.kz/">{content.SELECT_COUNTRY_KAZAKHSTAN}</LinkTo>
                </Li>
            </SiteDropDown>
        </MultiSiteNav>
    );
};

export default DropDownMenu;
