import styled from 'styled-components';
import BelarusFlag from 'assets/images/flags/belarus.png';
import DownArrow from 'assets/images/arrow-down.svg';
import GlobalFlag from 'assets/images/flags/global.png';
import RussiaFlag from 'assets/images/flags/flag_russia.png';
import UkraineFlag from 'assets/images/flags/flag_ukraine.png';
import KazakhstanFlag from 'assets/images/flags/flag_kazakhstan.png';

import { screenSizeSmall } from 'styles/screenSize';
import { Background, TextColor } from '../../../../styles/colors';

export const MultiSiteNav = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    transition: all 0.5s;
    div {
        i {
            transition: 0.6s ease;
        }
    }
    &:hover {
        div {
            i {
                transform: rotate(180deg);
                transition: 0.6s ease;
            }
        }
        ul {
            display: block;
            border-radius: 0.8rem;
            li:first-child:hover {
                border-radius: 0.8rem 0.8rem 0 0;
            }
            li:last-child:hover {
                border-radius: 0rem 0rem 0.8rem 0.8rem;
            }
        }
    }
`;

export const CurrentSite = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.8rem 1.6rem 0.8rem 4.4rem;
    margin: 0;
    width: 13.6rem;
    height: 4rem;
    box-sizing: border-box;
    border-radius: 0.8rem;
    border: 0.1rem solid #d9dadb;
    background-color: ${Background};
    line-height: 4rem;
    color: ${TextColor};
    font-size: 1.6rem;
    box-sizing: border-box;
    cursor: pointer;
    &::after {
        content: '';
        position: absolute;
        display: block;
        overflow: hidden;
        top: 0;
        right: 0;
        background-color: transparent;
        border-left: 0.1rem solid #d9dadb;
        width: 2rem;
        height: 4rem;
        -webkit-border-top-right-radius: 0.5rem;
        -webkit-border-bottom-right-radius: 0.5rem;
        -moz-border-top-right-radius: 0.5rem;
        -moz-border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        -webkit-transition: top 0.5s;
        -moz-transition: top 0.5s;
        -ms-transition: top 0.5s;
        -o-transition: top 0.5s;
        transition: top 0.5s;
    }
`;

export const Belarus = styled.span`
    position: absolute;
    display: block;
    width: 2rem;
    height: 1.3rem;
    background-image: url(${BelarusFlag});
    background-repeat: no-repeat;
    background-size: cover;
    top: 1.3rem;
    left: 1rem;
`;

export const ArrowDown = styled.i`
    position: absolute;
    display: block;
    top: 1.2rem;
    bottom: 1.2rem;
    right: 0.2rem;
    margin-top: -0.25rem;
    background-color: transparent;
    background-image: url(${DownArrow});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.6rem 1.7rem;
    transition: display 0.5s ease-in-out;
    width: 1.6rem;
    height: 1.6rem;
    z-index: 5;
    @media screen and (max-width: ${screenSizeSmall}) {
        display: ${({ open }) => (open ? 'none' : 'block')};
    }
`;

export const SiteDropDown = styled.ul`
    top: 7rem;
    position: absolute;
    display: none;
    list-style: none;
    background-color: ${Background};
    padding-left: 0;
`;

export const Li = styled.li`
    position: relative;
    display: block;
    box-sizing: border-box;
    transition: 0.6s ease;
    &:hover {
        background-color: #d9dadb;
        transition: 0.6s ease;
    }
`;

export const Global = styled.i`
    position: absolute;
    display: block;
    width: 2rem;
    height: 1.3rem;
    background-image: url(${GlobalFlag});
    background-repeat: no-repeat;
    top: 1.3rem;
    left: 1rem;
`;

export const Russia = styled.i`
    position: absolute;
    display: block;
    width: 2rem;
    height: 1.3rem;
    background-image: url(${RussiaFlag});
    background-repeat: no-repeat;
    top: 1.3rem;
    left: 1rem;
`;
export const Ukraine = styled.i`
    position: absolute;
    display: block;
    width: 2rem;
    height: 1.3rem;
    background-image: url(${UkraineFlag});
    background-repeat: no-repeat;
    top: 1.3rem;
    left: 1rem;
`;
export const Kazakhstan = styled.i`
    position: absolute;
    display: block;
    width: 2rem;
    height: 1.3rem;
    background-image: url(${KazakhstanFlag});
    background-repeat: no-repeat;
    top: 1.3rem;
    left: 1rem;
`;

export const LinkTo = styled.a`
    display: block;
    color: ${TextColor};
    width: 100%;
    height: auto;
    padding: 0.8rem 1.6rem 0.8rem 4.4rem;
    z-index: 5;
    box-sizing: border-box;
    text-decoration: none;
`;
