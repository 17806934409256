import styled from 'styled-components';
import { Grey2 } from 'styles/colors';
import { FlexItem } from 'styles/commons';
import { screenSizeSmall } from 'styles/screenSize';
import { HoverColor, PrimaryColor } from '../../../styles/colors';
// import ContactFooter from 'assets/images/contactFooter.jpg';

export const FooterContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    /* width: 100%;
    padding: 4rem 0;
    position: relative; */
    background-color: ${Grey2};
    padding: 3rem 0;
    @media screen and (max-width: ${screenSizeSmall}) {
        display: flex;
        flex-flow: column;
    }
`;

export const FlexBlock = styled(FlexItem)`
    justify-content: space-between;
`;

export const Copyright = styled.div`
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 6.4rem;
    border-top: 0.1rem solid #292c2f;
    text-align: center;
    margin-top: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
        margin-top: 4.4rem;
        padding-top: 2.4rem;
        padding-bottom: 4.4rem;
    }
    @media (max-width: 480px) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 3.2rem;
        padding-top: 1.6rem;
        padding-bottom: 2.4rem;
    }
`;

export const FeaturesLinks = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65rem;
    @media (max-width: 768px) {
        width: 44rem;
    }
    @media (max-width: 480px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
    a {
        transition: 0.6s ease;
        color: #292c2f;
        @media (max-width: 480px) {
            margin-left: 0;
            margin-bottom: 0.8rem;
            &:last-child {
                margin-bottom: 1.6rem;
            }
        }
        :hover {
            transition: 0.6s ease;
            color: ${HoverColor};
            @media (max-width: 768px) {
                color: ${PrimaryColor};
            }
        }
    }
`;
