/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { HoverColor, PrimaryColor } from '../../styles/colors';

export const CookiePageContainer = styled.div`
    width: 100%;
    h1 {
        font-size: 4.8rem;
        line-height: 5.8rem;
        padding-bottom: 3.2rem;
        @media (max-width: 768px) {
            padding-bottom: 2.4rem;
        }
        @media (max-width: 480px) {
            padding-bottom: 2rem;
        }
    }
    h2 {
        font-size: 2.4rem;
        line-height: 2.9rem;
        color: #666b6e;
        padding-bottom: 3.2rem;
        @media (max-width: 768px) {
            padding-bottom: 2.4rem;
        }
        @media (max-width: 480px) {
            padding-bottom: 2rem;
        }
    }
    h3 {
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: ${PrimaryColor};
        padding-bottom: 3.2rem;
        @media (max-width: 768px) {
            padding-top: 2.4rem;
            padding-bottom: 2.4rem;
        }
        @media (max-width: 480px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
    p {
        text-align: justify;
        padding-bottom: 1.6rem;
        &:last-child {
            padding-bottom: 0;
        }
        @media (max-width: 768px) {
            padding-bottom: 0.8rem;
        }
        strong {
            font-size: inherit;
            line-height: inherit;
            font-weight: 550;
        }
        a {
            font-size: inherit;
            line-height: inherit;
            color: ${PrimaryColor};
            transition: 0.6s ease;
            :hover {
                color: ${HoverColor};
                transition: 0.6s ease;
                @media (max-width: 768px) {
                    color: ${PrimaryColor};
                }
            }
        }
    }
`;
