import React from 'react';
import { PropTypes } from 'prop-types';
import { Container } from 'styles/commons';
import {
    CompanyValuesCards,
    CompanyValuesCardsCard,
    CompanyValuesCardsCardContent,
    CompanyValuesCardsCardContentSubtitle,
    CompanyValuesCardsCardContentTitle,
    CompanyValuesCardsCardImg,
    CompanyValuesTitle,
} from './WeOfferSection.styles';
import WeOfferSectionPng1 from '../../../../assets/images/WeOffer/1.png';
import WeOfferSectionPng2 from '../../../../assets/images/WeOffer/2.png';
import WeOfferSectionPng3 from '../../../../assets/images/WeOffer/3.png';
import WeOfferSectionPng4 from '../../../../assets/images/WeOffer/4.png';
import { ContainerFull } from '../../../../styles/commons';
import { content } from '../../../../commons/content';

export const WeOfferSection = ({ paddingBottom }) => {
    return (
        <ContainerFull paddingBottom={paddingBottom}>
            <Container>
                <CompanyValuesTitle>Мы предлагаем</CompanyValuesTitle>
                <CompanyValuesCards>
                    <CompanyValuesCardsCard>
                        <CompanyValuesCardsCardImg>
                            <img src={WeOfferSectionPng1} alt={content.WE_OFFER_SECTION_TITLE1} />
                        </CompanyValuesCardsCardImg>
                        <CompanyValuesCardsCardContent>
                            <CompanyValuesCardsCardContentTitle>
                                {content.WE_OFFER_SECTION_TITLE1}
                            </CompanyValuesCardsCardContentTitle>
                            <CompanyValuesCardsCardContentSubtitle>
                                {content.WE_OFFER_SECTION_SUBTITLE1}
                            </CompanyValuesCardsCardContentSubtitle>
                        </CompanyValuesCardsCardContent>
                    </CompanyValuesCardsCard>
                    <CompanyValuesCardsCard>
                        <CompanyValuesCardsCardImg>
                            <img src={WeOfferSectionPng2} alt={content.WE_OFFER_SECTION_TITLE2} />
                        </CompanyValuesCardsCardImg>
                        <CompanyValuesCardsCardContent>
                            <CompanyValuesCardsCardContentTitle>
                                {content.WE_OFFER_SECTION_TITLE2}
                            </CompanyValuesCardsCardContentTitle>
                            <CompanyValuesCardsCardContentSubtitle>
                                {content.WE_OFFER_SECTION_SUBTITLE2}
                            </CompanyValuesCardsCardContentSubtitle>
                        </CompanyValuesCardsCardContent>
                    </CompanyValuesCardsCard>
                    <CompanyValuesCardsCard>
                        <CompanyValuesCardsCardImg>
                            <img src={WeOfferSectionPng3} alt={content.WE_OFFER_SECTION_TITLE3} />
                        </CompanyValuesCardsCardImg>
                        <CompanyValuesCardsCardContent>
                            <CompanyValuesCardsCardContentTitle>
                                {content.WE_OFFER_SECTION_TITLE3}
                            </CompanyValuesCardsCardContentTitle>
                            <CompanyValuesCardsCardContentSubtitle>
                                {content.WE_OFFER_SECTION_SUBTITLE3}
                            </CompanyValuesCardsCardContentSubtitle>
                        </CompanyValuesCardsCardContent>
                    </CompanyValuesCardsCard>
                    <CompanyValuesCardsCard>
                        <CompanyValuesCardsCardImg>
                            <img src={WeOfferSectionPng4} alt={content.WE_OFFER_SECTION_TITLE4} />
                        </CompanyValuesCardsCardImg>
                        <CompanyValuesCardsCardContent>
                            <CompanyValuesCardsCardContentTitle>
                                {content.WE_OFFER_SECTION_TITLE4}
                            </CompanyValuesCardsCardContentTitle>
                            <CompanyValuesCardsCardContentSubtitle>
                                {content.WE_OFFER_SECTION_SUBTITLE4}
                            </CompanyValuesCardsCardContentSubtitle>
                        </CompanyValuesCardsCardContent>
                    </CompanyValuesCardsCard>
                </CompanyValuesCards>
            </Container>
        </ContainerFull>
    );
};

WeOfferSection.defaultProps = {
    paddingBottom: undefined,
};

WeOfferSection.propTypes = {
    paddingBottom: PropTypes.string,
};

export default WeOfferSection;
