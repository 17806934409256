import styled from 'styled-components';
import { PrimaryColor } from '../../../styles/colors';

export const CompanyInNumbersItem = styled.div`
    border-left: 0.1rem solid #d9dadb;
    padding-left: 3.2rem;
    @media (max-width: 768px) {
        padding-left: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 1.6rem;
        :last-child {
            margin-bottom: 0;
        }
    }
`;
export const CompanyInNumbersTitle = styled.h2`
    margin-bottom: 3.2rem;
    @media (max-width: 768px) {
        margin-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 2rem;
    }
`;
export const CompanyInNumbersContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        align-items: flex-start;
        justify-content: center;
        flex-wrap: nowrap;
    }
    @media (max-width: 480px) {
        flex-direction: column;
    }
`;
export const CompanyInNumbersItemTitle = styled.h3`
    font-size: 6rem;
    line-height: 7.2rem;
    color: ${PrimaryColor};
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        font-size: 4.4rem;
        line-height: 5.3rem;
        margin-bottom: 1.6rem;
    }
    @media (max-width: 480px) {
        font-size: 3.2rem;
        line-height: 3.8rem;
        margin-bottom: 0.4rem;
    }
`;
export const CompanyInNumbersItemText = styled.p`
    font-size: 2.4rem;
    line-height: 2.9rem;
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-weight: 550;
    }
    @media (max-width: 480px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
`;
