import React from 'react';
import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
// import { content } from 'commons/content';
// import { externalLink } from 'commons/constants';
import BreadCrumbs from '../../../components/common/BreadCrumbs/BreadCrumbs';
import DynamicTitle from '../../../components/molecules/DynamicTitle/DynamicTitle';
import { HeaderSlide } from '../../../components/common/HeaderSlide';
import { content } from '../../../commons/content';
import { GlobalContainer } from '../../../styles/commons';
import ImgWeb from '../../../assets/images/OurTeamPage/OurTeamHeaderWeb.png';
import ImgTab from '../../../assets/images/OurTeamPage/OurTeamHeaderTab.png';
import ImgMob from '../../../assets/images/OurTeamPage/OurTeamHeaderMob.png';
import { ContactsSection, TeamSection } from '../../../components/common/Section';

const TeamPage = () => {
    return (
        <>
            <DynamicTitle title="Команда" />
            <BreadCrumbs colorDefault="#FFFFFF80" colorActive="#fff" />
            <GlobalContainer>
                <HeaderSlide
                    data={{
                        title: content.HEADER_SLIDE_TEAM_TITLE,
                        subtitle: content.HEADER_SLIDE_TEAM_SUBTITLE,
                        bgColor: 'linear-gradient(90deg, #357972 0%, #80B84D 55.21%, #C7C261 100%)',
                    }}
                    images={{ web: ImgWeb, tab: ImgTab, mob: ImgMob }}
                />
                <TeamSection />
                <ContactsSection backgroundColor="#F0F0F0" />
                <GoogleAnalitycs />
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
};

export default TeamPage;
