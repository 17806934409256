import React from 'react';
import { PropTypes } from 'prop-types';
import { Container } from 'styles/commons';
import {
    CompanyValuesCards,
    CompanyValuesCardsCard,
    CompanyValuesCardsCardContent,
    CompanyValuesCardsCardContentSubtitle,
    CompanyValuesCardsCardContentTitle,
    CompanyValuesCardsCardSvg,
    CompanyValuesTitle,
} from './CompanyValuesSection.styles';
import { content } from '../../../../commons/content';
import { ContainerFull } from '../../../../styles/commons';
import CompanyValuesCardPng1 from '../../../../assets/images/CompanyValuesSection/1.png';
import CompanyValuesCardPng2 from '../../../../assets/images/CompanyValuesSection/2.png';
import CompanyValuesCardPng3 from '../../../../assets/images/CompanyValuesSection/3.png';
import CompanyValuesCardPng4 from '../../../../assets/images/CompanyValuesSection/4.png';

export const CompanyValuesSection = ({ paddingBottom }) => {
    return (
        <ContainerFull paddingBottom={paddingBottom}>
            <Container>
                <CompanyValuesTitle>Ценности компании</CompanyValuesTitle>
                <CompanyValuesCards>
                    <CompanyValuesCardsCard>
                        <CompanyValuesCardsCardSvg>
                            <img src={CompanyValuesCardPng1} alt={content.COMPANY_VALUES_SECTION_TITLE1} />
                        </CompanyValuesCardsCardSvg>
                        <CompanyValuesCardsCardContent>
                            <CompanyValuesCardsCardContentTitle>
                                {content.COMPANY_VALUES_SECTION_TITLE1}
                            </CompanyValuesCardsCardContentTitle>
                            <CompanyValuesCardsCardContentSubtitle>
                                {content.COMPANY_VALUES_SECTION_SUBTITLE1}
                            </CompanyValuesCardsCardContentSubtitle>
                        </CompanyValuesCardsCardContent>
                    </CompanyValuesCardsCard>
                    <CompanyValuesCardsCard>
                        <CompanyValuesCardsCardSvg>
                            <img src={CompanyValuesCardPng2} alt={content.COMPANY_VALUES_SECTION_TITLE2} />
                        </CompanyValuesCardsCardSvg>
                        <CompanyValuesCardsCardContent>
                            <CompanyValuesCardsCardContentTitle>
                                {content.COMPANY_VALUES_SECTION_TITLE2}
                            </CompanyValuesCardsCardContentTitle>
                            <CompanyValuesCardsCardContentSubtitle>
                                {content.COMPANY_VALUES_SECTION_SUBTITLE2}
                            </CompanyValuesCardsCardContentSubtitle>
                        </CompanyValuesCardsCardContent>
                    </CompanyValuesCardsCard>
                    <CompanyValuesCardsCard>
                        <CompanyValuesCardsCardSvg>
                            <img src={CompanyValuesCardPng3} alt={content.COMPANY_VALUES_SECTION_TITLE3} />
                        </CompanyValuesCardsCardSvg>
                        <CompanyValuesCardsCardContent>
                            <CompanyValuesCardsCardContentTitle>
                                {content.COMPANY_VALUES_SECTION_TITLE3}
                            </CompanyValuesCardsCardContentTitle>
                            <CompanyValuesCardsCardContentSubtitle>
                                {content.COMPANY_VALUES_SECTION_SUBTITLE3}
                            </CompanyValuesCardsCardContentSubtitle>
                        </CompanyValuesCardsCardContent>
                    </CompanyValuesCardsCard>
                    <CompanyValuesCardsCard>
                        <CompanyValuesCardsCardSvg>
                            <img src={CompanyValuesCardPng4} alt={content.COMPANY_VALUES_SECTION_TITLE4} />
                        </CompanyValuesCardsCardSvg>
                        <CompanyValuesCardsCardContent>
                            <CompanyValuesCardsCardContentTitle>
                                {content.COMPANY_VALUES_SECTION_TITLE4}
                            </CompanyValuesCardsCardContentTitle>
                            <CompanyValuesCardsCardContentSubtitle>
                                {content.COMPANY_VALUES_SECTION_SUBTITLE4}
                            </CompanyValuesCardsCardContentSubtitle>
                        </CompanyValuesCardsCardContent>
                    </CompanyValuesCardsCard>
                </CompanyValuesCards>
            </Container>
        </ContainerFull>
    );
};

export default CompanyValuesSection;

CompanyValuesSection.defaultProps = {
    paddingBottom: undefined,
};

CompanyValuesSection.propTypes = {
    paddingBottom: PropTypes.string,
};
