/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { content } from 'commons/content';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { White, LightBlack, LightGrey2 } from 'styles/colors';
import { screenSizeSmall } from 'styles/screenSize';
import SearchInput from '../components/common/SearchPanel/SearchInput/SearchInput';
import { Background, HoverColor, PrimaryColor } from '../styles/colors';
import { fetchSearchAutocompleteData } from '../services/NewsService';
import { Autocomplete } from '../components/common/SearchPanel/Autocomplete';

export const useSearch = (setSelectedLetter) => {
    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const [showAutocomplete, setShowAutocomplete] = useState();
    const [autocompleteItems, setAutocompleteItems] = useState([]);

    const history = useHistory();
    const { search } = useLocation();
    const select = useMemo(() => new URLSearchParams(search).get('term'), [search]);

    const resetSearch = () => {
        setValue('');
    };

    const setSelect = (data) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set('term', data);
        const newSearch = searchParams.toString();
        history.replace({ search: `?${newSearch}` });
    };

    useEffect(() => {
        setSelect(value);
        if (setSelectedLetter) {
            setSelectedLetter('');
        }

        if (value.length !== 0)
            _.debounce(
                () =>
                    fetchSearchAutocompleteData({ term: value })
                        .then(({ data }) => data)
                        .then(setAutocompleteItems),
                250,
            )();
    }, [value]);

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (!params.has('term')) {
            setSelect('');
        } else {
            setValue(select);
        }
    }, []);

    // eslint-disable-next-line react/prop-types
    const renderSearch = (handlers) => (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                setShowAutocomplete(false);
                if (handlers && handlers.onSubmit) handlers.onSubmit();
            }}
        >
            <SearchInput
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    setShowAutocomplete(e.target.value.length !== 0);
                }}
                resetSearch={resetSearch}
                onFocus={() => {
                    setIsFocused(true);
                    setShowAutocomplete(true);
                }}
                onBlur={() => {
                    setIsFocused(false);
                    setTimeout(() => setShowAutocomplete(false), 200);
                    // eslint-disable-next-line react/prop-types
                    if (handlers && handlers.onBlur) handlers.onBlur();
                }}
                isFocused={isFocused}
            />
            {showAutocomplete && <Autocomplete items={autocompleteItems} onItemClick={setValue} />}
        </Form>
    );

    return { renderSearch, searchValue: value, resetSearch, setSearchValue: setValue };
};

export const useGlobalSearch = () => {
    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const [showAutocomplete, setShowAutocomplete] = useState();
    const [autocompleteItems, setAutocompleteItems] = useState([]);

    const resetSearch = () => {
        setValue('');
    };

    useEffect(() => {
        setShowAutocomplete(value.length !== 0);
        if (value.length !== 0)
            _.debounce(
                () =>
                    fetchSearchAutocompleteData({ term: value })
                        .then(({ data }) => data)
                        .then(setAutocompleteItems),
                250,
            )();
    }, [value]);

    // eslint-disable-next-line react/prop-types
    const renderSearch = (handlers) => (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                setShowAutocomplete(false);
                if (handlers && handlers.onSubmit) handlers.onSubmit();
            }}
        >
            <SearchInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                resetSearch={resetSearch}
                onFocus={() => {
                    setIsFocused(true);
                    setShowAutocomplete(true);
                }}
                onBlur={() => {
                    setIsFocused(false);
                    setTimeout(() => setShowAutocomplete(false), 200);
                    // eslint-disable-next-line react/prop-types
                    if (handlers && handlers.onBlur) handlers.onBlur();
                }}
                isFocused={isFocused}
            />
            {showAutocomplete && <Autocomplete items={autocompleteItems} onItemClick={setValue} />}
        </Form>
    );

    return { renderSearch, searchValue: value, resetSearch, setSearchValue: setValue };
};

export const useSort = () => {
    const [order, setOrder] = useState('desc');

    const renderSort = () => (
        <SortButton type="button" onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}>
            {order === 'asc' ? (
                <FontAwesomeIcon icon={faArrowUp} size="1x" />
            ) : (
                <FontAwesomeIcon icon={faArrowDown} size="1x" />
            )}
        </SortButton>
    );

    const resetSort = () => {
        setOrder('desc');
    };

    return { renderSort, sortValue: order, resetSort };
};

export const useSelect = () => {
    const history = useHistory();
    const { search } = useLocation();
    const select = useMemo(() => new URLSearchParams(search).get('direction'), [search]);

    const setSelect = (value) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set('direction', value);
        const newSearch = searchParams.toString();
        history.replace({ search: `?${newSearch}` });
    };

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (!params.has('direction')) {
            setSelect('0');
        }
    }, [search]);
    const [isOpen, setIsOpen] = useState(false);

    const handleHover = () => {
        setIsOpen(true);
    };

    const handleLeave = () => {
        setIsOpen(false);
    };

    const handleSelect = (value) => {
        setSelect(value);
        setIsOpen(false);
    };

    const getContentByValue = (value, categoryContent) => {
        return categoryContent[value];
    };

    const handleKeyPress = (event, value) => {
        if (event.key === 'Enter') {
            handleSelect(value);
        }
    };

    const renderSelect = () => {
        const categoryContent = {
            '0': 'Все направления',
            '1': 'Гинекология',
            '2': 'Кардиология',
            '3': 'Неврология',
            '4': 'Терапия',
            '5': 'Эндокринология',
        };

        return (
            <DropDownContainer onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                <CurrentDropDownItem
                    className="current-site"
                    onMouseDown={() => setIsOpen((prev) => !prev)}
                    onKeyDown={(event) => handleKeyPress(event, select)}
                    tabIndex={0}
                    aria-expanded={isOpen}
                    type="button"
                >
                    {getContentByValue(select, categoryContent)}
                    <div>
                        <svg
                            width="1.6rem"
                            height="1.6rem"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M4 6L8 10L12 6" stroke="#FF7100" strokeWidth="2" />
                        </svg>
                    </div>
                </CurrentDropDownItem>
                {isOpen && (
                    <DropDownList>
                        <DropDownItem>
                            <button
                                type="button"
                                onClick={() => handleSelect('0')}
                                onKeyDown={(event) => handleKeyPress(event, '0')}
                            >
                                Все направления
                            </button>
                        </DropDownItem>
                        <DropDownItem>
                            <button
                                type="button"
                                onClick={() => handleSelect('1')}
                                onKeyDown={(event) => handleKeyPress(event, '1')}
                            >
                                {getContentByValue('1', categoryContent)}
                            </button>
                        </DropDownItem>
                        <DropDownItem>
                            <button
                                type="button"
                                onClick={() => handleSelect('2')}
                                onKeyDown={(event) => handleKeyPress(event, '2')}
                            >
                                {getContentByValue('2', categoryContent)}
                            </button>
                        </DropDownItem>
                        <DropDownItem>
                            <button
                                type="button"
                                onClick={() => handleSelect('3')}
                                onKeyDown={(event) => handleKeyPress(event, '3')}
                            >
                                {getContentByValue('3', categoryContent)}
                            </button>
                        </DropDownItem>
                        <DropDownItem>
                            <button
                                type="button"
                                onClick={() => handleSelect('4')}
                                onKeyDown={(event) => handleKeyPress(event, '4')}
                            >
                                {getContentByValue('4', categoryContent)}
                            </button>
                        </DropDownItem>
                        <DropDownItem>
                            <button
                                type="button"
                                onClick={() => handleSelect('5')}
                                onKeyDown={(event) => handleKeyPress(event, '5')}
                            >
                                {getContentByValue('5', categoryContent)}
                            </button>
                        </DropDownItem>
                    </DropDownList>
                )}
            </DropDownContainer>
        );
    };

    return { renderSelect, categoryValue: select };
};
export const DropDownContainer = styled.div`
    position: relative;
    display: inline-block;
    padding-left: 2rem;
    padding-top: 2rem;
    :hover {
        ul {
            display: block;
        }
    }
    @media (max-width: 480px) {
        padding-left: 0;
        padding-top: 0;
    }
`;
export const CurrentDropDownItem = styled.div`
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.1rem;
    width: 20rem;
    height: 4rem;
    border-radius: 0.8rem;
    border: 0.1rem solid #d9dadb;
    position: relative;
    padding: 0.8rem 1.6rem;
    cursor: pointer;
    :hover {
        div {
            svg {
                transition: 0.6s ease;
                transform: rotate(180deg);
            }
        }
    }
    div {
        position: absolute;
        width: 2.4rem;
        height: 3.7rem;
        top: 0;
        right: 0;
        border-left: 0.1rem solid #d9dadb;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            transition: 0.6s ease;
        }
    }
`;

export const DropDownList = styled.ul`
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: ${Background};
    z-index: 1;
    border-left: 0.1rem solid #d9dadb;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.08);
    width: 20rem;
    li:first-child {
        button {
            border-radius: 0.8rem 0.8rem 0 0;
        }
    }
    li:last-child {
        button {
            border-radius: 0 0 0.8rem 0.8rem;
        }
    }
`;
export const DropDownItem = styled.li`
    cursor: pointer;
    :hover {
        button {
            transition: 0.6s ease;
            background-color: #d9dadb;
        }
    }
    button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.4rem;
        transition: 0.6s ease;
        background-color: #fff;
        border: 0;
        outline: 0;
        padding: 0.8rem 1.6rem;
        text-align: start;
    }
`;
export const useSelectCategoriesProduct = () => {
    const history = useHistory();
    const { search } = useLocation();
    const select = useMemo(() => new URLSearchParams(search).get('categoryProd'), [search]);

    const setSelect = (value) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set('categoryProd', value || '0');
        const newSearch = searchParams.toString();
        history.replace({ search: `?${newSearch}` });
    };

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (!params.has('categoryProd')) {
            setSelect('0');
        }
    }, [search]);

    const renderSelectCategoriesProduct = (allCount, recCount, noRecCount, supCount) => (
        <>
            <SelectCategoryItem htmlFor="defaultCategory" active={select === '0'}>
                {`${content.PRODUCTS_LIST_PAGE_CATEGORY_DEFAULT} (${allCount})`}
                <SelectCategoryItemInput
                    type="radio"
                    id="defaultCategory"
                    value="0"
                    checked={select === '0'}
                    onChange={(e) => setSelect(e.target.value)}
                />
            </SelectCategoryItem>
            <SelectCategoryItem htmlFor="receptCategory" active={select === '1'}>
                {`${content.PRODUCTS_LIST_PAGE_CATEGORY_RECIPY} (${recCount})`}
                <SelectCategoryItemInput
                    type="radio"
                    id="receptCategory"
                    value="1"
                    checked={select === '1'}
                    onChange={(e) => setSelect(e.target.value)}
                />
            </SelectCategoryItem>
            <SelectCategoryItem htmlFor="bezreceptCategory" active={select === '2'}>
                {`${content.PRODUCTS_LIST_PAGE_CATEGORY_WITHOUT_RECIPY} (${noRecCount})`}
                <SelectCategoryItemInput
                    type="radio"
                    id="bezreceptCategory"
                    value="2"
                    checked={select === '2'}
                    onChange={(e) => setSelect(e.target.value)}
                />
            </SelectCategoryItem>
            <SelectCategoryItem htmlFor="badCategory" active={select === '3'}>
                {`${content.PRODUCTS_LIST_PAGE_CATEGORY_DIETARY_SUPPLEMENTS} (${supCount})`}
                <SelectCategoryItemInput
                    type="radio"
                    id="badCategory"
                    value="3"
                    checked={select === '3'}
                    onChange={(e) => setSelect(e.target.value)}
                />
            </SelectCategoryItem>
        </>
    );

    const resetSelectCategoriesProduct = () => {
        setSelect('0');
    };

    return { renderSelectCategoriesProduct, categoryValue: select, resetSelectCategoriesProduct };
};

export const useSelectCategoriesPromohub = () => {
    const history = useHistory();
    const { search } = useLocation();
    const select = useMemo(() => {
        return new URLSearchParams(search).get('category');
    }, [search]);

    const setSelect = (value) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set('category', value || '0');
        const newSearch = searchParams.toString();
        history.replace({ search: `?${newSearch}` });
    };

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (!params.has('category')) {
            setSelect('0');
        }
    }, [search]);

    const renderSelectCategoriesPromohub = (pubCount, videoCount, newsCount) => (
        <>
            <SelectCategoryItem htmlFor="publicationCategory" active={select === '0'}>
                {`${content.PROMOHUB_LIST_PAGE_CATEGORY_DEFAULT} (${pubCount})`}
                <SelectCategoryItemInput
                    type="radio"
                    id="publicationCategory"
                    value="0"
                    checked={select === '0'}
                    onChange={(e) => setSelect(e.target.value)}
                />
            </SelectCategoryItem>
            <SelectCategoryItem htmlFor="videoCategory" active={select === '1'}>
                {`${content.PROMOHUB_LIST_PAGE_CATEGORY_VIDEO} (${videoCount})`}
                <SelectCategoryItemInput
                    type="radio"
                    id="videoCategory"
                    value="1"
                    checked={select === '1'}
                    onChange={(e) => setSelect(e.target.value)}
                />
            </SelectCategoryItem>
            <SelectCategoryItem htmlFor="newsCategory" active={select === '2'}>
                {`${content.PROMOHUB_LIST_PAGE_CATEGORY_WITHOUT_NEWS} (${newsCount})`}
                <SelectCategoryItemInput
                    type="radio"
                    id="newsCategory"
                    value="2"
                    checked={select === '2'}
                    onChange={(e) => setSelect(e.target.value)}
                />
            </SelectCategoryItem>
        </>
    );

    const resetSelectCategoriesPromohub = () => {
        setSelect('0');
    };

    return { renderSelectCategoriesPromohub, categoryPromohubValue: select, resetSelectCategoriesPromohub };
};

export const usePagination = (defaultLastPage) => {
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(defaultLastPage);
    useEffect(() => {
        return () => {
            localStorage.setItem('paginationPage', page.toString());
        };
    }, [page]);

    useEffect(() => {
        const storedPage = localStorage.getItem('paginationPage');
        if (storedPage) {
            setPage(parseInt(storedPage));
        }
    }, []);
    const resetPagination = useCallback(() => {
        const storedPage = localStorage.getItem('paginationPage');
        if (storedPage) {
            setPage(parseInt(storedPage));
        } else {
            setPage(1);
        }
    }, []);
    const handleFirstPageClick = useCallback(() => {
        setPage(1);
    }, []);
    const handlePageClick = useCallback((pageNumber) => {
        setPage(pageNumber);
    }, []);
    const handlePrevPageClick = useCallback(() => {
        setPage((prevPage) => prevPage - 1);
    }, []);
    const handleNextPageClick = useCallback(() => {
        setPage((prevPage) => prevPage + 1);
    }, []);
    const renderPagination = useCallback(() => {
        if (lastPage <= 1) {
            return null;
        }
        const previousDisabled = page === 1;
        const nextDisabled = page === lastPage;
        const paginationItems = [];
        if (page > 1) {
            paginationItems.push(
                <PaginationButton key="first" type="button" onClick={handleFirstPageClick} disabled={previousDisabled}>
                    1
                </PaginationButton>,
            );
        }
        if (page >= 3) {
            paginationItems.push(
                <span key="dots1" style={{ fontSize: '1.6rem', lineHeight: '2.4rem' }}>
                    ...
                </span>,
            );
        }
        paginationItems.push(
            <PaginationButton key={page} type="button" onClick={() => handlePageClick(page)} className="active">
                {page}
            </PaginationButton>,
        );
        if (page < lastPage - 1) {
            paginationItems.push(
                <span key="dots2" style={{ fontSize: '1.6rem', lineHeight: '2.4rem' }}>
                    ...
                </span>,
            );
        }
        if (page < lastPage) {
            paginationItems.push(
                <PaginationButton
                    key="last"
                    type="button"
                    onClick={() => handlePageClick(lastPage)}
                    disabled={nextDisabled}
                >
                    {lastPage}
                </PaginationButton>,
            );
        }
        return (
            <>
                <PaginationButton key="prev" type="button" onClick={handlePrevPageClick} disabled={previousDisabled}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M17.6 6L19 7.4L14.425 12L19 16.6L17.6 18L11.6 12L17.6 6ZM11 6L12.4 7.4L7.825 12L12.4 16.6L11 18L5 12L11 6Z"
                            fill="#666B6E"
                        />
                    </svg>
                </PaginationButton>
                {paginationItems}
                <PaginationButton key="next" type="button" onClick={handleNextPageClick} disabled={nextDisabled}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M6.4 18L5 16.6L9.575 12L5 7.4L6.4 6L12.4 12L6.4 18ZM13 18L11.6 16.6L16.175 12L11.6 7.4L13 6L19 12L13 18Z"
                            fill="#666B6E"
                        />
                    </svg>
                </PaginationButton>
            </>
        );
    }, [page, lastPage, handleFirstPageClick, handlePageClick, handlePrevPageClick, handleNextPageClick]);
    return { renderPagination, pageValue: page, resetPagination, setLastPage };
};

export const Form = styled.form`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;
export const SelectCategoryItem = styled.label`
    cursor: pointer;
    position: relative;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem;
    margin-right: 0.8rem;
    border: 0.1rem solid ${PrimaryColor};
    transition: 0.6s ease;
    white-space: nowrap;
    @media (max-width: 480px) {
        margin-bottom: 0.4rem;
    }
    &:hover {
        transition: 0.6s ease;
        background-color: ${HoverColor};
        color: ${Background};
        border: 0.1rem solid ${HoverColor};
        @media (max-width: 768px) {
            transition: 0.6s ease;
            background-color: ${PrimaryColor};
            color: ${Background};
            border: 0.1rem solid ${PrimaryColor};
        }
    }
    ${(props) =>
        props.active &&
        css`
            transition: 0.6s ease;
            background-color: ${PrimaryColor};
            color: ${Background};
            :hover {
                background-color: ${PrimaryColor};
                color: ${Background};
                cursor: default;
            }
        `}
`;

export const SelectCategoryItemInput = styled.input`
    position: absolute;
    width: 0.1rem;
    height: 0.1rem;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    &:checked + ${SelectCategoryItem} {
        cursor: default;
        background-color: ${PrimaryColor};
        color: ${Background};
    }
`;

export const Select = styled.select`
    border: 0.1rem solid #;
    margin-right: 1rem;
    font-size: 1.6rem;
    color: ${LightBlack};
    padding: 0 0.5rem;
    &:focus {
        outline: none;
    }

    @media screen and (max-width: ${screenSizeSmall}) {
        width: 60%;
    }
`;
export const Option = styled.option`
    border: none;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${LightBlack};
    &:focus {
        outline: none;
    }

    @media screen and (max-width: ${screenSizeSmall}) {
        width: 60%;
    }
`;

export const SortButton = styled.button`
    background-color: ${White};
    border: none;
    color: ${LightBlack};
    margin-left: 2rem;
    padding: 0 1.5rem;
    &:focus {
        outline: none;
    }
    &:active {
        background-color: ${LightGrey2};
    }
`;

export const DeleteButton = styled.button`
    background-color: ${White};
    border: none;
    color: ${LightBlack};
    padding: 0 1.5rem;
    &:focus {
        outline: none;
    }
    &:active {
        background-color: ${LightGrey2};
    }
`;

export const PaginationButton = styled.button`
    cursor: pointer;
    min-width: 4rem;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Background};
    border: 1px solid #d9dadb;
    border-radius: 0.8rem;
    color: #666b6e;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0 0.8rem;
    outline: 0;
    transition: 0.6s ease;
    svg {
        path {
            transition: 0.6s ease;
        }
    }

    &:focus {
        transition: 0.6s ease;
        background-color: ${Background};
        color: #666b6e;
    }
    &:active {
        transition: 0.6s ease;
        background-color: ${Background};
        color: #666b6e;
    }
    &.active {
        transition: 0.6s ease;
        background-color: ${PrimaryColor};
        color: ${Background};
    }
    :hover {
        transition: 0.6s ease;
        background-color: ${HoverColor};
        color: ${Background};
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
            transition: 0.6s ease;
        }
        svg {
            path {
                transition: 0.6s ease;
                fill: ${Background};
            }
        }
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
            color: ${Background};
        }
    }
    &:active {
        transition: 0.6s ease;
        background-color: ${Background};
        color: #666b6e;
    }
    &:disabled {
        cursor: default;
        opacity: 0.3;
        background-color: ${Background};
        color: #666b6e;
        :hover {
            cursor: default;
            background-color: ${Background};
            color: #666b6e;
            svg {
                cursor: default;
                path {
                    fill: #666b6e;
                }
            }
        }
    }
`;
