/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-debugger */
import React from 'react';
import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
import { Container } from 'styles/commons';
import { content } from 'commons/content';
import ImgWeb from '../../assets/images/RiskRakaGrudiPage/RiskRakaGrudiWeb.png';
import ImgTab from '../../assets/images/RiskRakaGrudiPage/RiskRakaGrudiTab.png';
import ImgMob from '../../assets/images/RiskRakaGrudiPage/RiskRakaGrudiMob.png';
import { FormulaDescriptionBlock, FormulaDescription } from './styles2';
import DynamicTitle from '../../components/molecules/DynamicTitle/DynamicTitle';
import { BreadCrumbs } from '../../components/common/BreadCrumbs';
import { GlobalContainer } from '../../styles/commons';
import { HeaderSlide } from '../../components/common/HeaderSlide';
import { ContactsSection, RiskRakaGrudiInfoSection } from '../../components/common/Section';
import {
    IndinolImage,
    RiskRakaGrudiInfoBlock,
    RiskRakaInfoTitle,
} from '../../components/common/Section/RiskRakaGrudiInfoSection/RiskRakaGrudiInfoSection.styles';
import IndinolBanner from '../../assets/images/RiskRakaGrudiPage/IndinolBanner.png';
import formulaSchema from '../../assets/images/RiskRakaGrudiPage/FormulaRiska.png';
import { CompanyValuesCardsCardContentTitle } from '../../components/common/Section/CompanyValuesSection/CompanyValuesSection.styles';

export const CalculatorRakaGrudiPage = () => {
    const validate = () => {
        document.querySelector('#calculator').scrollIntoView({
            behavior: 'smooth',
        });
    };
    return (
        <>
            <DynamicTitle title="Калькулятор для расчета риска рака груди" />
            <BreadCrumbs colorDefault="#FFFFFF80" colorActive="#fff" />
            <GlobalContainer>
                <HeaderSlide
                    data={{
                        title: content.HEADER_SLIDE_RISKRAKAGRUDI_TITLE,
                        subtitle: content.HEADER_SLIDE_RISKRAKAGRUDI_SUBTITLE,
                        bgColor: 'linear-gradient(74.77deg, #FF7100 9.99%, #F3CA37 89.98%)',
                    }}
                    button={{
                        type: 'button',
                        onClick: () => {
                            validate();
                        },
                        text: content.HEADER_SLIDE_RISKRAKAGRUDI_BUTTON,
                        styles: { bgColor: 'transparent', border: '0.2rem solid #fff' },
                    }}
                    images={{ web: ImgWeb, tab: ImgTab, mob: ImgMob }}
                />
                <RiskRakaGrudiInfoSection
                    onClick={() => {
                        validate();
                    }}
                />
                <RiskRakaGrudiInfoBlock>
                    <GlobalContainer>
                        <Container>
                            <IndinolImage src={IndinolBanner} alt="indinol-banner" />
                        </Container>
                    </GlobalContainer>
                </RiskRakaGrudiInfoBlock>
                <RiskRakaGrudiInfoBlock>
                    <GlobalContainer>
                        <Container>
                            <RiskRakaInfoTitle justifyContent="flex-start" textAlign="start">
                                Используемые уравнения
                            </RiskRakaInfoTitle>
                            <IndinolImage src={formulaSchema} alt="formula-schema" />
                        </Container>
                    </GlobalContainer>
                </RiskRakaGrudiInfoBlock>
                <RiskRakaGrudiInfoBlock>
                    <GlobalContainer>
                        <Container>
                            <CompanyValuesCardsCardContentTitle>Источники</CompanyValuesCardsCardContentTitle>
                            <FormulaDescriptionBlock>
                                <FormulaDescription>
                                    * Модель Гейла для стратификации риска развития рака молочной железы в ближайшие 5
                                    лет.
                                </FormulaDescription>
                                <FormulaDescription>
                                    1. Gail MH, Constantino JP, Bryant J, et al. Weighing the Risks and Benefits of
                                    Tamoxifen Treatment for Preventing Breast Cancer. J. National Cancer Institute.
                                    1999; V91, #21, pages 1829-1846.
                                </FormulaDescription>
                                <FormulaDescription>
                                    2. Costantino JP, Gail MH, Pee D, et al. Validation studies for models projecting
                                    the risk of invasive and total breast cancer incidence. J Natl Cancer Inst. 1999 Sep
                                    15;91(18):1541-8.
                                </FormulaDescription>
                            </FormulaDescriptionBlock>
                        </Container>
                    </GlobalContainer>
                </RiskRakaGrudiInfoBlock>
            </GlobalContainer>
            <GlobalContainer>
                <ContactsSection backgroundColor="#f0f0f0" />
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
};

export default CalculatorRakaGrudiPage;
