import styled from 'styled-components';
import { Orange } from 'styles/colors';

export const EmployeeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 31.5rem;
`;

export const EmployeePhoto = styled.div`
    width: 31.5rem;
    height: 31.2rem;
    @media (max-width: 768px) {
        width: 27rem;
        height: 27rem;
    }
`;
export const EmployeeName = styled.h3`
    padding-top: 2rem;
    padding-bottom: 0.8rem;
    text-transform: capitalize;
`;
export const TeamSectionContainer = styled.div`
    display: flex;
    align-items: center;
    justifycontent: space-between;
    flex-wrap: wrap;
    gap: 3.2rem 2rem;
    div {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const EmployeePost = styled.p`
    text-align: center;
    color: ${Orange};
    text-transform: uppercase;
`;
