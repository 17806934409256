import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { content } from '../../../commons/content';
import {
    PopupPartnersForm,
    PopupPartnersFormBackground,
    PopupPartnersFormConfirmationText,
    PopupPartnersFormContainer,
    PopupPartnersFormContent,
    PopupPartnersFormInput,
    PopupPartnersFormSubmit,
    PopupPartnersFormSubtitle,
    PopupPartnersFormTitle,
} from './PopupPartnersPageForm.styles';
import PopupPartnersFormCloseSvg from './PopupPartnersFormCloseSvg/PopupPartnersFormCloseSvg';

export default function PopupPartnersPageForm({ handlePopupClose }) {
    const containerRef = useRef(null);
    const [formData, setFormData] = useState({
        fio: '',
        country: '',
        organization: '',
        job: '',
        email: '',
        phone: '',
    });
    const [formErrors, setFormErrors] = useState({});

    const handleMouseEnter = () => {
        if (containerRef.current) {
            document.body.classList.add('no-scroll-y');
        }
    };

    const handleMouseLeave = () => {
        if (containerRef.current) {
            document.body.classList.remove('no-scroll-y');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [name]: '',
        }));
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        if (formData.fio.trim() === '') {
            errors.fio = 'Обязательное поле.';
        }
        if (formData.country.trim() === '') {
            errors.country = 'Обязательное поле.';
        }
        if (formData.organization.trim() === '') {
            errors.organization = 'Обязательное поле.';
        }
        if (formData.job.trim() === '') {
            errors.job = 'Обязательное поле.';
        }
        if (formData.email.trim() === '') {
            errors.email = 'Обязательное поле.';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'E-mail должен содержать символ @.';
        }
        if (formData.phone.trim() === '') {
            errors.phone = 'Обязательное поле.';
        }
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/mail/partner`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    contactPost: formData.job,
                    fullName: formData.fio,
                    phoneNumber: formData.phone,
                    email: formData.email,
                    location: formData.country,
                    organizationName: formData.organization,
                }),
            });
            const result = await request.json();
            if (request.ok && result.data.sended) {
                window.location.href = '/thanks-page';
            }
        }
    };
    return (
        <PopupPartnersFormContainer>
            <PopupPartnersFormBackground
                onClick={handlePopupClose}
                ref={containerRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <PopupPartnersFormContent
                ref={containerRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <PopupPartnersFormCloseSvg handlePopupClose={handlePopupClose} />
                <PopupPartnersFormTitle>{content.POPUP_PARTNERS_CONTENT_TITLE}</PopupPartnersFormTitle>
                <PopupPartnersFormSubtitle>{content.POPUP_PARTNERS_CONTENT_SUBTITLE}</PopupPartnersFormSubtitle>
                <PopupPartnersForm onSubmit={handleSubmit} noValidate>
                    <div>
                        <PopupPartnersFormInput
                            type="text"
                            placeholder={content.POPUP_PARTNERS_CONTENT_INPUT_FIO}
                            name="fio"
                            value={formData.fio}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.fio && <ErrorMessage>{formErrors.fio}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupPartnersFormInput
                            type="text"
                            placeholder={content.POPUP_PARTNERS_CONTENT_INPUT_COUNTRY}
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.country && <ErrorMessage>{formErrors.country}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupPartnersFormInput
                            type="text"
                            placeholder={content.POPUP_PARTNERS_CONTENT_INPUT_ORGANIZATION}
                            name="organization"
                            value={formData.organization}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.organization && <ErrorMessage>{formErrors.organization}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupPartnersFormInput
                            type="text"
                            placeholder={content.POPUP_PARTNERS_CONTENT_INPUT_JOB}
                            name="job"
                            value={formData.job}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.job && <ErrorMessage>{formErrors.job}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupPartnersFormInput
                            type="email"
                            placeholder={content.POPUP_PARTNERS_CONTENT_INPUT_EMAIL}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.email && <ErrorMessage>{formErrors.email}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupPartnersFormInput
                            type="tel"
                            placeholder={content.POPUP_PARTNERS_CONTENT_INPUT_PHONE}
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        {formErrors.phone && <ErrorMessage>{formErrors.phone}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupPartnersFormSubmit type="submit">
                            {content.POPUP_PARTNERS_CONTENT_INPUT_SUBMIT}
                        </PopupPartnersFormSubmit>
                    </div>
                </PopupPartnersForm>
                <PopupPartnersFormConfirmationText
                    dangerouslySetInnerHTML={{ __html: content.POPUP_PARTNERS_CONTENT_CONFIRMATION_TEXT }}
                />
            </PopupPartnersFormContent>
        </PopupPartnersFormContainer>
    );
}

PopupPartnersPageForm.propTypes = {
    handlePopupClose: PropTypes.func.isRequired,
};

function ErrorMessage({ children }) {
    return <div style={{ color: 'red', fontSize: '1.2rem', paddingTop: '0.5rem' }}>{children}</div>;
}

ErrorMessage.propTypes = {
    children: PropTypes.node.isRequired,
};
