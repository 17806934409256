/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { PropTypes } from 'prop-types';
import { EmployeeContainer, EmployeeName, EmployeePhoto, EmployeePost } from '../TeamSection.styles';
import ImageAlt from '../../../../HOC/ImageAlt/ImageAlt';
import { TeamSliderContainer, TeamSliderContainerInner } from './TeamSlider.styles';
import './TeamSlider.styles.css';

const TeamSlider = ({ staff }) => {
    return (
        <TeamSliderContainer>
            <TeamSliderContainerInner>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={2}
                    spaceBetween={20}
                    navigation={{
                        nextEl: '.team-slider-button-next',
                        prevEl: '.team-slider-button-prev',
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        481: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {staff.map((item) => (
                        <SwiperSlide key={item.id}>
                            <EmployeeContainer>
                                <EmployeePhoto>
                                    <ImageAlt src={item.photo} alt={item.photo} />
                                </EmployeePhoto>
                                <EmployeeName>{item.name}</EmployeeName>
                                <EmployeePost>{item.post}</EmployeePost>
                            </EmployeeContainer>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </TeamSliderContainerInner>
            <div className="team-slider-button-prev">
                <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                    <path d="M15.975 2L17.75 3.775L9.525 12L17.75 20.225L15.975 22L5.975 12L15.975 2Z" fill="#FF7100" />
                </svg>
            </div>
            <div className="team-slider-button-next">
                <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                    <path d="M8.025 22L6.25 20.225L14.475 12L6.25 3.775L8.025 2L18.025 12L8.025 22Z" fill="#FF7100" />
                </svg>
            </div>
        </TeamSliderContainer>
    );
};

TeamSlider.propTypes = {
    staff: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default TeamSlider;
