import React from 'react';
import PropTypes from 'prop-types';
import { GlobalSearchButtonInner } from './styles';

export default function GlobalSearchButton({ index, activeIndex, onClick, title, totalCount }) {
    const handleClick = () => {
        onClick(index);
    };

    return (
        <GlobalSearchButtonInner type="button" className={activeIndex === index ? 'active' : ''} onClick={handleClick}>
            {`${title} (${totalCount})`}
        </GlobalSearchButtonInner>
    );
}

GlobalSearchButton.propTypes = {
    index: PropTypes.number.isRequired,
    activeIndex: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    totalCount: PropTypes.number.isRequired,
};
