import React from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer } from './styles';

const ButtonPink = ({ title, link }) => {
    return <ButtonContainer href={link}>{title}</ButtonContainer>;
};

ButtonPink.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default ButtonPink;
