import React, { useState } from 'react';
import BreadCrumbs from '../../../components/common/BreadCrumbs/BreadCrumbs';
import { GlobalContainer } from '../../../styles/commons';
import { content } from '../../../commons/content';
import { HeaderSlide } from '../../../components/common/HeaderSlide';
import ImgWeb from '../../../assets/images/OurCareerPage/OurCareerWeb.png';
import ImgTab from '../../../assets/images/OurCareerPage/OurCareerTab.png';
import ImgMob from '../../../assets/images/OurCareerPage/OurCareerMob.png';
import WeOffer from './WeOffer/WeOffer';
import { SingleSlide } from '../../../components/common/SingleSlide/SingleSlide';
import ImgLeft from '../../../assets/images/OurCareerPage/NoVacancies.png';
import ImgLeftActualVacancies from '../../../assets/images/OurCareerPage/AboutCompany.png';
import { currentVacancies } from '../../../commons/currentVacancies';
import CurrentVacancies from './CurrentVacancies/CurrentVacancies';
import { isMobile, isTablet } from '../../../commons/constants';
import PopupCareerPageForm from '../../../components/common/PopupCareerPageForm/PopupCareerPageForm';
import GoogleAnalitycs from '../../../components/atoms/GoogleAnalitycs';
import { ContactsSection } from '../../../components/common/Section';

export default function CareerPage() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        document.body.classList.add('no-scroll-y');
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
        document.body.classList.remove('no-scroll-y');
    };

    return (
        <>
            <BreadCrumbs colorDefault="#FFFFFF80" colorActive="#fff" />
            <GlobalContainer>
                <HeaderSlide
                    data={{
                        title: content.HEADER_SLIDE_CAREER_TITLE,
                        subtitle: content.HEADER_SLIDE_CAREER_SUBTITLE,
                        bgColor: 'linear-gradient(74.77deg, #FF7100 9.99%, #F3CA37 89.98%)',
                    }}
                    button={{
                        type: 'button',
                        onClick: () => handlePopupOpen(),
                        text: content.HEADER_SLIDE_CAREER_BUTTON,
                        styles: { bgColor: 'transparent', border: '0.2rem solid #fff' },
                    }}
                    images={{ web: ImgWeb, tab: ImgTab, mob: ImgMob }}
                />
                <WeOffer />
                <SingleSlide
                    imgLeft={ImgLeftActualVacancies}
                    title={content.CAREER_PAGE_ABOUT_SECTION_TITLE}
                    text={content.CAREER_PAGE_ABOUT_SECTION_TEXT}
                    // buttonText={content.CAREER_PAGE_ABOUT_SECTION_BUTTON}
                    backgroundColor="#F0F0F0"
                    flexDirection={(isTablet && 'row') || (isMobile && 'row') || 'row-reverse'}
                    // buttonLink="/our-values"
                    // dadasdasdasdasd
                />
                {currentVacancies.length > 0 ? (
                    <CurrentVacancies currentVacancies={currentVacancies} />
                ) : (
                    <SingleSlide
                        imgLeft={ImgLeft}
                        title={content.CAREER_PAGE_VALANCIES_SECTION_TITLE}
                        text={content.CAREER_PAGE_VALANCIES_SECTION_TEXT}
                        buttonText={content.CAREER_PAGE_VALANCIES_SECTION_BUTTON}
                        backgroundColor="#fff"
                        handlePopupOpen={handlePopupOpen}
                    />
                )}
                {isPopupOpen && <PopupCareerPageForm handlePopupClose={handlePopupClose} />}
                <ContactsSection backgroundColor="#F0F0F0" />
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
}
