/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const RemoveSlash = ({ history, location: { pathname } }) => {
    useEffect(() => {
        const newPathname = pathname.replace(/\/+$/, '');
        if (newPathname !== pathname) {
            history.push(newPathname);
        } else if (pathname === '/index.html') {
            const newPathNameIndex = '/';
            history.push(newPathNameIndex);
        }
    }, [history, pathname]);

    return null;
};

export default withRouter(RemoveSlash);
