import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ImageAlt } from '../../../HOC/ImageAlt';
import { HoverColor, PrimaryColor } from '../../../../styles/colors';

export const PromohubSimilarItemsTitle = styled.h3`
    padding-bottom: 3.2rem;
    text-align: left;
    @media (max-width: 768px) {
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 2rem;
    }
`;

export const PromohubSimilarItemsContainer = styled.div`
    width: 42.7rem;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const PromohubSimilarItem = styled(Link)`
    width: 100%;
    max-height: 13.8rem;
    display: flex;
    padding: 1.6rem 0;
    border-width: 0.1rem 0 0.1rem 0;
    border-style: solid;
    border-color: #d9dadb;
    :hover {
        h4 {
            transition: 0.6s ease;
            color: ${HoverColor};
            @media (max-width: 768px) {
                color: ${PrimaryColor};
            }
        }
    }
`;
export const PromohubSimilarItemImg = styled.div`
    flex-basis: 50%;
    max-width: 20.4rem;
`;

export const PromohubSimilarItemTitle = styled.h4`
    font-weight: 550;
    transition: 0.6s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
`;
export const PromohubSimilarItemText = styled.p`
    display: flex;
    flex-basis: 15%;
    align-items: center;
    color: #666b6e;
    font-size: 1.6rem;
    line-height: 2.1rem;
`;
export const PromohubSimilarItemContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-basis: 50%;
    padding-left: 2rem;
`;

export const Img = styled(ImageAlt)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
