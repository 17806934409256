import styled from 'styled-components';
import { White, Pink, PinkLight } from 'styles/colors';
import { FlexItem } from 'styles/commons';
import { screenSizeSmall } from 'styles/screenSize';
import ulProtected from 'assets/images/ul-protected.png';

export const FlexBlock = styled(FlexItem)`
    width: 100%;
    @media screen and (max-width: ${screenSizeSmall}) {
        width: 100%;
        height: auto;
    }
`;

export const BannerBackground = styled.div`
    background-color: ${Pink};
    background-position: center 0rem;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    margin-top: 12rem;
    width: 100%;
    height: ${({ height }) => height};
`;

export const Title = styled.h2`
    font-size: 4.3rem;
    color: ${White};
    margin-bottom: 0.8rem;
    @media screen and (max-width: ${screenSizeSmall}) {
        font-size: 3rem;
    }
`;

export const ButonContainer = styled.div`
    width: 70%;
`;

export const BannerTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 50%;
    @media screen and (max-width: ${screenSizeSmall}) {
        max-width: 100%;
        justify-content: center;
    }
`;

export const BannerImage = styled.div`
    max-width: 50%;
    display: flex;
    align-items: end;
    @media screen and (max-width: ${screenSizeSmall}) {
        max-width: 100%;
    }
`;

export const BannerSmallText = styled.p`
    color: ${White};
    font-size: 2.1rem;
    margin-bottom: 2rem;
    text-align: justify;
    @media screen and (max-width: ${screenSizeSmall}) {
        font-size: 1.8rem;
    }
`;

export const Headline = styled.div`
    background-color: ${PinkLight};
    color: ${Pink};
    font-size: 4rem;
    padding: 2rem 0;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 4.8rem;

    text-align: center;
`;

export const TextBlock = styled.div`
    margin: 4rem 0;
    width: 100%;

    @media screen and (max-width: ${screenSizeSmall}) {
        padding: 2rem;
    }
`;

export const PinkText = styled.p`
    color: ${Pink};
    font-size: 2rem;
    font-weight: 600;
`;

export const TextColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Ul = styled.ul`
    list-style: none;
    padding-left: 0;
    margin-bottom: 3rem;
`;

export const Li = styled.li`
    position: relative;
    padding-left: 2rem;
    line-height: 1.6;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;

    &::before {
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        background-image: url(${ulProtected});
        background-size: cover;
        background-position: center;
        left: 0;
        top: 0;
        transform: translateY(25%);
    }
`;

export const Text = styled.p`
    margin-bottom: 2rem;
`;

export const FactorsIconsContainer = styled(FlexItem)`
    padding: 2rem 0rem 4rem;
    justify-content: space-around;
    @media screen and (max-width: ${screenSizeSmall}) {
        padding: 0;
    }
`;

export const FactorsIconsContainerSecond = styled(FlexItem)`
    padding: 2rem 0rem 4rem;
    justify-content: space-evenly;
    @media screen and (max-width: ${screenSizeSmall}) {
        padding: 0;
    }
`;

export const IconBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: ${screenSizeSmall}) {
        margin-bottom: 2rem;
    }
`;

export const PinkTextSmall = styled.p`
    color: ${Pink};
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
`;

export const IconSmallText = styled.p`
    font-size: 1.4rem;
    font-weight: 300;
    text-align: center;
`;

export const Icon = styled.img`
    margin-top: 1rem;
`;

export const NextActionsBlock = styled.div`
    background-color: ${Pink};
    padding-top: 2rem;
`;

export const LeftBlock = styled.div`
    width: 30%;
    display: flex;
    align-items: end;
    @media screen and (max-width: ${screenSizeSmall}) {
        width: 100%;
    }
`;

export const RightBlock = styled.div`
    width: 70%;
    @media screen and (max-width: ${screenSizeSmall}) {
        width: 100%;
    }
`;

export const PinkTextLarge = styled.p`
    color: ${Pink};
    font-size: 2.2rem;
    text-align: center;
`;

export const TestContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
