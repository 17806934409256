import styled from 'styled-components';
import { screenSizeSmall } from 'styles/screenSize';
import { HoverColor, PrimaryColor } from '../../../styles/colors';

// import { screenSizeSmall } from 'styles/screenSize';

export const NewsPageContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ProductItemPageContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: ${screenSizeSmall}) {
        flex-direction: column;
    }
`;

export const ProductItemPageSliderContainer = styled.div`
    width: 42.7rem;
    position: relative;
    margin-right: 2rem;
    @media (max-width: 768px) {
        margin-right: 0;
        padding-bottom: 4.4rem;
    }
    .image-gallery-slide-wrapper.bottom {
        width: 42.7rem;
        height: 42.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 2rem;
        @media (max-width: 480px) {
            width: 28.8rem;
            height: 28.8rem;
        }
    }
    .image-gallery-thumbnails {
        padding: 0;
    }
    .image-gallery-thumbnails-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0 2rem;
        @media (max-width: 768px) {
            .image-gallery-thumbnail {
                margin-right: 2rem;
                @media (max-width: 480px) {
                    margin-right: 1.6rem;
                }
            }
            justify-content: flex-start;
        }
    }
    .image-gallery-thumbnail {
        width: 13rem;
        height: 13rem;
        @media (max-width: 768px) {
            width: 8.5rem;
            height: 8.5rem;
        }
    }
    .image-gallery-thumbnail.active {
        border: 0.1rem solid ${PrimaryColor};
    }
    .image-gallery-thumbnail:hover {
        border: 0.1rem solid ${HoverColor};
        @media (max-width: 768px) {
            border: 0.1rem solid ${PrimaryColor};
        }
    }
    .image-gallery-thumbnail:focus {
        border: 0.1rem solid ${PrimaryColor};
    }
    @media screen and (max-width: ${screenSizeSmall}) {
        width: 100%;
        margin-right: 0rem;

        .image-gallery-slides {
            width: 100%;
        }
    }
`;

export const ProductContent = styled.div`
    width: 87.3rem;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const PriceBlock = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;
    a {
        margin-right: 2rem;
        @media (max-width: 480px) {
            margin-right: 0;
            margin-top: 2rem;
        }
    }
    @media (max-width: 480px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

export const ProductItemPageTitle = styled.h2`
    text-transform: lowercase;
    padding-bottom: 3.2rem;
    &::first-letter {
        text-transform: uppercase;
    }
    @media (max-width: 768px) {
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 2rem;
    }
`;

export const Img = styled.img``;

export const ProductItemPageText = styled.div`
    p {
        padding-bottom: 2rem;
        &:last-child {
            padding-bottom: 0;
        }
    }
`;

export const ButtonImg = styled.a`
    width: 18.8rem;
    height: 6.4rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const PriceText = styled.h3`
    margin-right: 2rem;
`;
