import styled from 'styled-components';

export const WeOfferContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    div {
        padding-bottom: 3rem;
        @media (max-width: 768px) {
            padding-bottom: 2.8rem;
        }
        @media (max-width: 480px) {
            padding-bottom: 2rem;
        }
        &.sc-AxgMl.OLhwu {
            padding-bottom: 0;
        }
        @media (max-width: 768px) {
            flex-basis: 50%;
            &:nth-child(3) {
                border-width: 0.1rem 0 0 0;
                border-style: solid;
                border-color: #d9dadb;
            }
        }
        @media (max-width: 480px) {
            flex-basis: 100%;
        }
        &:nth-child(1) {
            @media (max-width: 480px) {
                padding-top: 0;
                padding-bottom: 2rem;
            }
        }
        &:nth-child(2) {
            border-width: 0 0.1rem 0 0.1rem;
            border-style: solid;
            border-color: #d9dadb;
            @media (max-width: 768px) {
                border-width: 0 0 0 0.1rem;
            }
            @media (max-width: 480px) {
                border-width: 0.1rem 0 0.1rem 0;
            }
        }
        &:nth-child(4) {
            border-width: 0.1rem 0 0 0;
            border-style: solid;
            border-color: #d9dadb;
            padding-bottom: 0;
            @media (max-width: 768px) {
                padding-bottom: 2.8rem;
                border-width: 0.1rem 0 0 0.1rem;
            }
            @media (max-width: 480px) {
                padding-bottom: 2rem;
                border-width: 0.1rem 0 0.1rem 0;
            }
        }
        &:nth-child(5) {
            border-width: 0.1rem 0.1rem 0 0.1rem;
            border-style: solid;
            border-color: #d9dadb;
            padding-bottom: 0;
            @media (max-width: 768px) {
                border-width: 0.1rem 0.1rem 0 0;
            }
            @media (max-width: 480px) {
                border-width: 0 0 0.1rem 0;
                padding-bottom: 2rem;
            }
        }
        &:nth-child(6) {
            border-width: 0.1rem 0 0 0;
            border-style: solid;
            border-color: #d9dadb;
            padding-bottom: 0;
            @media (max-width: 480px) {
                border-width: 0;
            }
        }
    }
`;

export const WeOfferContainerItem = styled.div`
    padding: 4.7rem;
    padding-bottom: 0;
    @media (max-width: 768px) {
        padding: 2.8rem;
    }
    @media (max-width: 480px) {
        padding: 2rem 2.8rem;
    }
    div {
        img {
            min-width: 6rem;
            min-height: 6rem;
        }
    }
`;

export const WeOfferTitle = styled.h2`
    padding-bottom: 3.2rem;
    @media (max-width: 768px) {
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 2rem;
    }
`;
export const WeOfferText = styled.h3`
    width: 34.4rem;
    text-align: center;
    max-width: 34.4rem;
    padding-top: 4.8rem;
    @media (max-width: 768px) {
        padding-top: 2.8rem;
        width: 100%;
        max-width: 100%;
    }
    @media (max-width: 480px) {
        max-width: 100%;
        padding-top: 0.8rem;
    }
`;
