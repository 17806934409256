export const ourHistory = [
    {
        year: '1836',
        paragraph: ['Основание компании под названием «Schweizerhall " недалеко от города Базель'],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '1994',
        paragraph: ['Schweizerhall становится публичной, выйдя на швейцарскую фондовую биржу'],
    },

    {
        year: '2004',
        paragraph: ['Приобретение компании Cimex Pharma в швейцарском Лисберге'],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '2006',
        paragraph: ['Приобретение Novosis в Мисбахе, Германия'],
    },
    {
        year: '2008',
        paragraph: ['Изменение названия группы компаний и дочерних компаний на “Acino”'],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '2011',
        paragraph: [
            'Приобретение объединенного подразделения компании Cephalon в регионе Ближний Восток и Африка.',
            'Acino завершила сделку по приобретению производственных мощностей, R & D-подразделения и бизнеса компании Mepha на Ближнем Востоке, в Африке, Латинской Америке и Азии.',
            'Коммерциализация продукции под брендом «Acino Switzerland.',
        ],
    },
    {
        year: '2013',
        paragraph: ['Поглощение частными инвестиционными фондами Avista Capital и Nordic Capital.'],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '2015',
        paragraph: ['Приобретение фармацевтического завода»Фарма Старт".'],
    },
    {
        year: '2016',
        paragraph: [
            'Приобретение бизнеса и права на продукты компании Norgine B.V. На Ближнем Востоке и в Северной Африке (MENA).',
            'Приобретение производства компании Takeda в Эстонии.',
            'Подписание лицензионного соглашения на комбинированный болеутоляющий Maxigesic с компанией AFT.',
            'Продажа бизнеса Novosis.',
            'Штаб-квартира переехала в Цюрих.',
        ],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '2017',
        paragraph: [
            'Приобретение компании Litha Healthcare в Южной Африке.',
            'Подписание дистрибуционного соглашения с Merck в странах СНГ.',
            'Открытие офиса представительства Acino в Республике Беларусь.',
            'Подписание соглашения о дистрибьюции с компанией Merck KGaA.',
            'Приобретение прав на бренды компании Valeant – 2018.',
        ],
    },
    {
        year: '2018',
        paragraph: ['Подписание контрактов на поставку в РБ продукции «Фарма Старт».'],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '2019',
        paragraph: ['Acino открывает новые офисы в Турции и Египте.'],
    },
    {
        year: '2020',
        paragraph: [
            'Приобретение части терапевтического портфеля компании Takeda в 14 странах мира, включая Украину и Молдову.',
            'Acino в Украине получила сертификат GMP EU.',
            'Acino в Южной Африке получила сертификат B-BBEE.',
            ' Ferrer.',
        ],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '',
        paragraph: [''],
    },
    {
        year: '2021',
        paragraph: [
            'Подписание соглашения о сотрудничестве Acino и Pharmax в соответствии со стратегией ОАЭ, с целью стать региональным центром фармацевтического производства.',
            'Merz Therapeutics и Acino подписали дистрибьюторское соглашение в Украине, СНГ и Монголии.',
            'ADQ, базирующаяся в Абу-Даби, одна из крупнейших холдинговых компаний региона с широким портфелем крупных предприятий в ключевых секторах экономики, подписывает соглашение о приобретении Acino.',
            'Подписание соглашения о дистрибьюции с компанией Merz.',
            'Приобретение прав на бренды компании «МираксБиоФарма».',
        ],
    },
    {
        year: '2022',
        paragraph: [
            'Поглощение инвестиционно-холдинговой компанией ADQ.',
            'Приобретение ряда брендов компании Aspen в Южной Африке.',
            'Acino получает статус уровня 1 B-BBEE в Южной Африке третий год подряд.',
        ],
        paragraphBold: 'История продолжается',
    },
    {
        year: '',
        paragraph: [''],
    },
];
