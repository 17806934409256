/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import Publication from '../../../Publication/Publication';
import PublicationFullSkeleton from '../../../Publication/PublicationFullSkeleton/PublicationFullSkeleton';
import { PromohubPageContentContainer } from './LatestPublications.styles';

export default function LatestPublicationsFetch() {
    const [loader, setLoader] = useState(true);
    const [publics, setPublics] = useState([]);

    useEffect(() => {
        const getAllPublics = async () => {
            const request = await fetch(
                // eslint-disable-next-line radix
                `${process.env.REACT_APP_BACKEND_URL}/promohub/latest`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                },
            );
            const response = await request.json();
            if (response !== undefined) {
                setPublics(response);
            }
        };

        getAllPublics();
        setLoader(false);
    }, []);

    let latestPublications;
    if (publics.length >= 2) {
        latestPublications = publics.slice(-2);
    } else {
        latestPublications = publics;
    }

    return (
        <PromohubPageContentContainer>
            {loader
                ? [...new Array(2)].map((_, i) => <PublicationFullSkeleton key={i} />)
                : latestPublications.map((publication) => (
                      <Publication
                          key={publication.id}
                          img={publication.image}
                          shortText={publication.shortText}
                          createdAt={publication.createdAt}
                          title={publication.title}
                          link={`/publics/${publication.id}`}
                          onlyTitle
                      />
                  ))}
        </PromohubPageContentContainer>
    );
}
