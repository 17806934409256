export const externalLink = {
    PANEL: 'https://forma-soglasiya.amm-pharmgroup.com/acino.php',
    PLAY_STORE:
        'https://play.google.com/store/apps/details?id=com.visdtech.twofourshift&_branch_match_id=752864179427535817',
};

export const emailLink = {
    OFFER_EMAIL_LINK: 'mailto:office_by@acino.swiss',
};

export const phoneLink = {
    PHONE_LINK: 'tel:+375173199142',
};
export const adressLink = {
    ADRESS_LINK: 'https://goo.gl/maps/6k6eX9vm9oh5stB67',
};

export const internalLink = {
    HOSPITALTY: '/hospitality',
    CAR_SERVICE: '/car-service',
    RETAIL: '/retail',
    MOVING: '/moving',
    BEAUTY: '/beauty',
    CONSTRUCTION: '/construction',
};

export const socilaTeamLink = {
    FB: 'https://www.facebook.com/AcinoBelarusCaucasusCluster/',
    VK: 'https://vk.com/acino_belarus',
    OK: 'https://ok.ru/group/54055490027715',
};

export const socialFooterLink = {
    FB: 'https://www.facebook.com/AcinoBelarusCaucasusCluster/',
    VK: 'https://vk.com/acino_pharma',
    INSTAGRAM: 'https://www.instagram.com/acino_belaruscaucasus_cluster/',
    LINKEDIN: 'https://www.linkedin.com/company/acino-swiss',
    YOUTUBE: 'https://www.youtube.com/channel/UCSlmqVJbQfvooveArQuPQTA',
    GOOGLEMAP:
        'https://www.google.com/maps/place/Acino+Pharma+%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B0%D0%B2%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE+%D0%B2+%D0%A0%D0%B5%D1%81%D0%BF%D1%83%D0%B1%D0%BB%D0%B8%D0%BA%D0%B8+%D0%91%D0%B5%D0%BB%D0%B0%D1%80%D1%83%D1%81%D1%8C/@53.9396096,27.479548,15z/data=!4m2!3m1!1s0x0:0x2d800f3cb9816531?ved=2ahUKEwj-3JK98bPgAhXXQRUIHeTuDRsQ_BIwDnoECAYQCA',
};

export const DrugsLink = {
    KONKOR_KOR: 'http://concor.acino.by/',
    KONKOR: 'http://concor.acino.by/',
    EUTIROKS: 'http://euthyrox.acino.by/',
    TIROZOL: 'https://www.rceth.by/NDfiles/instr/3205_98_03_08_09_14_17_19_i.pdf',
    IODOBALSNS: 'https://www.rceth.by/NDfiles/instr/1117_95_2000_04_05_10_16_i.pdf',
    GLUKOFAZ: 'http://glucophage.acino.by/',
    GLUKOVANS: 'https://www.rceth.by/NDfiles/instr/8182_07_11_12_15_16_19_i.pdf',
    TAJM_FACTIR: 'http://women-health.acinopharma-belarusoffice.com/narushenie-menstrualnogo-cykla.html',
    ESTROVEL: 'http://women-health.acinopharma-belarusoffice.com/menopausa-klimaks.html',
    FERRETAB: 'http://women-health.acinopharma-belarusoffice.com/pitanie-beremennoy-zenshiny.html',
    RIZOPTAN: 'http://acino.by/images/rizoptan.pdf',
    ARIPROZOL: 'http://acino.by/images/ariptazol.pdf',
    NEOGABIN: 'http://acino.by/images/neogabin.pdf',
    LAMOTRIN: 'http://acino.by/images/lamotrin.pdf',
    LEVOKOM: 'https://www.rceth.by/NDfiles/instr/6713_04_09_14_17_19_i.pdf',
    METILPREDNIZOLON: 'https://www.rceth.by/NDfiles/instr/6802_04_09_14_17_19_i.pdf',
};

export const isTablet = window.innerWidth <= 768;
export const isMobile = window.innerWidth <= 480;
