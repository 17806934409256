import styled from 'styled-components';

export const PromohubPageContentContainer = styled.div`
    width: 100%;
    padding-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6.4rem;
    @media (max-width: 768px) {
        padding-top: 2.4rem;
        margin-bottom: 4.4rem;
    }
    @media (max-width: 480px) {
        padding-top: 2rem;
    }
`;
