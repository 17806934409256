import React from 'react';

export default function ButtonPrev() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
            <path
                d="M15.975 2.5L17.75 4.275L9.525 12.5L17.75 20.725L15.975 22.5L5.975 12.5L15.975 2.5Z"
                fill="#FF7100"
            />
        </svg>
    );
}
