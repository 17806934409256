import styled from 'styled-components';
import { PrimaryColor } from '../../styles/colors';

export const ErrorPageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
export const ErrorPageContainerTitle = styled.h1`
    font-weight: 400;
    font-size: 12.8rem;
    line-height: 15.4rem;
    padding-bottom: 3.2rem;
    color: ${PrimaryColor};
    text-align: center;
    @media (max-width: 768px) {
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 2rem;
        font-size: 8rem;
        line-height: 9.6rem;
    }
`;
export const ErrorPageContainerSubtitle = styled.h2`
    font-weight: 700;
    font-size: 6rem;
    line-height: 7.2rem;
    padding-bottom: 0.8rem;
    color: #292c2f;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 4.4rem;
        line-height: 5.3rem;
    }
    @media (max-width: 480px) {
        font-size: 3.2rem;
        line-height: 3.8rem;
    }
`;
export const ErrorPageContainerText = styled.p`
    max-width: 85.6rem;
    font-size: 2rem;
    line-height: 2.6rem;
    padding-bottom: 6.4rem;
    color: #292c2f;
    text-align: center;
    @media (max-width: 768px) {
        max-width: 64.8rem;
        font-size: 1.6rem;
        line-height: 2.1rem;
        padding-bottom: 4.4rem;
    }
    @media (max-width: 480px) {
        max-width: 28.8rem;
    }
`;
