import { createGlobalStyle, css } from 'styled-components';

const fonts = css`
    @font-face {
        font-family: helveticaneuecyr;
        font-style: normal;
        font-weight: 400;
        src: local('HelveticaNeueCyr'), url('assets/fonts/HelveticaNeueCyr-Roman.woff') format('woff');
    }
    @font-face {
        font-family: helveticaneuecyr;
        font-style: normal;
        font-weight: 100;
        src: local('HelveticaNeueCyr'), url('assets/fonts/helveticaneuecyr-thin.woff') format('woff');
    }
    @font-face {
        font-family: helveticaneuecyr;
        font-style: normal;
        font-weight: 200;
        src: local('HelveticaNeueCyr'), url('assets/fonts/HelveticaNeueCyr-UltraLight.woff') format('woff');
    }
    @font-face {
        font-family: helveticaneuecyr;
        font-style: normal;
        font-weight: 300;
        src: local('HelveticaNeueCyr'), url('assets/fonts/HelveticaNeueCyr-Light.woff') format('woff');
    }
    @font-face {
        font-family: helveticaneuecyr;
        font-style: normal;
        font-weight: 550;
        src: local('HelveticaNeueCyr'), url('assets/fonts/helveticaneuecyr-medium.woff') format('woff');
    }
    @font-face {
        font-family: helveticaneuecyr;
        font-style: normal;
        font-weight: 700;
        src: local('HelveticaNeueCyr'), url('assets/fonts/helveticaneuecyr-bold.woff') format('woff');
    }
`;

const GlobalStyle = createGlobalStyle`
html, body {
    width: 100%;
    height: 100%;
    font-size: 62.5%;
}

@media(max-width:1600px){
    html, body {
    font-size: 62.5%;
    }
}
@media(max-width:1440px){
    html, body {
    font-size: 57.5%;
}
}
@media(max-width:1320px){
    html, body {
    font-size: 52.5%;
}
}
@media(max-width:1200px){
    html, body {
    font-size: 47.5%;
}
}
@media(max-width:1080px){
    html, body {
    font-size: 42.5%;
}
}
@media(max-width:960px){
    html, body {
    font-size: 37.5%;
}
}
@media(max-width:840px){
    html, body {
    font-size: 32.5%;
}
}
@media(max-width:768px){
    html, body {
        font-size: 62.5%;
    }
}
@media(max-width:767px){
    html, body {
        font-size: 57.5%;
    }
}
@media(max-width:680px){
    html, body {
        font-size: 52.5%;
    }
}
@media(max-width:600px){
    html, body {
        font-size: 47.5%;
    }
}
@media(max-width:520px){
    html, body {
        font-size: 42.5%;
    }
}
@media(max-width:480px){
    html, body {
        font-size: 87.5%;
    }
}
@media(max-width:448px){
    html, body {
        font-size: 82.5%;
    }
}
@media(max-width:416px){
    html, body {
        font-size: 77.5%;
    }
}
@media(max-width:384px){
    html, body {
        font-size: 72.5%;
    }
}
@media(max-width:352px){
    html, body {
        font-size: 67.5%;
    }
}
@media(max-width:320px){
    html, body {
        font-size: 62.5%;
    }
}
@media(max-width:304px){
    html, body {
        font-size: 57.5%;
    }
}
@media(max-width:288px){
    html, body {
        font-size: 52.5%;
    }
}
@media(max-width:272px){
    html, body {
        font-size: 47.5%;
    }
}
@media(max-width:256px){
    html, body {
        font-size: 42.5%;
    }
}
@media(max-width:240px){
    html, body {
        font-size: 37.5%;
    }
}
@media(max-width:208px){
    html, body {
        font-size: 32.5%;
    }
}
@media(max-width:176px){
    html, body {
        font-size: 27.5%;
    }
}
@media(max-width:144px){
    html, body {
        font-size: 22.5%;
    }
}
@media(max-width:112px){
    html, body {
        font-size: 17.5%;
    }
}
@media(max-width:80px){
    html, body {
        font-size: 12.5%;
    }
}
@media(max-width:48px){
    html, body {
        font-size: 7.5%;
    }
}
@media(max-width:16px){
    html, body {
        font-size: 2.5%;
    }
}
${fonts};
`;

export default GlobalStyle;
