import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const DynamicTitle = ({ title, description, keywords, robots }) => {
    return (
        <div>
            <Helmet>
                <title>
                    {`Acino | `}
                    {title}
                </title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="robots" content={robots} />
            </Helmet>
        </div>
    );
};

DynamicTitle.defaultProps = {
    title: 'Acino',
    description:
        'Официальная страница Представительства швейцарской фармацевтической компании Acino Фарма АО (Acino Pharma AG) в Минске',
    keywords: '',
    robots: 'index, follow',
};
DynamicTitle.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    robots: PropTypes.string,
};

export default DynamicTitle;
