import axios from 'axios';

// TODO rename file to http or client
const client = axios.create({
    baseURL: 'https://www.acino-media.by/',
    headers: {
        'Content-type': 'application/json',
    },
});

export const get = (url) => {
    return client.get(url);
};
