import React from 'react';
import DynamicTitle from '../../components/molecules/DynamicTitle/DynamicTitle';
import BreadCrumbs from '../../components/common/BreadCrumbs/BreadCrumbs';
import { HeaderSlide } from '../../components/common/HeaderSlide';
import { GlobalContainer } from '../../styles/commons';
import { content } from '../../commons/content';
import ImgWeb from '../../assets/images/PartnersPage/PartnersHeaderWeb.png';
import ImgTab from '../../assets/images/PartnersPage/PartnersHeaderTab.png';
import ImgMob from '../../assets/images/PartnersPage/PartnersHeaderMob.png';
import { PartnersSection } from './PartnersSection';
import GoogleAnalitycs from '../../components/atoms/GoogleAnalitycs';
import {
    CompanyValuesSection,
    ContactsSection,
    MissionSection,
    OurPartnersSection,
    WeOfferSection,
} from '../../components/common/Section';

export default function PartnersPage() {
    return (
        <>
            <DynamicTitle title="Партнерам" />
            <BreadCrumbs colorDefault="#FFFFFF80" colorActive="#fff" />
            <GlobalContainer>
                <HeaderSlide
                    data={{
                        title: content.HEADER_SLIDE_PARTNER_TITLE,
                        subtitle: content.HEADER_SLIDE_PARTNER_SUBTITLE,
                    }}
                    images={{ web: ImgWeb, tab: ImgTab, mob: ImgMob }}
                />
                <CompanyValuesSection />
                <MissionSection />
                <WeOfferSection />
                <OurPartnersSection />
                <PartnersSection />
                <ContactsSection backgroundColor="#f0f0f0" />
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
}
