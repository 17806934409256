/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { content } from 'commons/content';
import Cookies from 'js-cookie';
import { externalLink } from 'commons/constants';
import { ModalContainer, Title, Button, Subtitle } from './BottomRightModal.styles';
import CloseIcon from './CloseIcon/CloseIcon';

export const BottomRightModal = ({ onClose }) => {
    const onDescribeHandle = () => {
        Cookies.set('subscription', 'yes', { expires: 30 });
        onClose();
        window.open(externalLink.PANEL, '_blank');
    };

    useEffect(() => {
        const hasAcceptedCookies = Cookies.get('subscription');
        if (hasAcceptedCookies) {
            onClose();
        }
    }, [onClose]);

    return (
        <ModalContainer>
            <CloseIcon onClose={onClose} />
            <Title>Подписка</Title>
            <Subtitle>Информационные ресурсы для специалистов здравоохранения</Subtitle>
            <Button type="button" onClick={onDescribeHandle}>
                {content.DESCRIPTION_TO}
            </Button>
        </ModalContainer>
    );
};

export default BottomRightModal;
