import { ru } from './ru';

const getContent = (locale) => {
    switch (locale) {
        case 'ru':
            return ru;
        default:
            return ru;
    }
};

export const content = getContent();
