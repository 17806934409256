import React from 'react';
import { PropTypes } from 'prop-types';
import {
    CurrentVacanciesCardContainer,
    CurrentVacanciesCardContentMore,
    CurrentVacanciesCardText,
    CurrentVacanciesCardTitle,
} from './CurrentVacanciesCard.styles';
import ImgCategory from '../../../../../assets/images/OurCareerPage/Vacancies/1.png';
import ImgWorkingFormat from '../../../../../assets/images/OurCareerPage/Vacancies/2.png';
import ImgPostDate from '../../../../../assets/images/OurCareerPage/Vacancies/3.png';
import ImgLocation from '../../../../../assets/images/OurCareerPage/Vacancies/4.png';
import { content } from '../../../../../commons/content';

export default function CurrentVacanciesCard({ title, category, workingFormat, postDate, location, buttonLink }) {
    return (
        <CurrentVacanciesCardContainer>
            <CurrentVacanciesCardTitle>{title}</CurrentVacanciesCardTitle>
            <CurrentVacanciesCardText>
                <img src={ImgCategory} alt={category} />
                {category}
            </CurrentVacanciesCardText>
            <CurrentVacanciesCardText>
                <img src={ImgWorkingFormat} alt={workingFormat} />
                {workingFormat}
            </CurrentVacanciesCardText>
            <CurrentVacanciesCardText>
                <img src={ImgPostDate} alt={postDate} />
                {postDate}
            </CurrentVacanciesCardText>
            <CurrentVacanciesCardText>
                <img src={ImgLocation} alt={location} />
                {location}
            </CurrentVacanciesCardText>
            <CurrentVacanciesCardContentMore to={buttonLink}>
                {content.CURRENT_VACANCIES_BUTTON_TEXT}
                <svg xmlns="http://www.w3.org/2000/svg" width="1.6rem" height="1.6rem" viewBox="0 0 16 16" fill="none">
                    <path d="M6 12L10 8L6 4" stroke="#FF7100" strokeWidth="2" />
                </svg>
            </CurrentVacanciesCardContentMore>
        </CurrentVacanciesCardContainer>
    );
}

CurrentVacanciesCard.propTypes = {
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    workingFormat: PropTypes.string.isRequired,
    postDate: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
};
