import React from 'react';
import PropTypes from 'prop-types';
import { CloseGlobalSearchButton } from './styles';

export default function CloseGlobalSearch({ onClick }) {
    return (
        <CloseGlobalSearchButton type="button" onClick={onClick} className="closeGlobalSearch">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path
                    d="M30.6 32.9667L20 22.35L9.39999 32.9667L7.03333 30.6L17.65 20L7.03333 9.39999L9.39999 7.03333L20 17.65L30.6 7.04999L32.95 9.39999L22.35 20L32.95 30.6L30.6 32.9667Z"
                    fill="#FF7100"
                />
            </svg>
        </CloseGlobalSearchButton>
    );
}

CloseGlobalSearch.propTypes = {
    onClick: PropTypes.func.isRequired,
};
