/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'styles/commons';
import _ from 'lodash';
import {
    GlobalSearchButtons,
    GlobalSearchInner,
    GlobalSearchInputWrapper,
    GlobalSearchWrapper,
    NewsBlock,
} from './styles';
import CloseGlobalSearch from './CloseGlobalSearch';
import GlobalSearchButton from './GlobalSearchButton';
import { useGlobalSearch, useSearch } from '../../commons/listHooks';
import { ContainerFull, Row, RowAlignCol, TotalCountText } from '../../styles/commons';
import { fetchSearchData } from '../../services/NewsService';
import { ProductsSearchSectionContainer, PromohubSearchResultContainer } from './SearchSection/styles';
import PromohubSearchSection from './SearchSection/PromohubSearchSection';
import SingleSearchProduct from './SearchSection/SingleSearchProduct';
import GlobalNotFoundBlock from './SearchSection/NotFoundBlocks/GlobalNotFound';
import { Button } from '../common/Button';

const normalizeCount = (number, wordsArr) => {
    const num = Math.abs(number);
    if (Number.isInteger(num)) {
        const options = [2, 0, 1, 1, 1, 2];
        return wordsArr[num % 100 > 4 && num % 100 < 20 ? 2 : options[num % 10 < 5 ? num % 10 : 5]];
    }
    return wordsArr[1];
};

export const GlobalSearch = ({ onClick }) => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [searchData, setSearchData] = useState(null);
    const [globalNotFound, setGlobalNotFound] = useState({ state: false, index: -1 });
    const handleButtonClick = (index) => {
        setActiveIndex(index === activeIndex ? 1 : index);
    };
    const [loading, setLoading] = useState(false);
    const {
        renderSearch,
        searchValue,
        setSearchValue,
        // , resetSearch
    } = useGlobalSearch();

    const search = () => {
        if (!searchValue || searchValue.length === 0) return;

        setLoading(true);

        fetchSearchData({ term: searchValue, limit: 6 })
            .then((res) => res.data)
            .then(setSearchData)
            .then(() => setLoading(false));
    };

    useEffect(() => {
        search();
    }, [searchValue]);

    const [data, metadata] = useMemo(() => [searchData ? searchData.data : [], searchData ? searchData.metadata : {}], [
        searchData,
    ]);

    const totalCounts = useMemo(
        () => [
            metadata.all && metadata.all ? metadata.all.count : 0,
            metadata.promohub && metadata.promohub
                ? metadata.promohub.news.count + metadata.promohub.publics.count + metadata.promohub.videos.count
                : 0,
            metadata.products && metadata.products ? metadata.products.count : 0,
        ],
        [metadata],
    );

    return (
        <>
            <GlobalSearchWrapper>
                <ContainerFull>
                    <Container>
                        <GlobalSearchInner>
                            <GlobalSearchInputWrapper>
                                {renderSearch({})}
                                {/* {showAutocomplete && <Autocomplete items={autocomplete} onItemClick={setSearchValue} />} */}
                            </GlobalSearchInputWrapper>
                            <CloseGlobalSearch onClick={onClick} />
                        </GlobalSearchInner>
                        {searchValue.length < 1 ? (
                            <></>
                        ) : (
                            <>
                                <GlobalSearchButtons>
                                    <GlobalSearchButton
                                        index={1}
                                        activeIndex={activeIndex}
                                        onClick={handleButtonClick}
                                        title="Все"
                                        totalCount={totalCounts[0]}
                                    />
                                    <GlobalSearchButton
                                        index={2}
                                        activeIndex={activeIndex}
                                        onClick={handleButtonClick}
                                        title="Промохаб"
                                        totalCount={totalCounts[1]}
                                    />
                                    <GlobalSearchButton
                                        index={3}
                                        activeIndex={activeIndex}
                                        onClick={handleButtonClick}
                                        title="Продукты"
                                        totalCount={totalCounts[2]}
                                    />
                                </GlobalSearchButtons>
                                <NewsBlock>
                                    <Container>
                                        <RowAlignCol>
                                            {activeIndex === 3 &&
                                            data.products !== undefined &&
                                            data.products.length > 0 ? (
                                                <>
                                                    <TotalCountText>
                                                        {`Найдено ${totalCounts[1] && totalCounts[2] ? 'и' : ''} ${
                                                            totalCounts[2] ? 'в разделе «Продукты»' : ''
                                                        } ${totalCounts[2]} ${normalizeCount(totalCounts[2], [
                                                            'результат',
                                                            'результата',
                                                            'результатов',
                                                        ])}`}
                                                    </TotalCountText>
                                                    <ProductsSearchSectionContainer>
                                                        {data.products.map((item) => (
                                                            <SingleSearchProduct item={item} term={searchValue} />
                                                        ))}
                                                        {data.products.length < 3 ? (
                                                            <></>
                                                        ) : (
                                                            <Button
                                                                search
                                                                link={`/products?term=${searchValue}`}
                                                                title="Смотреть все"
                                                            />
                                                        )}
                                                    </ProductsSearchSectionContainer>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {activeIndex === 2 && data.promohub !== undefined ? (
                                                <PromohubSearchResultContainer>
                                                    {Object.keys(data.promohub).map((objectKey, i, array) => (
                                                        <>
                                                            <PromohubSearchSection
                                                                objectKey={objectKey}
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                key={i}
                                                                collection={data.promohub[objectKey]}
                                                                term={searchValue}
                                                            />
                                                            {i !== array.length - 1 ? (
                                                                <div
                                                                    style={{
                                                                        border: 1,
                                                                        clear: 'both',
                                                                        display: 'block',
                                                                        backgroundColor: '#B3B3B3',
                                                                        minHeight: 1,
                                                                        marginTop: -40,
                                                                        width: '100%',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div style={{ marginBottom: 20 }} />
                                                            )}
                                                            {}
                                                        </>
                                                    ))}
                                                </PromohubSearchResultContainer>
                                            ) : (
                                                <></>
                                            )}
                                            {activeIndex === 1 && data.all !== undefined ? (
                                                <>
                                                    {data.all.promohub.length > 0 || data.all.products.length > 0 ? (
                                                        <TotalCountText>
                                                            {`Найдено ${totalCounts[1] ? 'в разделе «Промохаб»' : ''} ${
                                                                totalCounts[1] && totalCounts[2] ? 'и' : ''
                                                            } ${totalCounts[2] ? 'в разделе «Продукты»' : ''} ${
                                                                totalCounts[0]
                                                            } ${normalizeCount(totalCounts[0], [
                                                                'результат',
                                                                'результата',
                                                                'результатов',
                                                            ])}`}
                                                        </TotalCountText>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {data.all.promohub.length > 0 ? (
                                                        <>
                                                            <PromohubSearchResultContainer>
                                                                <PromohubSearchSection
                                                                    collection={data.all.promohub}
                                                                    term={searchValue}
                                                                    promoData={data.promohub}
                                                                />
                                                            </PromohubSearchResultContainer>
                                                            <div
                                                                style={{
                                                                    border: 0,
                                                                    clear: 'both',
                                                                    display: 'block',
                                                                    backgroundColor: '#B3B3B3',
                                                                    minHeight: 1,
                                                                    marginBottom: 60,
                                                                    marginTop: 35,
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {data.all.promohub.length === 0 &&
                                                            data.all.products.length > 0 ? (
                                                                <>
                                                                    <PromohubSearchResultContainer>
                                                                        <h2
                                                                            style={{
                                                                                marginTop: '20px',
                                                                                paddingBottom: '18px',
                                                                            }}
                                                                        >
                                                                            Промохаб
                                                                        </h2>
                                                                        <p>{`Публикаций, видео, новостей по запросу «${searchValue}» не найдено`}</p>
                                                                    </PromohubSearchResultContainer>
                                                                    <div
                                                                        style={{
                                                                            border: 0,
                                                                            clear: 'both',
                                                                            display: 'block',
                                                                            backgroundColor: '#B3B3B3',
                                                                            minHeight: 1,
                                                                            marginBottom: 60,
                                                                            marginTop: 35,
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    )}
                                                    {data.all.products.length > 0 ? (
                                                        <>
                                                            <ProductsSearchSectionContainer>
                                                                <h2 style={{ marginTop: '20px' }}>Продукты</h2>
                                                                {data.all.products.map((item) => (
                                                                    <SingleSearchProduct
                                                                        item={item}
                                                                        term={searchValue}
                                                                    />
                                                                ))}
                                                                {data.all.products.length < 3 ? (
                                                                    <></>
                                                                ) : (
                                                                    <Button
                                                                        search
                                                                        link={`/products?term=${searchValue}`}
                                                                        title="Смотреть все"
                                                                    />
                                                                )}
                                                            </ProductsSearchSectionContainer>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {data.all.promohub.length > 0 &&
                                                            data.all.products.length === 0 ? (
                                                                <ProductsSearchSectionContainer>
                                                                    <h2
                                                                        style={{
                                                                            marginTop: '20px',
                                                                            paddingBottom: '18px',
                                                                        }}
                                                                    >
                                                                        Продукты
                                                                    </h2>
                                                                    <p>{`Продуктов по запросу «${searchValue}» не найдено`}</p>
                                                                </ProductsSearchSectionContainer>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {(data.products !== undefined &&
                                                data.products.length === 0 &&
                                                activeIndex === 3) ||
                                            (data.all !== undefined &&
                                                data.all.products.length === 0 &&
                                                data.all.promohub.length === 0 &&
                                                activeIndex === 1) ? (
                                                <GlobalNotFoundBlock state={{ state: true, index: activeIndex }} />
                                            ) : (
                                                <></>
                                            )}
                                        </RowAlignCol>
                                    </Container>
                                </NewsBlock>
                            </>
                        )}
                    </Container>
                </ContainerFull>
            </GlobalSearchWrapper>
        </>
    );
};

GlobalSearch.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default GlobalSearch;
