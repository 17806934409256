import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { content } from '../../../commons/content';
import {
    PopupCareerForm,
    PopupCareerFormBackground,
    PopupCareerFormConfirmationText,
    PopupCareerFormContainer,
    PopupCareerFormContent,
    PopupCareerFormInput,
    PopupCareerFormSubmit,
    PopupCareerFormSubtitle,
    PopupCareerFormTitle,
} from './PopupCareerPageForm.styles';
import PopupCareerPageFormCloseSvg from './PopupCareerPageFormCloseSvg/PopupCareerPageFormCloseSvg';

export default function PopupCareerPageForm({ handlePopupClose }) {
    const containerRef = useRef(null);
    const [formData, setFormData] = useState({
        fio: '',
        country: '',
        vacancy: '',
        job: '',
        email: '',
        phone: '',
    });
    const [formErrors, setFormErrors] = useState({});

    const handleMouseEnter = () => {
        if (containerRef.current) {
            document.body.classList.add('no-scroll-y');
        }
    };

    const handleMouseLeave = () => {
        if (containerRef.current) {
            document.body.classList.remove('no-scroll-y');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [name]: '',
        }));
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        if (formData.fio.trim() === '') {
            errors.fio = 'Обязательное поле.';
        }
        if (formData.email.trim() === '') {
            errors.email = 'Обязательное поле.';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'E-mail должен содержать символ @.';
        }
        if (formData.vacancy.trim() === '') {
            errors.vacancy = 'Обязательное поле.';
        }
        if (formData.phone.trim() === '') {
            errors.phone = 'Обязательное поле.';
        }
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/mail/vacancy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fullName: formData.fio,
                    phoneNumber: formData.phone,
                    email: formData.email,
                    vacancyName: formData.vacancy,
                }),
            });
            const result = await request.json();
            if (request.ok && result.data.sended) {
                window.location.href = '/thanks-page';
            }
        }
    };
    return (
        <PopupCareerFormContainer>
            <PopupCareerFormBackground
                onClick={handlePopupClose}
                ref={containerRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <PopupCareerFormContent ref={containerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <PopupCareerPageFormCloseSvg handlePopupClose={handlePopupClose} />
                <PopupCareerFormTitle>{content.POPUP_CAREER_CONTENT_TITLE}</PopupCareerFormTitle>
                <PopupCareerFormSubtitle>{content.POPUP_CAREER_CONTENT_SUBTITLE}</PopupCareerFormSubtitle>
                <PopupCareerForm onSubmit={handleSubmit} noValidate>
                    <div>
                        <PopupCareerFormInput
                            type="text"
                            placeholder={content.POPUP_CAREER_CONTENT_INPUT_FIO}
                            name="fio"
                            value={formData.fio}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.fio && <ErrorMessage>{formErrors.fio}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupCareerFormInput
                            type="email"
                            placeholder={content.POPUP_CAREER_CONTENT_INPUT_EMAIL}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.email && <ErrorMessage>{formErrors.email}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupCareerFormInput
                            type="text"
                            placeholder={content.POPUP_CAREER_CONTENT_INPUT_VACANCY}
                            name="vacancy"
                            value={formData.vacancy}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.vacancy && <ErrorMessage>{formErrors.vacancy}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupCareerFormInput
                            type="tel"
                            placeholder={content.POPUP_CAREER_CONTENT_INPUT_PHONE}
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        {formErrors.phone && <ErrorMessage>{formErrors.phone}</ErrorMessage>}
                    </div>
                    <div>
                        <PopupCareerFormSubmit type="submit">
                            {content.POPUP_CAREER_CONTENT_INPUT_SUBMIT}
                        </PopupCareerFormSubmit>
                    </div>
                </PopupCareerForm>
                <PopupCareerFormConfirmationText
                    dangerouslySetInnerHTML={{ __html: content.POPUP_CAREER_CONTENT_CONFIRMATION_TEXT }}
                />
            </PopupCareerFormContent>
        </PopupCareerFormContainer>
    );
}

PopupCareerPageForm.propTypes = {
    handlePopupClose: PropTypes.func.isRequired,
};

function ErrorMessage({ children }) {
    return <div style={{ color: 'red', fontSize: '1.2rem', paddingTop: '0.5rem' }}>{children}</div>;
}

ErrorMessage.propTypes = {
    children: PropTypes.node.isRequired,
};
