/* eslint-disable react/prop-types */
import React from 'react';

export default function CloseIcon({ onClose }) {
    const handleKeyDown = (event) => {
        if (event.key === 'Esc' || event.key === ' ') {
            onClose();
        }
    };
    return (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <div onClick={onClose} onKeyDown={handleKeyDown} role="button" tabIndex={0}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                <path
                    d="M18.3602 19.7797L12.0002 13.4097L5.64021 19.7797L4.22021 18.3597L10.5902 11.9997L4.22021 5.63973L5.64021 4.21973L12.0002 10.5897L18.3602 4.22973L19.7702 5.63973L13.4102 11.9997L19.7702 18.3597L18.3602 19.7797Z"
                    fill="#FF7100"
                />
            </svg>
        </div>
    );
}
