/* eslint-disable */
/* eslint-disable array-callback-return */
import React from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import './ProductItemSlider.styles.css';

export default function ProductItemSlider(propsImages) {
    const [img, setImages] = React.useState(null);

    React.useEffect(() => {
        const arrImg = [];
        propsImages.images.map((val) => {
            if (val !== '') {
                arrImg.push(val);
            }
        });
        setImages(
            arrImg.map((url) => ({
                original: `${url}`,
                thumbnail: `${url}`,
                originalAlt: `${url.split('/').pop().split('.').shift()}`,
            })),
        );
    }, [propsImages]);

    return img ? (
        <ImageGallery
            items={img}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
            thumbnailClass="thumbnailTest"
        />
    ) : null;
}
