import React from 'react';
import ContentLoader from 'react-content-loader';
import { isMobile, isTablet } from '../../../commons/constants';

export default function ProductsListPageSkeleton() {
    return (
        <>
            {isMobile && (
                <ContentLoader
                    speed={3}
                    width="28.8rem"
                    height="51.5rem"
                    viewBox="0 0 288 515"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="28.8rem" height="28.8rem" />
                    <rect x="0" y="30.8rem" rx="0" ry="0" width="28.8rem" height="1.8rem" />
                    <rect x="0" y="34.6rem" rx="0" ry="0" width="28.8rem" height="1.4rem" />
                    <rect x="0" y="46.3rem" rx="2.5rem" ry="2.5rem" width="13.4rem" height="4.8rem" />
                    <rect x="0" y="36.4rem" rx="0" ry="0" width="28.8rem" height="1.4rem" />
                    <rect x="0" y="38.2rem" rx="0" ry="0" width="28.8rem" height="1.4rem" />
                    <rect x="15.4rem" y="46.3rem" rx="0" ry="0" width="13.4rem" height="4.8rem" />
                </ContentLoader>
            )}

            {isTablet && !isMobile && (
                <ContentLoader
                    speed={3}
                    width="64.8rem"
                    height="31.4rem"
                    viewBox="0 0 648 314"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="31.4rem" height="31.4rem" />
                    <rect x="33.4rem" y="0" rx="0" ry="0" width="31.4rem" height="1.8rem" />
                    <rect x="33.4rem" y="3.4rem" rx="0" ry="0" width="31.4rem" height="1.4rem" />
                    <rect x="33.4rem" y="16.5rem" rx="3.2rem" ry="3.2rem" width="21.7rem" height="6rem" />
                    <rect x="33.4rem" y="5.8rem" rx="0" ry="0" width="31.4rem" height="1.4rem" />
                    <rect x="33.4rem" y="8.2rem" rx="0" ry="0" width="31.4rem" height="1.4rem" />
                </ContentLoader>
            )}

            {!isMobile && !isTablet && (
                <ContentLoader
                    speed={3}
                    width="132rem"
                    height="42.7rem"
                    viewBox="0 0 1320 427"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="0" ry="0" width="42.7rem" height="42.7rem" />
                    <rect x="44.7rem" y="0" rx="0" ry="0" width="87.3rem" height="2.9rem" />
                    <rect x="44.7rem" y="4.9rem" rx="0" ry="0" width="87.3rem" height="2.4rem" />
                    <rect x="44.7rem" y="20rem" rx="3.2rem" ry="3.2rem" width="23.8rem" height="6.4rem" />
                    <rect x="44.7rem" y="8.1rem" rx="0" ry="0" width="87.3rem" height="2.4rem" />
                    <rect x="44.7rem" y="11.3rem" rx="0" ry="0" width="87.3rem" height="2.4rem" />
                    <rect x="70.5rem" y="20rem" rx="0" ry="0" width="18.8rem" height="6.4rem" />
                </ContentLoader>
            )}
        </>
    );
}
