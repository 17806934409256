import styled from 'styled-components';
import { White, Orange } from 'styles/colors';

export const Nav = styled.nav`
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    justify-items: right;
`;

export const NavContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 132rem;
    @media (max-width: 768px) {
        width: 64.8rem;
    }
    @media (max-width: 480px) {
        width: 28.8rem;
    }
`;

export const CountryBar = styled.div`
    display: flex;
    align-items: center;
`;

export const SearchAll = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
        path {
            transition: stroke 0.3s ease;
        }
    }
    &:hover {
        svg {
            path {
                stroke: ${Orange};
            }
        }
    }
`;

export const CountryHeaderBar = styled.div`
    width: 13.4rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 1.2rem;
    @media (max-width: 768px) {
        margin-left: 2rem;
    }
    @media (max-width: 480px) {
        display: none;
    }
`;

export const NavBar = styled.div`
    position: fixed;
    /* width: 100%; */
    z-index: 10;
    background-color: ${White};
    /* height: 12rem; */

    /* margin: 0rem auto 0rem auto;
    max-height: 12rem; */
    -webkit-box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.75);
    box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.75);
`;

export const LogoBar = styled.div`
    width: 17.4rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            svg {
                width: 17.4rem;
                height: 5rem;
                @media (max-width: 768px) {
                    width: 13rem;
                    height: 3.6rem;
                }
                @media (max-width: 480px) {
                    width: 11.6rem;
                    height: 3.2rem;
                }
            }
        }
    }
`;

export const MenuBar = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    right: 0rem;
`;
