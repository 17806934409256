/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import CloseIcon from './CloseIcon/CloseIcon';
import { Button, ModalContainer, Subtitle, Title } from './BottomRightModalCookies.styles';

export const BottomRightModalCookies = ({ onClose }) => {
    const handleAccept = () => {
        Cookies.set('cookieName', 'cookieValue', { expires: 30 });
        onClose();
    };

    useEffect(() => {
        const hasAcceptedCookies = Cookies.get('cookieName');
        if (hasAcceptedCookies) {
            onClose();
        }
    }, [onClose]);

    return (
        <ModalContainer>
            <CloseIcon onClose={onClose} />
            <Title>Cookie-файлы</Title>
            <Subtitle>
                Продолжая работу с сайтом, вы разрешаете использовать cookie. Подробнее в &nbsp;
                <a href="/cookie-policy" target="_blank">
                    «Политика конфиденциальности»
                </a>
                .
            </Subtitle>
            <Button onClick={handleAccept}>Принять</Button>
        </ModalContainer>
    );
};

export default BottomRightModalCookies;
