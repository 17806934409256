import React from 'react';
import HomeSlider1 from 'assets/images/SliderHome/HomeSlider1.png';
import HomeSlider2 from 'assets/images/SliderHome/HomeSlider2.png';
import HomeSlider3 from 'assets/images/SliderHome/HomeSlider3.png';
import HomeSlider4 from 'assets/images/SliderHome/HomeSlider4.png';
import HomeSlider5 from 'assets/images/SliderHome/HomeSlider5.png';
import HomeSlider6 from 'assets/images/SliderHome/HomeSlider6.png';
import HomeSlider7 from 'assets/images/SliderHome/HomeSlider7.png';

import HomeSlider1Tab from 'assets/images/SliderHome/HomeSlider1-tab.png';
import HomeSlider2Tab from 'assets/images/SliderHome/HomeSlider2-tab.png';
import HomeSlider3Tab from 'assets/images/SliderHome/HomeSlider3-tab.png';
import HomeSlider4Tab from 'assets/images/SliderHome/HomeSlider4-tab.png';
import HomeSlider5Tab from 'assets/images/SliderHome/HomeSlider5-tab.png';
import HomeSlider6Tab from 'assets/images/SliderHome/HomeSlider6-tab.png';
import HomeSlider7Tab from 'assets/images/SliderHome/HomeSlider7-tab.png';

import HomeSlider1Mob from 'assets/images/SliderHome/HomeSlider1-mob.png';
import HomeSlider2Mob from 'assets/images/SliderHome/HomeSlider2-mob.png';
import HomeSlider3Mob from 'assets/images/SliderHome/HomeSlider3-mob.png';
import HomeSlider4Mob from 'assets/images/SliderHome/HomeSlider4-mob.png';
import HomeSlider5Mob from 'assets/images/SliderHome/HomeSlider5-mob.png';
import HomeSlider6Mob from 'assets/images/SliderHome/HomeSlider6-mob.png';
import HomeSlider7Mob from 'assets/images/SliderHome/HomeSlider7-mob.png';

import { content } from 'commons/content';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import {
    SlideImg,
    SlideSubtitle,
    SlideTitle,
    HomeSliderButtons,
    ContainerMaxSlide,
    HomeSlideContainer,
    SlideTitleH1,
    HomeSliderButtonNextContainer,
    HomeSliderPaginationContainer,
    MainSliderContainer,
} from './MainSliderSection.styles';
import 'swiper/swiper-bundle.min.css';
import { ContainerFull } from '../../../../styles/commons';
import './styles.css';
import ButtonNext from './ButtonNext/ButtonNext';
import { Button } from '../../Button';
import ButtonPrev from './ButtonPrev/ButtonPrev';

export const MainSliderSection = () => {
    return (
        <ContainerFull paddingTop="0">
            <MainSliderContainer>
                <Swiper
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    navigation={{ nextEl: '.home-section-slider-next', prevEl: '.home-section-slider-prev' }}
                    pagination={{ el: '.home-section-slider-pagination', clickable: true }}
                >
                    <SwiperSlide>
                        <SlideImg imgWeb={HomeSlider1} imgTab={HomeSlider1Tab} imgMob={HomeSlider1Mob}>
                            <ContainerMaxSlide>
                                <HomeSlideContainer>
                                    <SlideTitleH1>{content.SLIDER_HOME_TITLE1}</SlideTitleH1>
                                    <SlideSubtitle>{content.SLIDER_HOME_SUBTITLE1}</SlideSubtitle>
                                    <Button link="/publics/148" title={content.SLIDER_HOME_BUTTON} />
                                </HomeSlideContainer>
                            </ContainerMaxSlide>
                        </SlideImg>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideImg imgWeb={HomeSlider2} imgTab={HomeSlider2Tab} imgMob={HomeSlider2Mob}>
                            <ContainerMaxSlide>
                                <HomeSlideContainer>
                                    <SlideTitle>{content.SLIDER_HOME_TITLE2}</SlideTitle>
                                    <SlideSubtitle>{content.SLIDER_HOME_SUBTITLE2}</SlideSubtitle>
                                    <Button link="/publics/143" title={content.SLIDER_HOME_BUTTON} />
                                </HomeSlideContainer>
                            </ContainerMaxSlide>
                        </SlideImg>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideImg imgWeb={HomeSlider3} imgTab={HomeSlider3Tab} imgMob={HomeSlider3Mob}>
                            <ContainerMaxSlide>
                                <HomeSlideContainer>
                                    <SlideTitle>{content.SLIDER_HOME_TITLE3}</SlideTitle>
                                    <SlideSubtitle>{content.SLIDER_HOME_SUBTITLE3}</SlideSubtitle>
                                    <Button link="/publics/146" title={content.SLIDER_HOME_BUTTON} />
                                </HomeSlideContainer>
                            </ContainerMaxSlide>
                        </SlideImg>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideImg imgWeb={HomeSlider4} imgTab={HomeSlider4Tab} imgMob={HomeSlider4Mob}>
                            <ContainerMaxSlide>
                                <HomeSlideContainer>
                                    <SlideTitle>{content.SLIDER_HOME_TITLE4}</SlideTitle>
                                    <SlideSubtitle>{content.SLIDER_HOME_SUBTITLE4}</SlideSubtitle>
                                    <Button link="/publics/145" title={content.SLIDER_HOME_BUTTON} />
                                </HomeSlideContainer>
                            </ContainerMaxSlide>
                        </SlideImg>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideImg imgWeb={HomeSlider5} imgTab={HomeSlider5Tab} imgMob={HomeSlider5Mob}>
                            <ContainerMaxSlide>
                                <HomeSlideContainer>
                                    <SlideTitle>{content.SLIDER_HOME_TITLE5}</SlideTitle>
                                    <SlideSubtitle>{content.SLIDER_HOME_SUBTITLE5}</SlideSubtitle>
                                    <Button link="/publics/144" title={content.SLIDER_HOME_BUTTON} />
                                </HomeSlideContainer>
                            </ContainerMaxSlide>
                        </SlideImg>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideImg imgWeb={HomeSlider6} imgTab={HomeSlider6Tab} imgMob={HomeSlider6Mob}>
                            <ContainerMaxSlide>
                                <HomeSlideContainer>
                                    <SlideTitle>{content.SLIDER_HOME_TITLE6}</SlideTitle>
                                    <SlideSubtitle>{content.SLIDER_HOME_SUBTITLE6}</SlideSubtitle>
                                    <Button link="/publics/148" title={content.SLIDER_HOME_BUTTON} />
                                </HomeSlideContainer>
                            </ContainerMaxSlide>
                        </SlideImg>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SlideImg imgWeb={HomeSlider7} imgTab={HomeSlider7Tab} imgMob={HomeSlider7Mob}>
                            <ContainerMaxSlide>
                                <HomeSlideContainer>
                                    <SlideTitle>{content.SLIDER_HOME_TITLE7}</SlideTitle>
                                    <SlideSubtitle>{content.SLIDER_HOME_SUBTITLE7}</SlideSubtitle>
                                    <Button
                                        link="/calculator-dlya-rascheta-raka-grudi/"
                                        title={content.SLIDER_HOME_BUTTON_TEST}
                                    />
                                </HomeSlideContainer>
                            </ContainerMaxSlide>
                        </SlideImg>
                    </SwiperSlide>
                </Swiper>
                <HomeSliderButtonNextContainer>
                    <HomeSliderButtons>
                        <div className="home-section-slider-prev">
                            <ButtonPrev />
                        </div>
                        <div className="home-section-slider-next">
                            <ButtonNext />
                        </div>
                    </HomeSliderButtons>
                </HomeSliderButtonNextContainer>
                <HomeSliderPaginationContainer>
                    <div className="home-section-slider-pagination" />
                </HomeSliderPaginationContainer>
            </MainSliderContainer>
        </ContainerFull>
    );
};

export default MainSliderSection;
