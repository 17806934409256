/* eslint-disable no-nested-ternary */
export const calculatorData = [
    {
        title: 'Возраст на момент посещения врача',
        values: [
            { id: 1, title: '35-39', value: 0.278 },
            { id: 2, title: '40-44', value: 0.45 },
            { id: 3, title: '45-49', value: 0.584 },
            { id: 4, title: '50-54', value: 0.703 },
            { id: 5, title: '55-59', value: 0.859 },
            { id: 6, title: '60-64', value: 1.018 },
            { id: 7, title: '65-69', value: 1.116 },
            { id: 8, title: '70-74', value: 1.157 },
            { id: 9, title: '75-79', value: 1.14 },
            { id: 10, title: '80-84', value: 1.006 },
        ],
    },
    {
        title: 'Возраст начала менструаций',
        values: [
            { id: 0, title: 'В 14 лет и позже', value: 1.0 },
            { id: 1, title: '12-13 лет', value: 1.1 },
            { id: 2, title: 'Раньше 12 лет', value: 1.21 },
        ],
    },
    {
        title: 'Возраст рождения первого ребенка',
        values: [
            { id: 0, title: 'до 20 лет', value: 1 },
            { id: 1, title: '20-24 года', value: 2 },
            { id: 4, title: '25-29 лет или нерожавшая женщина', value: 4 },
            { id: 2, title: '30 лет и более', value: 3 },
        ],
    },
    {
        title: 'Сколько знаете родственников 1 степени родства с раком молочной железы',
        values: [
            { id: 0, title: 'Нет родственников первой степени родства с раком молочной железы', value: 1 },
            { id: 1, title: '1 родственник первой степени родства с раком молочной железы', value: 2 },
            { id: 2, title: '2 и более родственников с первой степенью родства с раком молочной железы', value: 3 },
        ],
    },
    {
        title: 'Количество биопсий',
        values: [
            { id: 0, title: 'Биопсии не выполнялись', value: 1 },
            { id: 1, title: '1 биопсия', value: 2 },
            { id: 2, title: '2 и более биопсии', value: 3 },
        ],
    },
    {
        title: 'Атипичная гиперплазия',
        values: [
            { id: 0, title: 'Биопсии не выполнялись', value: 1 },
            {
                id: 1,
                title:
                    'Была выполнена, по крайней мере, одна биопсия, ни в одном из биоптатов атипичная гиперплазия не выявлена ',
                value: 0.93,
            },
            {
                id: 2,
                title:
                    'Атипичная гиперплазия не выявлена, неизвестен статус гиперплазии, по крайней мере, для одного биоптата',
                value: 1.000001,
            },
            { id: 3, title: 'Атипичная гиперплазия выявлена, по крайней мере, в одном биоптате', value: 1.82 },
        ],
    },
];

export const calculateChildAndRelatives = (child, relatives) => {
    if (child === 1) {
        return relatives === 1 ? 1 : relatives === 2 ? 2.61 : relatives === 3 ? 6.8 : 0;
    }
    if (child === 2) {
        return relatives === 1 ? 1.24 : relatives === 2 ? 2.68 : relatives === 3 ? 5.78 : 0;
    }
    if (child === 3) {
        return relatives === 1 ? 1.93 : relatives === 2 ? 2.83 : relatives === 3 ? 4.17 : 0;
    }
    if (child === 4) {
        return relatives === 1 ? 1.55 : relatives === 2 ? 2.76 : relatives === 3 ? 4.91 : 0;
    }
    return 0;
};

export const calculateBiopsy = (age, biopsy) => {
    if (age >= 0.703) {
        return biopsy === 1 ? 1 : biopsy === 2 ? 1.7 : biopsy === 3 ? 2.88 : 0;
    }
    if (age < 0.703) {
        return biopsy === 1 ? 1 : biopsy === 2 ? 1.27 : biopsy === 3 ? 1.62 : 0;
    }
    return 0;
};
