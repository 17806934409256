/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-irregular-whitespace */

import React, { useContext } from 'react';
import { BottomModalContext } from 'context/context';
import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
import { Container } from 'styles/commons';
import { Orange } from 'styles/colors';
import { CookiePageContainer } from './styles';
import { ContainerFull, FlexColumn, GlobalContainer } from '../../styles/commons';
import { isMobile, isTablet } from '../../commons/constants';

const PoliticsCookie = () => {
    return (
        <>
            <GlobalContainer>
                <ContainerFull paddingBottom="12rem">
                    <Container>
                        <FlexColumn alignItems="flex-start">
                            <CookiePageContainer>
                                <h1>Заявление о защите права пользователей на неприкосновенность частной жизни</h1>
                                <h2>Политика защиты неприкосновенности частной жизни в Интернете</h2>
                                <h3>Заявление компании Acino International AG и ее дочерних компаний</h3>
                                <p>
                                    Мы благодарим Вас за проявленный интерес к компании Acino и рады приветствовать на
                                    нашем веб-сайте.
                                </p>
                                <p>
                                    Защита неприкосновенности Вашей частной жизни имеет чрезвычайную важность для нас.
                                    Мы также хотели бы заверить Вас в том, что компания Acino соблюдает все
                                    соответствующие нормативно-правовые требования.
                                </p>
                                <p>
                                    В настоящем заявлении компании Acino о защите права пользователей на
                                    неприкосновенность частной жизни описывается порядок сбора, управления и
                                    использования персональных данных в Интернете компанией Acino. Настоящая политика
                                    защиты неприкосновенности частной жизни распространяется только на данные,
                                    полученные и контролируемые компанией Acino, т.е. на интернет-страницы, содержащие
                                    прямые гиперссылки на веб-сайт, где размещено данное заявление о защите права
                                    пользователей на неприкосновенность частной жизни. Наша политика не распространяется
                                    на информацию, полученную из других источников.
                                </p>
                                <p>
                                    Пользуясь данным веб-сайтом, вы даете согласие на сбор, регистрацию и использование
                                    информации согласно настоящей политике защиты неприкосновенности частной жизни,
                                    которая полностью соответствует всем требованиям действующего законодательства РБ об
                                    охране частной жизни гражданина и обработкой персональных данных. Кроме того, вы
                                    соглашаетесь с тем, что Acino, без предварительного уведомления и по собственному
                                    усмотрению, может вносить изменения в настоящую политику защиты неприкосновенности
                                    частной жизни. Acino стремится публиковать все изменения на данной странице, чтобы в
                                    полной мере обеспечить вас информацией о характере собираемых персональных данных,
                                    порядке их использования, а также условиях раскрытия информации компанией.
                                </p>
                                <h3>Сбор данных</h3>
                                <p>
                                    При каждом посещении Вами веб-сайта Acino автоматически осуществляется сбор общей
                                    информации, при этом сохраняются следующие данные: название вашего
                                    интернет-провайдера, адрес веб-сайта, с которого осуществлен переход, история
                                    страниц Acino, которыми вы заинтересовались, и ваш IP-адрес.
                                </p>
                                <p>
                                    Данная информация анализируется анонимно и используется для повышения
                                    привлекательности нашего веб-сайта, улучшения контента и функциональности с точки
                                    зрения удобства для Вас.
                                </p>
                                <h3>Гарантированная неприкосновенность частной жизни и ее защита</h3>
                                <p>
                                    Для целей настоящей Политики компании Acino по защите неприкосновенности частной
                                    жизни, термин «персональные данные» может включать такие сведения, как: имя, дата
                                    рождения, адрес электронной почты и номера телефонов. Другими словами, данные,
                                    которые дают возможность установить личность. Как правило, Acino не ведет учет
                                    персональных данных без прямо выраженного согласия соответствующего лица. Однако в
                                    исключительных случаях Acino оставляет за собой право собирать дополнительные данные
                                    с соблюдением ограничений и требований, установленных действующим законодательством.
                                    В последующих разделах описывается порядок и условия сбора и учета персональных
                                    данных.
                                </p>
                                <h3>Целевое использование персональных данных</h3>
                                <p>
                                    В некоторых странах законодательство в фармацевтической сфере ограничивает
                                    использование определенной информации только квалифицированными специалистами. В
                                    связи с этим, чтобы зарегистрироваться для получения доступа к защищенным с помощью
                                    пароля разделам веб-сайта, необходимо раскрыть персональные данные, такие как имя,
                                    фамилия, почтовый адрес, адрес электронной почты, а также дополнительные данные,
                                    которые могут предоставляться по желанию. Зарегистрированный пользователь веб-сайта
                                    может в любой момент видеть такие данные, внести в них изменения или удалить. Мы
                                    собираем только те данные, которые пользователи предоставили добровольно. Такие
                                    данные не передаются третьим лицам для коммерческого использования, однако они
                                    используются для улучшения вашего обслуживания или для связи с вами. Любой
                                    пользователь может в любой момент отозвать свое согласие на дальнейшее использование
                                    своих персональных данных.
                                </p>
                                <p>
                                    Acino хранит персональные данные согласно действующим нормативно-правовым
                                    требованиям в соответствии с наивысшими этическими стандартами предоставления
                                    информации и ведения документации.
                                </p>
                                <h3>Секретность персональных данных</h3>
                                <p>
                                    Acino не продает, не передает и не распространяет персональные данные среди третьих
                                    лиц, за исключением отдельных случаев, указанных в настоящем заявлении о защите
                                    права пользователей на неприкосновенность частной жизни.
                                </p>
                                <h3>Право на доступ, обновление и право на удаление данных</h3>
                                <p>
                                    Если пользователь раскрывает персональные данные на веб-сайте Acino по запросу,
                                    такой пользователь имеет неограниченное право на доступ, редактирование и удаление
                                    таких данных. Пользователь имеет также право запретить компании Acino обращаться к
                                    нему. Пользователям, желающим связаться с компанией Acino по вопросам использования
                                    персональных данных, необходимо направить запрос на e-mail:&nbsp;
                                    <a href="mailto:info@acino.swiss">info@acino.swiss</a>. Acino попросит указать
                                    название веб-сайта, на котором были зарегистрированы данные, а также конкретные
                                    данные, которые необходимо обновить или удалить. Мы внимательно и оперативно
                                    рассмотрит такой запрос. Запросы на удаление персональных данных рассматриваются в
                                    соответствии со всеми нормативными требованиями и этическими стандартами,
                                    касающимися ведения и хранения документации.
                                </p>
                                <h3>Безопасность и конфиденциальность</h3>
                                <p>
                                    Для обеспечения безопасности и конфиденциальности персональных данных,
                                    зарегистрированных компанией Acino онлайн, Acino использует передовые сети данных, в
                                    том числе новейшие межсетевые защитные экраны и защиту с помощью паролей. Несмотря
                                    на то, что Acino не может гарантировать полное отсутствие потери данных, их
                                    неправомерного использования или изменения, компания прилагает все усилия для
                                    достижения такой цели.
                                </p>
                                <h3>Анонимные данные, cookie-файлы и теги</h3>
                                <p>
                                    Acino может записывать анонимные данные о параметрах посещения своего веб-сайта,
                                    касающиеся посещения страниц и поисковых запросов. При посещении страницы, возможно,
                                    в Вашем компьютере будет сохранена информация в форме cookie-файла. В cookie-файлах
                                    Acino записывается только Интернет-провайдер пользователя (IP), а также дата и время
                                    посещения. Cookie-файлы Acino не используются для установления личности. Cookie-файл
                                    представляет собой тег, который отправляется браузерам и сохраняется на жестком
                                    диске пользователя для ускорения и упрощения возвращения на сайт после прерывания.
                                    Cookie-файлы не причиняют вреда компьютеру. Однако пользователь может изменить
                                    настройки таким образом, чтобы до установки cookie-файла браузер направлял запрос о
                                    разрешении такой установки. Acino может пользоваться услугами третьих лиц для сбора
                                    и обработки информации, указанной в данном разделе. На услуги таких третьих лиц
                                    распространяются обязательства, которые предусмотрены в пункте 4 «Секретность
                                    персональных данных».
                                </p>
                                <p>
                                    Acino следит за тем, чтобы с помощью тегов и cookie-файлов не собиралась и не
                                    записывалась информация, которая дает возможность каким-либо образом установить
                                    личность пользователя, например, раскрывая его имя, почтовый адрес, адрес
                                    электронной почты, номер телефона.
                                </p>
                                <h3>Персональная информация и дети</h3>
                                <p>
                                    Acino намеренно не ведет регистрацию персональных данных лиц, не достигших 13 лет,
                                    не использует такие данные и не предоставляет доступ к ним третьим лицам без прямо
                                    выраженного согласия родителя или опекуна. Acino раскрывает все сведения законному
                                    представителю ребенка. Такой представитель имеет право и возможность в любой момент
                                    обновить и удалить персональные данные ребенка. Acino выполняет юридические
                                    требования, касающиеся защиты детей.
                                </p>
                                <h3>Ссылки на другие страницы</h3>
                                <p>
                                    Настоящее заявление о защите права пользователей на неприкосновенность частной жизни
                                    распространяется только на веб-сайты компании Acino и прямо выраженным образом
                                    исключает веб-сайты третьих лиц. Acino по собственному усмотрению может указывать
                                    ссылки на веб-сайты третьих лиц, представляющие интерес для пользователей. Мы
                                    стремимся контролировать, чтобы страницы и сайты, на которые указаны ссылки,
                                    соответствовали высшим стандартам. Принимая во внимание быстро изменяющийся характер
                                    сети Интернет, Acino не может брать на себя ответственность за требования по защите
                                    неприкосновенности частной жизни в Интернете и контент на связанных веб-сайтах. Еще
                                    раз обращаем Ваше внимание на то, что настоящая политика защиты неприкосновенности
                                    частной жизни не распространяется на связанные веб-сайты.
                                </p>
                                <h3>Контактные данные Acino</h3>
                                <p>
                                    У вас могут возникнуть вопросы и даже жалобы, касающиеся защиты неприкосновенности
                                    частной жизни и сбора данных на веб-сайтах компании Acino, а также могут возникнуть
                                    опасения, касающиеся соответствия юридическим требованиям. Если у вас возникли
                                    подобные опасения или вы желаете оставить комментарий либо предложение, касающиеся
                                    политики Acino по защите неприкосновенности частной жизни, просим обращаться в
                                    компанию Acino International AG по следующему адресу:
                                </p>
                                <p>Acino International AG</p>
                                <p>Тургауэрштрассе, 36/38</p>
                                <p>CH-8050, Цюрих</p>
                                <p>
                                    Тел.:&nbsp;
                                    <a href="tel:+41445552200">+41 44 555 22 00</a>
                                </p>
                                 
                                <p>
                                    E-mail:&nbsp;<a href="mailto:info@acino.swiss">info@acino.swiss</a>
                                </p>
                                <p>
                                    Последнее обновление настоящей Политики защиты неприкосновенности частной жизни в
                                    Интернете внесено 28 октября 2016 года. Acino стремится постоянно совершенствовать
                                    технические условия обработки данных, доверенных нашей компании. Так как информация
                                    по защите неприкосновенности частной жизни регулярно обновляется, рекомендуем
                                    пользователям периодически повторно обращаться к данной странице.
                                </p>
                            </CookiePageContainer>
                        </FlexColumn>
                    </Container>
                </ContainerFull>
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
};

export default PoliticsCookie;
