import styled from 'styled-components';

export const OurPartnersSectionTitle = styled.h2`
    margin-bottom: 3.2rem;
    @media (max-width: 768px) {
        margin-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 2rem;
    }
`;
export const OurPartnersSectionContainer = styled.h2`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.2rem 9.8rem;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        gap: 1.6rem 4.8rem;
    }
    @media (max-width: 480px) {
        gap: 1.6rem 3rem;
    }
    div {
        height: 10rem;
        @media (max-width: 768px) {
            height: 4.9rem;
        }
        @media (max-width: 480px) {
            height: 3.1rem;
        }
        &:nth-child(1) {
            width: 10rem;
            @media (max-width: 768px) {
                width: 5rem;
            }
            @media (max-width: 480px) {
                width: 3.1rem;
            }
        }
        &:nth-child(2) {
            width: 24.8rem;
            @media (max-width: 768px) {
                width: 12.2rem;
            }
            @media (max-width: 480px) {
                width: 7.7rem;
            }
        }
        &:nth-child(3) {
            width: 23.1rem;
            @media (max-width: 768px) {
                width: 11.3rem;
            }
            @media (max-width: 480px) {
                width: 7.1rem;
            }
        }
        &:nth-child(4) {
            width: 24.9rem;
            @media (max-width: 768px) {
                width: 12.2rem;
            }
            @media (max-width: 480px) {
                width: 7.7rem;
            }
        }
        &:nth-child(5) {
            width: 10rem;
            @media (max-width: 768px) {
                width: 4.9rem;
            }
            @media (max-width: 480px) {
                width: 3.1rem;
            }
        }
        &:nth-child(6) {
            width: 41.1rem;
            @media (max-width: 768px) {
                width: 20.2rem;
            }
            @media (max-width: 480px) {
                width: 12.7rem;
            }
        }
        &:nth-child(7) {
            width: 42.1rem;
            @media (max-width: 768px) {
                width: 21.1rem;
            }
            @media (max-width: 480px) {
                width: 13rem;
            }
        }
        img {
            height: 100%;
            object-fit: contain;
        }
    }
    div:nth-child(1) {
        justify-content: flex-start;
    }
`;
