import React from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer } from './styles';

const ButtonWhite = ({ title, link }) => {
    return <ButtonContainer to={link}>{title}</ButtonContainer>;
};

ButtonWhite.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default ButtonWhite;
