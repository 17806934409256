import React, { useState } from 'react';
import { Container, ContainerFull, FlexColumn, FlexRow } from '../../../styles/commons';
import { content } from '../../../commons/content';
import {
    PartnersContent,
    PartnersContentButton,
    PartnersContentSubtitle,
    PartnersContentTitle,
} from './PartnersSection.styles';
import { PopupPartnersPageForm } from '../../../components/common/PopupPartnersPageForm';

export default function PartnersSection() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        document.body.classList.add('no-scroll-y');
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
        document.body.classList.remove('no-scroll-y');
    };

    return (
        <ContainerFull backgroundColor="linear-gradient(74.77deg, #FF7100 9.99%, #C70B6F 89.98%)" paddingBottom="12rem">
            <Container>
                <FlexRow alignItems="center" justifyContent="space-between">
                    <FlexColumn alignItems="flex-start" justifyContent="flex-start">
                        <PartnersContent>
                            <PartnersContentTitle>{content.PARTNERS_SECTION_TITLE}</PartnersContentTitle>
                            <PartnersContentSubtitle>{content.PARTNERS_SECTION_TEXT}</PartnersContentSubtitle>
                        </PartnersContent>
                    </FlexColumn>
                    <PartnersContentButton onClick={handlePopupOpen}>
                        {content.PARTNERS_SECTION_BUTTON}
                    </PartnersContentButton>
                    {isPopupOpen && <PopupPartnersPageForm handlePopupClose={handlePopupClose} />}
                </FlexRow>
            </Container>
        </ContainerFull>
    );
}
