import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Background, HoverColor, PrimaryColor } from '../../../styles/colors';

export const ButtonItem = styled(Link)`
    font-size: 2rem;
    line-height: 2rem;
    cursor: pointer;
    color: ${Background};
    background-color: ${(props) => props.backgroundcolorbutton || PrimaryColor};
    border: ${(props) => props.border || ''};
    padding: 2rem 5.6rem;
    border-radius: 3.2rem;
    transition: 0.6s ease;
    position: relative;
    z-index: 2;

    ${(props) =>
        props.backgroundcolorbutton === 'transparent'
            ? css`
                  &:hover {
                      transition: 0.6s ease;
                      background-color: ${Background};
                      color: ${HoverColor};
                      @media (max-width: 768px) {
                          background-color: ${Background};
                          color: ${PrimaryColor};
                      }
                  }
              `
            : css`
                  &:hover {
                      transition: 0.6s ease;
                      background-color: ${HoverColor};
                      @media (max-width: 768px) {
                          background-color: ${PrimaryColor};
                      }
                  }
              `}

    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }

    @media (max-width: 480px) {
        line-height: ${(props) => (props.search ? '0.2rem' : '1.6rem')};
        font-size: 1.6rem;
        width: 100%;
        text-align: center;
    }
`;

export const ButtonContainer = styled.div`
    opacity: ${(props) => (props.disabled ? '0.3' : '1')};
    pointer-events: ${(props) => props.disabled && 'none'};
    transition: all 0.3s;
    display: flex;
`;
