/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Button } from 'components/common/Button';
import { Container, GlobalContainer } from '../../../styles/commons';
import {
    CalculatorSlideValues,
    CalculatorContentItem,
    CalculatorNavigation,
    CalculatorRow,
    CalculatorSlideContainer,
    CalculatorSwiper,
    CalculatorSwiperInner,
    CalculatorSlideResultsDescription,
    CalculatorSlideResultsDescriptionItem,
} from './CalculatorSection.styles';
import { calculateBiopsy, calculateChildAndRelatives, calculatorData } from './constants';
import { RiskRakaInfoTitle } from '../Section/RiskRakaGrudiInfoSection/RiskRakaGrudiInfoSection.styles';

const defaultData = {
    age: 0,
    menstruation: 0,
    child: 0,
    relatives: 0,
    biopsy: 0,
    hyperplasia: 0,
};
export const CalculatorSection = () => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const [disabledButtonNextClick, setDisabledButtonNextClick] = useState(true);
    const swiperRef = useRef(null);
    const [collectedData, setCollectedData] = useState(defaultData);
    const calculatedData = useMemo(() => {
        return {
            childAndRelatives: calculateChildAndRelatives(collectedData.child, collectedData.relatives),
            biopsy: calculateBiopsy(collectedData.age, collectedData.biopsy),
        };
    }, [collectedData]);
    useEffect(() => {
        if (currentSlide === 1) {
            setDisabledButtonNextClick(collectedData.age === 0);
        }
        if (currentSlide === 2) {
            setDisabledButtonNextClick(collectedData.menstruation === 0);
        }
        if (currentSlide === 3) {
            setDisabledButtonNextClick(collectedData.child === 0);
        }
        if (currentSlide === 4) {
            setDisabledButtonNextClick(collectedData.relatives === 0);
        }
        if (currentSlide === 5) {
            setDisabledButtonNextClick(collectedData.biopsy === 0);
        }
        if (currentSlide === 6) {
            setDisabledButtonNextClick(collectedData.hyperplasia === 0);
        }
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(currentSlide - 1);
        }
    }, [currentSlide, collectedData]);
    const result = useMemo(() => {
        return {
            relative:
                collectedData.menstruation *
                calculatedData.biopsy *
                calculatedData.childAndRelatives *
                collectedData.hyperplasia,
            fiveYear:
                collectedData.menstruation *
                calculatedData.biopsy *
                calculatedData.childAndRelatives *
                collectedData.hyperplasia *
                collectedData.age,
        };
    }, [calculatedData]);
    const handleRadioClick = (value) => {
        switch (currentSlide) {
            case 1:
                setCollectedData((prevData) => ({
                    ...prevData,
                    age: value,
                }));
                break;
            case 2:
                setCollectedData((prevData) => ({
                    ...prevData,
                    menstruation: value,
                }));
                break;
            case 3:
                setCollectedData((prevData) => ({
                    ...prevData,
                    child: value,
                }));
                break;
            case 4:
                setCollectedData((prevData) => ({
                    ...prevData,
                    relatives: value,
                }));
                break;
            case 5:
                setCollectedData((prevData) => ({
                    ...prevData,
                    biopsy: value,
                }));
                break;
            case 6:
                setCollectedData((prevData) => ({
                    ...prevData,
                    hyperplasia: value,
                }));
                break;
            default:
                break;
        }
    };
    const handleNextButtonClick = () => {
        if (currentSlide === 7) {
            setCurrentSlide(1);
            setCollectedData(defaultData);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    return (
        <>
            <GlobalContainer id="calculator">
                <Container>
                    <CalculatorRow>
                        <CalculatorContentItem>
                            <RiskRakaInfoTitle justifyContent="flex-start" textAlign="start">
                                Калькулятор для расчёта риска развития рака груди*
                            </RiskRakaInfoTitle>
                            <p style={{ color: '#FF7100' }}>Примерное время прохождения: 2 минуты.</p>
                            <p>*Результаты теста не являются диагнозом, необходима консультация врача</p>
                            <p>
                                Фактор риска – это все то, что увеличивает вероятность развития болезни. И хотя факторы
                                риска зачастую влияют на развитие рака, большинство из них его не вызывают. Знание
                                собственных факторов риска и обсуждение их со своим врачом поможет сделать более
                                осознанный выбор образа жизни и медицинского обслуживания.
                            </p>
                        </CalculatorContentItem>
                        <CalculatorContentItem>
                            <CalculatorSwiper>
                                <CalculatorSwiperInner>
                                    <Swiper
                                        ref={swiperRef}
                                        modules={[Navigation]}
                                        slidesPerView={1}
                                        navigation={{
                                            nextEl: '.calculator-slide-next',
                                        }}
                                        allowTouchMove={false}
                                        spaceBetween={20}
                                    >
                                        {calculatorData.map((slide, index) => (
                                            <SwiperSlide index={index}>
                                                <CalculatorSlideContainer>
                                                    <h3>{slide.title}</h3>
                                                    <CalculatorSlideValues current={index}>
                                                        {slide.values.map((item, i) => (
                                                            <label
                                                                aria-hidden
                                                                className="custom-radio-label"
                                                                htmlFor={`radio-${index}-${i}`}
                                                                onClick={() => {
                                                                    handleRadioClick(item.value, i);
                                                                }}
                                                            >
                                                                <div className="custom-radio">
                                                                    <input
                                                                        type="radio"
                                                                        name="radio"
                                                                        id={`radio-${index}-${i}`}
                                                                    />
                                                                    <span className="checkmark" />
                                                                </div>
                                                                <p>{item.title}</p>
                                                            </label>
                                                        ))}
                                                    </CalculatorSlideValues>
                                                </CalculatorSlideContainer>
                                            </SwiperSlide>
                                        ))}
                                        <SwiperSlide>
                                            <CalculatorSlideContainer>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <h3 style={{ display: 'inline-flex', paddingBottom: '1.6rem' }}>
                                                        Относительный риск: &nbsp;
                                                        <p
                                                            style={{
                                                                color: '#FF7100',
                                                                fontSize: 'inherit',
                                                                lineHeight: 'inherit',
                                                                fontWeight: 'inherit',
                                                            }}
                                                        >
                                                            {result.relative.toFixed(2)}
                                                        </p>
                                                    </h3>
                                                    <h3 style={{ display: 'inline-flex' }}>
                                                        Пятилетний риск:&nbsp;
                                                        <p
                                                            style={{
                                                                color: '#FF7100',
                                                                fontSize: 'inherit',
                                                                lineHeight: 'inherit',
                                                                fontWeight: 'inherit',
                                                            }}
                                                        >
                                                            {result.fiveYear.toFixed(2)}
                                                        </p>
                                                    </h3>
                                                </div>
                                                <CalculatorSlideResultsDescription>
                                                    <CalculatorSlideResultsDescriptionItem>
                                                        <h3>до 10%</h3>
                                                        <p style={{ color: '#666B6E' }}>– относительно низкий риск</p>
                                                    </CalculatorSlideResultsDescriptionItem>
                                                    <CalculatorSlideResultsDescriptionItem>
                                                        <h3>10-20%</h3>
                                                        <p style={{ color: '#666B6E' }}>– риск средней значимости</p>
                                                    </CalculatorSlideResultsDescriptionItem>
                                                    <CalculatorSlideResultsDescriptionItem>
                                                        <h3>20-30%</h3>
                                                        <p style={{ color: '#666B6E' }}>– значительный риск</p>
                                                    </CalculatorSlideResultsDescriptionItem>
                                                    <CalculatorSlideResultsDescriptionItem>
                                                        <h3>более 30%</h3>
                                                        <p style={{ color: '#666B6E' }}>– критически значимый риск</p>
                                                    </CalculatorSlideResultsDescriptionItem>
                                                </CalculatorSlideResultsDescription>
                                            </CalculatorSlideContainer>
                                        </SwiperSlide>
                                    </Swiper>
                                </CalculatorSwiperInner>
                                <CalculatorNavigation>
                                    <Button
                                        className="calculator-slide-next"
                                        onClick={handleNextButtonClick}
                                        title={currentSlide === 7 ? 'Пройти заново' : 'Далее'}
                                        disabled={disabledButtonNextClick}
                                    />
                                    {currentSlide !== 7 && (
                                        <div className="calculator-slider-pagination">{`${currentSlide} - ${6}`}</div>
                                    )}
                                </CalculatorNavigation>
                            </CalculatorSwiper>
                        </CalculatorContentItem>
                    </CalculatorRow>
                </Container>
            </GlobalContainer>
        </>
    );
};

export default CalculatorSection;
