import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Background, HoverColor, PrimaryColor } from '../../../styles/colors';

export const HeaderSlideImg = styled.div`
    max-height: 80rem;
    height: 100%;
    height: 80rem;
    width: 100%;
    background-image: url(${({ images }) => images.web});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media (max-width: 768px) {
        background-image: url(${({ images }) => images.tab});
        height: 64.9rem;
    }
    @media (max-width: 480px) {
        background-image: url(${({ images }) => images.mob});
        height: 53.2rem;
    }
`;
export const HeaderSlideContainer = styled.div`
    max-width: 132rem;
    margin: 0 auto;
    padding-top: 12rem;
    @media (max-width: 768px) {
        max-width: 64.8rem;
        padding-top: 10rem;
    }
    @media (max-width: 480px) {
        max-width: 28.8rem;
        padding-top: 8rem;
    }
`;

export const HeaderSlideTitleH1 = styled.h1`
    font-size: 6rem;
    line-height: 7.2rem;
    color: ${Background};
    font-size: 6rem;
    margin-bottom: 2rem;
    width: 76.2rem;
    @media (max-width: 768px) {
        width: 39.8rem;
        font-size: 4.4rem;
        line-height: 5.3rem;
        margin-bottom: 1.6rem;
    }
    @media (max-width: 480px) {
        font-size: 3.2rem;
        line-height: 3.8rem;
        width: 100%;
    }
`;
export const HeaderSlideTitle = styled.h2`
    font-size: 6rem;
    line-height: 7.2rem;
    color: ${Background};
    font-size: 6rem;
    margin-bottom: 2rem;
    width: 76.2rem;
    @media (max-width: 768px) {
        width: 39.8rem;
        font-size: 4.4rem;
        line-height: 5.3rem;
        margin-bottom: 1.6rem;
    }
    @media (max-width: 480px) {
        font-size: 3.2rem;
        line-height: 3.8rem;
        width: 100%;
    }
`;

export const HeaderSlideSubtitle = styled.p`
    color: ${Background};
    font-size: 2rem;
    line-height: 2.6rem;
    margin-bottom: 6.4rem;
    width: 76.2rem;
    @media (max-width: 768px) {
        width: 39.8rem;
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-bottom: 4.4rem;
    }
    @media (max-width: 480px) {
        font-size: 1.4rem;
        line-height: 2.1rem;
        width: 100%;
    }
`;

export const HeaderSlideButton = styled(Link)`
    display: inline-flex;
    font-size: 2rem;
    line-height: 2rem;
    cursor: pointer;
    color: ${Background};
    background-color: ${PrimaryColor};
    padding: 2rem 5.6rem;
    border-radius: 3.2rem;
    transition: 0.6s ease;
    border: 0;
    :hover {
        transition: 0.6 ease;
        background-color: ${HoverColor};
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
        }
    }
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    @media (max-width: 480px) {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
`;

export const ButtonPopupOpen = styled.button`
    font-size: 2rem;
    line-height: 2rem;
    cursor: pointer;
    color: ${Background};
    background-color: transparent;
    border: 0.2rem solid #fff;
    padding: 2rem 5.6rem;
    border-radius: 3.2rem;
    transition: 0.6s ease;
    :hover {
        transition: 0.6s ease;
        background-color: ${Background};
        color: ${HoverColor};
        @media (max-width: 768px) {
            background-color: ${Background};
            color: ${PrimaryColor};
        }
    }
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
        font-size: 2rem;
        line-height: 2rem;
    }
    @media (max-width: 480px) {
        font-size: 1.6rem;
        line-height: 1.6rem;
        width: 100%;
        text-align: center;
    }
`;
