import React from 'react';
import { GlobalContainer } from '../../styles/commons';
import BreadCrumbs from '../../components/common/BreadCrumbs/BreadCrumbs';
import GoogleAnalitycs from '../../components/atoms/GoogleAnalitycs';
import { ContactsSection } from '../../components/common/Section';

export default function ContactsPage() {
    return (
        <>
            <BreadCrumbs />
            <GlobalContainer>
                <ContactsSection backgroundColor="#fff" />
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
}
