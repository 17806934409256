import styled from 'styled-components';
import { White, Pink, Orange } from 'styles/colors';
import { screenSizeSmall } from 'styles/screenSize';
import { Link } from 'react-router-dom';

export const ButtonContainer = styled(Link)`
    display: inline-block;
    padding: 2rem 0 2rem 0;
    margin: 2rem 0rem;
    width: 100%;
    height: auto;
    min-width: 20rem;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: ${Pink};
    background-color: ${White};
    font-size: 2.4rem;
    font-weight: 900;
    letter-spacing: 0rem;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    border: 0.2rem solid ${White};
    cursor: pointer;
    -webkit-border-radius: 4rem;
    -moz-border-radius: 4rem;
    border-radius: 4rem;

    &:hover {
        color: ${White};
        border-color: ${Orange};
        background-color: ${Orange};
    }
    @media screen and (max-width: ${screenSizeSmall}) {
        padding: 15x 0 1.5rem 0;
        font-size: 1.8rem;
        min-width: 15rem;
        margin-top: 1rem;
    }
`;
