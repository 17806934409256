import React from 'react';

export default function ProductsListPageCardsRowButtonSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4rem" height="4rem" viewBox="0 0 40 40" fill="none">
            <rect x="0.5" y="0.5" width="39" height="39" rx="4.5" fill="white" />
            <mask id="path-2-inside-1_1072_2378" fill="white">
                <rect x="10" y="10" width="5.6" height="5.6" rx="0.8" />
            </mask>
            <rect
                x="10"
                y="10"
                width="5.6"
                height="5.6"
                rx="0.8"
                stroke="#666B6E"
                strokeWidth="3"
                mask="url(#path-2-inside-1_1072_2378)"
            />
            <mask id="path-3-inside-2_1072_2378" fill="white">
                <rect x="17.1992" y="10" width="12.8" height="5.6" rx="0.8" />
            </mask>
            <rect
                x="17.1992"
                y="10"
                width="12.8"
                height="5.6"
                rx="0.8"
                stroke="#666B6E"
                strokeWidth="3"
                mask="url(#path-3-inside-2_1072_2378)"
            />
            <mask id="path-4-inside-3_1072_2378" fill="white">
                <rect x="10" y="17.2002" width="5.6" height="5.6" rx="0.8" />
            </mask>
            <rect
                x="10"
                y="17.2002"
                width="5.6"
                height="5.6"
                rx="0.8"
                stroke="#666B6E"
                strokeWidth="3"
                mask="url(#path-4-inside-3_1072_2378)"
            />
            <mask id="path-5-inside-4_1072_2378" fill="white">
                <rect x="17.1992" y="17.2002" width="12.8" height="5.6" rx="0.8" />
            </mask>
            <rect
                x="17.1992"
                y="17.2002"
                width="12.8"
                height="5.6"
                rx="0.8"
                stroke="#666B6E"
                strokeWidth="3"
                mask="url(#path-5-inside-4_1072_2378)"
            />
            <mask id="path-6-inside-5_1072_2378" fill="white">
                <rect x="10" y="24.4004" width="5.6" height="5.6" rx="0.8" />
            </mask>
            <rect
                x="10"
                y="24.4004"
                width="5.6"
                height="5.6"
                rx="0.8"
                stroke="#666B6E"
                strokeWidth="3"
                mask="url(#path-6-inside-5_1072_2378)"
            />
            <mask id="path-7-inside-6_1072_2378" fill="white">
                <rect x="17.1992" y="24.4004" width="12.8" height="5.6" rx="0.8" />
            </mask>
            <rect
                x="17.1992"
                y="24.4004"
                width="12.8"
                height="5.6"
                rx="0.8"
                stroke="#666B6E"
                strokeWidth="3"
                mask="url(#path-7-inside-6_1072_2378)"
            />
            <rect x="0.5" y="0.5" width="39" height="39" rx="4.5" stroke="#D9DADB" />
        </svg>
    );
}
