import styled from 'styled-components';

export const CurrentVacanciesTitle = styled.h2`
    margin-bottom: 3.2rem;
    @media (max-width: 768px) {
        margin-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 2rem;
    }
`;
export const CurrentVacanciesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    @media (max-width: 480px) {
        gap: 1.6rem;
    }
`;
