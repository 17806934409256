import styled from 'styled-components';
import ImageAlt from '../../../HOC/ImageAlt/ImageAlt';
import { HoverColor, PrimaryColor } from '../../../../styles/colors';

export const Img = styled(ImageAlt)`
    width: 100%;
    height: 100%;
`;

export const ImageBlock2 = styled.div`
    width: 50%;
    @media (max-width: 768px) {
        width: 100%;
        padding-top: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-top: 4.4rem;
    }
`;

export const ContactsTitle = styled.h2`
    margin-bottom: 3.2rem;
    @media (max-width: 768px) {
        margin-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 2rem;
    }
`;

export const ContactsSubtitle = styled.h3`
    margin-bottom: 2rem;
    @media (max-width: 480px) {
        margin-bottom: 1.6rem;
    }
`;

export const ContactsContentTitle = styled.h4`
    margin-bottom: 0.8rem;
    @media (max-width: 480px) {
        margin-bottom: 0.4rem;
    }
`;

export const ContactsContentSubtitle = styled.a`
    color: #666b6e;
`;

export const ContactsContentWrapper = styled.div`
    max-width: 65rem;
    @media (max-width: 480px) {
        max-width: 100%;
    }
`;
export const ContactsBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 31.5rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    @media (max-width: 480px) {
        padding-right: 0;
        padding-bottom: 1.6rem;
        width: 100%;
    }
    a {
        transition: 0.6s ease;
    }
    a[href]:hover {
        transition: 0.6s ease;
        color: ${HoverColor};
        @media (max-width: 768px) {
            color: ${PrimaryColor};
        }
    }
`;
