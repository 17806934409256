import React from 'react';
import { Link } from 'react-router-dom';

export default function LogoFooter() {
    return (
        <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="17.6rem" height="5rem" viewBox="0 0 176 50" fill="none">
                <g clipPath="url(#clip0_322_1816)">
                    <path
                        d="M0 20.4436L19.39 3.64561C24.898 -1.12439 32.92 -1.15439 38.435 3.20861C33.422 1.23261 27.505 2.04361 23.155 5.81161L3.764 22.6096L0 20.4436Z"
                        fill="#FF7100"
                    />
                    <path
                        d="M7.13721 24.5505L26.5272 7.75354C32.0352 2.98254 40.0562 2.95354 45.5722 7.31554C40.5582 5.34054 34.6402 6.15054 30.2902 9.91854L10.9002 26.7165L7.13721 24.5505Z"
                        fill="#FF7100"
                    />
                    <path
                        d="M14.2729 28.658L33.664 11.861C39.171 7.08996 47.192 7.06096 52.708 11.423C47.695 9.44796 41.778 10.258 37.428 14.026L18.037 30.824L14.2729 28.658Z"
                        fill="#FF7100"
                    />
                    <path
                        d="M61.7978 29.5556L42.4068 46.3546C36.8998 51.1246 28.8778 51.1546 23.3618 46.7926C28.3758 48.7676 34.2938 47.9576 38.6428 44.1886L58.0348 27.3906L61.7978 29.5556Z"
                        fill="#C70B6F"
                    />
                    <path
                        d="M54.6621 25.4492L35.2701 42.2482C29.7631 47.0182 21.7421 47.0482 16.2261 42.6852C21.2391 44.6602 27.1561 43.8502 31.5061 40.0822L50.8981 23.2832L54.6621 25.4492Z"
                        fill="#C70B6F"
                    />
                    <path
                        d="M47.5249 21.3418L28.1339 38.1418C22.6259 42.9118 14.6049 42.9418 9.08887 38.5788C14.1019 40.5538 20.0199 39.7438 24.3689 35.9758L43.7609 19.1758L47.5249 21.3418Z"
                        fill="#C70B6F"
                    />
                    <path
                        d="M91.0001 21C91.0001 16.044 88.8061 13 81.4371 13C78.4911 13 75.4401 13.7 73.1381 14.525L73.6441 19.436C75.5791 18.334 78.3421 17.646 80.4141 17.646C83.7301 17.646 84.8811 18.885 84.8811 21.271V22.511C77.0991 22.511 71.6641 25.401 71.6641 30.863C71.6641 34.535 74.0441 37 78.1871 37C81.5961 37 84.5201 34.882 85.6251 32.312L85.5711 32.561C85.3871 33.754 85.0001 35.669 85.0001 37H91.0001V21ZM84.8801 26.87C84.8801 29.624 82.9001 32.377 80.0001 32.377C78.2961 32.377 77.2831 31.414 77.2831 29.945C77.2831 28.11 78.6641 26.32 84.8811 26.32L84.8801 26.87ZM112 35.928L111.528 30.848C110.253 31.501 108.506 31.827 106.9 31.827C102.886 31.827 101.328 29.124 101.328 25.163C101.328 20.783 103.264 17.986 107.088 17.986C108.6 17.986 110.064 18.359 111.339 18.826L111.953 13.652C110.383 13.2233 108.763 13.0041 107.136 13C98.8261 13 95.0001 18.546 95.0001 25.77C95.0001 33.644 99.0611 37 105.578 37C108.081 37 110.395 36.533 112 35.928ZM149 20.125C149 16.185 147.333 13 142.148 13C138.398 13 135.713 14.856 134.324 17.527L134.277 17.482C134.509 16.305 135 14.042 135 13H129V37H135V25.125C135 21.186 137.287 17.845 140.296 17.845C142.472 17.845 143 19.145 143 21.5V37H149V20.125ZM176 24.56C176 16.62 171.88 13 165.498 13C158.662 13 154 18.2 154 25.394C154 33.611 158.119 37 164.502 37C171.247 37 176 31.8 176 24.56ZM170.024 24.884C170.024 29.573 167.852 31.94 165 31.94C161.65 31.94 159.975 29.526 159.975 24.79C159.975 20.056 162.51 18.014 165.09 18.014C168.485 18.014 170.024 20.427 170.024 24.884ZM123 37V13H117V37H123ZM123 6.98C123 5.316 121.642 4 119.981 4C119.191 4.00106 118.433 4.31533 117.875 4.87393C117.316 5.43253 117.001 6.18989 117 6.98C117 8.646 118.32 10 119.981 10C120.781 9.99841 121.549 9.67969 122.114 9.11365C122.68 8.5476 122.999 7.78037 123 6.98Z"
                        fill="#292C2F"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_322_1816">
                        <rect width="176" height="50" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Link>
    );
}
