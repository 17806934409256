import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import {
    PublicationItem,
    PublicationContent,
    Img,
    ImgWrapper,
    PublcationContentTop,
    PublicationTitle,
    PublicationSubtitle,
    PublicationContentDate,
    PublicationContentBottom,
    PublicationContentMore,
    PublicationLinkWrapper,
} from './Publication.styles';
import { FlexRow } from '../../../styles/commons';

export default function Publication({
    img,
    shortText,
    createdAt,
    title,
    link,
    highlightMatches,
    searchValue,
    onlyTitle,
}) {
    return (
        <PublicationLinkWrapper to={link}>
            <PublicationItem>
                <FlexRow alignItems="flex-start" justifyContent="space-between">
                    <ImgWrapper>
                        <Img src={img} alt={title} />
                    </ImgWrapper>
                    <PublicationContent>
                        <PublcationContentTop>
                            {onlyTitle ? (
                                <PublicationTitle to={link}>{title}</PublicationTitle>
                            ) : (
                                <PublicationTitle to={link}>{highlightMatches(title, searchValue)}</PublicationTitle>
                            )}
                            <PublicationSubtitle dangerouslySetInnerHTML={{ __html: shortText }} />
                        </PublcationContentTop>
                        <PublicationContentBottom>
                            <PublicationContentDate>{moment(createdAt).format('DD.MM.YYYY')}</PublicationContentDate>
                            <PublicationContentMore to={link}>
                                Подробнее
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.6rem"
                                    height="1.6rem"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path d="M6 12L10 8L6 4" stroke="#FF7100" strokeWidth="2" />
                                </svg>
                            </PublicationContentMore>
                        </PublicationContentBottom>
                    </PublicationContent>
                </FlexRow>
            </PublicationItem>
        </PublicationLinkWrapper>
    );
}

Publication.defaultProps = {
    highlightMatches: () => {},
    onlyTitle: false,
    searchValue: '',
};

Publication.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    shortText: PropTypes.string.isRequired,
    searchValue: PropTypes.string,
    link: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    highlightMatches: PropTypes.func,
    onlyTitle: PropTypes.bool,
};
