import React from 'react';
import BreadCrumbs from '../../../components/common/BreadCrumbs/BreadCrumbs';
import { GlobalContainer } from '../../../styles/commons';
import { HeaderSlide } from '../../../components/common/HeaderSlide';
import { CompanyInNumbers } from '../../../components/common/CompanyInNumbers';
import { SingleSlide } from '../../../components/common/SingleSlide';
import { content } from '../../../commons/content';
import OurHistoryImage from '../../../assets/images/OurHistoryPage/our-history.png';
import DynamicTitle from '../../../components/molecules/DynamicTitle/DynamicTitle';
import ImgWeb from '../../../assets/images/OurHistoryPage/OurHistoryHeaderWeb.png';
import ImgTab from '../../../assets/images/OurHistoryPage/OurHistoryHeaderTab.png';
import ImgMob from '../../../assets/images/OurHistoryPage/OurHistoryHeaderMob.png';
import GoogleAnalitycs from '../../../components/atoms/GoogleAnalitycs';
import HistorySection from './HistorySection/HistorySection';
import { ContactsSection } from '../../../components/common/Section';

export default function OurHistoryPage() {
    return (
        <>
            <DynamicTitle title="Наша история" />
            <BreadCrumbs colorDefault="#FFFFFF80" colorActive="#fff" />
            <GlobalContainer>
                <HeaderSlide
                    data={{
                        title: content.HEADER_SLIDE_HISTORY_TITLE,
                        subtitle: content.HEADER_SLIDE_HISTORY_SUBTITLE,
                        bgColor: 'linear-gradient(74.31deg, #E0004D 6.33%, #EF7C00 87.76%)',
                    }}
                    images={{ web: ImgWeb, tab: ImgTab, mob: ImgMob }}
                />
                <CompanyInNumbers />
                <SingleSlide
                    imgLeft={OurHistoryImage}
                    title={content.HISTORY_SLIDE_TITLE}
                    text={content.HISTORY_SLIDE_TEXT}
                    backgroundColor="#F0F0F0"
                    border="0.2rem solid #fff"
                    buttonLink="/our-history"
                />
                <HistorySection />
                <ContactsSection backgroundColor="#F0F0F0" />
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
}
