import React from 'react';
import { PropTypes } from 'prop-types';

export default function ResetInputSvg({ onClick }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.2rem"
            height="2.2rem"
            viewBox="0 0 22 22"
            fill="none"
            onClick={onClick}
        >
            <path
                d="M18.1 3.89998C14.2 -2.43187e-05 7.79998 -2.43187e-05 3.89998 3.89998C-2.43187e-05 7.79998 -2.43187e-05 14.2 3.89998 18.1C7.79998 22 14.1 22 18 18.1C21.9 14.2 22 7.79998 18.1 3.89998ZM13.8 15.2L11 12.4L8.19998 15.2L6.79998 13.8L9.59998 11L6.79998 8.19998L8.19998 6.79998L11 9.59998L13.8 6.79998L15.2 8.19998L12.4 11L15.2 13.8L13.8 15.2Z"
                fill="#666B6E"
            />
        </svg>
    );
}

ResetInputSvg.propTypes = {
    onClick: PropTypes.func.isRequired,
};
