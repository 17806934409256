import React from 'react';
import './App.css';
import Layout from 'containers/Layout';
import RouterContainer from 'containers/RouterContainer';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

function App() {
    return (
        <BrowserRouter>
            <HelmetProvider>
                <RouterContainer>
                    <Layout />
                </RouterContainer>
            </HelmetProvider>
        </BrowserRouter>
    );
}

export default App;
