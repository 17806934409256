/* eslint-disable */
import React, { useState } from 'react';
import Router from '../pages/Router';
import { MenuContext, BottomModalContext, BottomCookiesContext } from 'context/context';
import Header from '../components/common/Header/Header';
import Footer from '../components/common/Footer/Footer';

const Layout = () => {
    const [open, setOpen] = useState(false);
    const changeOpenState = () => {
        setOpen(!open);
    };
    const setOpenStateFalse = () => {
        setOpen(false);
    };
    // const location = useLocation();
    const [visible, setVisible] = useState(false);
    const chengeVisibleState = () => {
        setVisible(!visible);
    };

    const [isBottomModal, setIsBottomModal] = useState(false);
    const modalBottomClose = () => {
        setIsBottomModal(false);
    };
    const [isBottomCookiesModal, setIsBottomCookiesModal] = useState(true);
    const modalBottomCookiesClose = () => {
        setTimeout(() => {
            setIsBottomModal(true);
        }, 3000);
        setIsBottomCookiesModal(false);
    };

    return (
        <>
            <MenuContext.Provider
                value={{
                    changeOpenState,
                    open,
                    visible,
                    chengeVisibleState,
                    setOpenStateFalse,
                }}
            >
                <BottomModalContext.Provider
                    value={{
                        isBottomModal,
                        modalBottomClose,
                        isBottomCookiesModal,
                        modalBottomCookiesClose,
                        setIsBottomModal,
                    }}
                >
                    <Header />
                    <Router />
                    <Footer />
                </BottomModalContext.Provider>
            </MenuContext.Provider>
        </>
    );
};

export default Layout;
