import React from 'react';
import PropTypes from 'prop-types';
// import { Row } from 'styles/commons';
import { SearchContainer } from './SearchPanel.styles';

const SearchPanel = ({ children }) => {
    return <SearchContainer>{children}</SearchContainer>;
};

SearchPanel.propTypes = {
    children: PropTypes.objectOf.isRequired,
};

export default SearchPanel;
