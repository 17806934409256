/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { useSearch, usePagination, useSelect } from 'commons/listHooks';
import { v4 as uuidv4 } from 'uuid';
import { content } from '../../commons/content';
import DynamicTitle from '../../components/molecules/DynamicTitle/DynamicTitle';
import { BreadCrumbs } from '../../components/common/BreadCrumbs';
import { HeaderSlide } from '../../components/common/HeaderSlide';
import ImgWeb from '../../assets/images/PromoHubPage/PromoHubHeaderWeb.png';
import ImgTab from '../../assets/images/PromoHubPage/PromoHubHeaderTab.png';
import ImgMob from '../../assets/images/PromoHubPage/PromoHubHeaderMob.png';
import { Container, ContainerFull, GlobalContainer } from '../../styles/commons';

import {
    MatchedLetter,
    ProductsListPageCardNothing,
    ProductsListPageCardNothingText,
    PromohubCategoriesContainer,
    PromohubInfoText,
    PromohubPageContentContainer,
    PromohubPagePaginationContainer,
    PromohubSearchContainer,
} from './PromohubListPage.styles';

import Publication from '../../components/common/Publication/Publication';
import { useSelectCategoriesPromohub } from '../../commons/listHooks';
import PublicationFullSkeleton from '../../components/common/Publication/PublicationFullSkeleton/PublicationFullSkeleton';
import GoogleAnalitycs from '../../components/atoms/GoogleAnalitycs';
import PromohubInfoSvg from './PromohubInfoSvg/PromohubInfoSvg';

export default function PromohubPage() {
    const { renderSearch, searchValue } = useSearch();
    const { renderSelect, categoryValue } = useSelect();
    const { renderSelectCategoriesPromohub, categoryPromohubValue } = useSelectCategoriesPromohub();
    const [loader, setLoader] = useState(true);
    const [publics, setPublics] = useState([]);
    const [video, setVideo] = useState([]);
    const [news, setNews] = useState([]);
    const [newsMeta, setNewsMeta] = useState({});
    const [videosMeta, setVideosMeta] = useState({});
    const [publicsMeta, setPublicsMeta] = useState({});
    const { renderPagination, pageValue, setLastPage, resetPagination } = usePagination(1);
    useEffect(() => {
        setLoader(true);
        async function fetchData() {
            const request = await fetch(
                // eslint-disable-next-line radix
                `${process.env.REACT_APP_BACKEND_URL}/promohub?page=${pageValue}&category=${categoryValue}&term=${searchValue}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                },
            );
            const response = await request.json();
            if (response.data !== undefined) {
                setNews(response.data.news);
                setNewsMeta(response.metadata.news);
                setPublics(response.data.publics);
                setPublicsMeta(response.metadata.publics);
                setVideo(response.data.videos);
                setVideosMeta(response.metadata.videos);
            }
        }
        fetchData();
        setLoader(false);
    }, [searchValue, pageValue, categoryValue, categoryPromohubValue]);

    useEffect(() => {
        resetPagination();
    }, [searchValue, categoryValue, categoryPromohubValue]);

    const highlightMatches = (text, search) => {
        const regex = new RegExp(`(${search})`, 'gi');
        return text.split(regex).map((match) => {
            if (match.toLowerCase() === search.toLowerCase()) {
                return <MatchedLetter>{match}</MatchedLetter>;
            }
            return match;
        });
    };

    const [selectedData, linkPrefix] = useMemo(() => {
        if (categoryPromohubValue === '0') {
            setLastPage(publicsMeta.totalPages);
            return [publics, 'publics'];
        }
        if (categoryPromohubValue === '1') {
            setLastPage(videosMeta.totalPages);
            return [video, 'video'];
        }
        if (categoryPromohubValue === '2') {
            setLastPage(newsMeta.totalPages);
            return [news, 'news'];
        }
        return [[], ''];
    }, [publics, video, news]);
    return (
        <>
            <DynamicTitle title="PromoHub" />
            <BreadCrumbs colorDefault="#FFFFFF80" colorActive="#fff" />
            <GlobalContainer>
                <HeaderSlide
                    data={{
                        title: content.PROMOHUB_SLIDE_CAREER_TITLE,
                        subtitle: content.PROMOHUB_SLIDE_CAREER_SUBTITLE,
                    }}
                    images={{ web: ImgWeb, tab: ImgTab, mob: ImgMob }}
                />
                <ContainerFull paddingBottom="12rem">
                    <Container>
                        <PromohubInfoText>
                            <PromohubInfoSvg />
                            {content.PROMOHUB_LIST_PAGE_INFO}
                        </PromohubInfoText>
                        <PromohubSearchContainer>
                            {renderSearch()}
                            {renderSelect()}
                        </PromohubSearchContainer>
                        <PromohubCategoriesContainer>
                            {renderSelectCategoriesPromohub(publicsMeta.count, videosMeta.count, newsMeta.count)}
                        </PromohubCategoriesContainer>
                        <PromohubPageContentContainer>
                            {loader ? (
                                [...new Array(20)].map((_, i) => <PublicationFullSkeleton key={i} />)
                            ) : selectedData.length > 0 ? (
                                selectedData.map((item) => {
                                    const key = uuidv4();
                                    return (
                                        <Publication
                                            key={key}
                                            img={item.image}
                                            shortText={item.shortText}
                                            createdAt={item.createdAt}
                                            title={item.title}
                                            link={`${linkPrefix}/${item.id}`}
                                            highlightMatches={highlightMatches}
                                            searchValue={searchValue}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    <ProductsListPageCardNothing>Поиск не дал результатов</ProductsListPageCardNothing>
                                    <ProductsListPageCardNothingText>
                                        Пожалуйста, попробуйте использовать другой термин
                                    </ProductsListPageCardNothingText>
                                </>
                            )}
                        </PromohubPageContentContainer>
                        {selectedData && (
                            <PromohubPagePaginationContainer>{renderPagination()}</PromohubPagePaginationContainer>
                        )}
                    </Container>
                </ContainerFull>
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
}
