import React from 'react';
import { Container, ContainerFull, FlexColumn } from '../../../styles/commons';
import {
    CompanyInNumbersContainer,
    CompanyInNumbersItem,
    CompanyInNumbersItemText,
    CompanyInNumbersItemTitle,
    CompanyInNumbersTitle,
} from './CompanyInNumbers.styles';
import { content } from '../../../commons/content';

export default function CompanyInNumbers() {
    return (
        <ContainerFull paddingBottom="12rem">
            <Container>
                <CompanyInNumbersTitle>{content.COMPANY_IN_NUMBERS_TITLE}</CompanyInNumbersTitle>
                <CompanyInNumbersContainer>
                    <CompanyInNumbersItem>
                        <FlexColumn alignItems="flex-start">
                            <CompanyInNumbersItemTitle>
                                {content.COMPANY_IN_NUMBERS_ITEM_TITLE1}
                            </CompanyInNumbersItemTitle>
                            <CompanyInNumbersItemText>{content.COMPANY_IN_NUMBERS_ITEM_TEXT1}</CompanyInNumbersItemText>
                        </FlexColumn>
                    </CompanyInNumbersItem>
                    <CompanyInNumbersItem>
                        <FlexColumn alignItems="flex-start">
                            <CompanyInNumbersItemTitle>
                                {content.COMPANY_IN_NUMBERS_ITEM_TITLE2}
                            </CompanyInNumbersItemTitle>
                            <CompanyInNumbersItemText>{content.COMPANY_IN_NUMBERS_ITEM_TEXT2}</CompanyInNumbersItemText>
                        </FlexColumn>
                    </CompanyInNumbersItem>
                    <CompanyInNumbersItem>
                        <FlexColumn alignItems="flex-start">
                            <CompanyInNumbersItemTitle>
                                {content.COMPANY_IN_NUMBERS_ITEM_TITLE3}
                            </CompanyInNumbersItemTitle>
                            <CompanyInNumbersItemText>{content.COMPANY_IN_NUMBERS_ITEM_TEXT3}</CompanyInNumbersItemText>
                        </FlexColumn>
                    </CompanyInNumbersItem>
                </CompanyInNumbersContainer>
            </Container>
        </ContainerFull>
    );
}
