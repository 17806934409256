import styled from 'styled-components';

export const GlobalNotFound = styled.div`
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
    width: 100%;
    height: 4.56rem;
    @media (max-width: 768px) {
        gap: 1.6rem;
        height: 4.13rem;
    }
    @media (max-width: 480px) {
        gap: 1.6rem;
        height: 5.13rem;
    }
`;
