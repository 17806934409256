import React from 'react';
import PropTypes from 'prop-types';
import { AutocompleteItem, AutocompleteWrapper } from '../../GlobalSearch/styles';

export const Autocomplete = ({ items, onItemClick }) =>
    items && (
        <AutocompleteWrapper>
            {items.map((item) => (
                <AutocompleteItem onClick={() => onItemClick(item)}>{item}</AutocompleteItem>
            ))}
        </AutocompleteWrapper>
    );

Autocomplete.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.arrayOf(PropTypes.object),
    onItemClick: PropTypes.func,
};

Autocomplete.defaultProps = {
    items: [],
    onItemClick: () => {},
};
