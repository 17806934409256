/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-irregular-whitespace */

import React, { useContext } from 'react';
import { BottomModalContext } from 'context/context';
import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
import { Container } from 'styles/commons';
import { ContainerFull, FlexColumn, GlobalContainer } from '../../styles/commons';
import { CookiePageContainer } from '../PoliticsCookie/styles';

const Agreement = () => {
    return (
        <>
            <GlobalContainer>
                <ContainerFull paddingBottom="12rem">
                    <Container>
                        <FlexColumn alignItems="flex-start">
                            <CookiePageContainer>
                                <h1>Юридическая информация</h1>
                                <h2>
                                    Перед тем, как перейти к другим страницам сайта, просим сначала ознакомиться с
                                    юридической информацией, которая носит обязательный характер для Представительство
                                    «Acino Pharma AG» в Республике Беларусь (далее — Acino), входящей в группу компаний
                                    Acino International AG (Швейцария):
                                </h2>
                                <h3>1. Общие условия и требования</h3>
                                <p>
                                    Все сведения, в том числе все изображения и информация, опубликованные на данном
                                    веб-сайте, являются собственностью Представительство «Acino Pharma AG» в Республике
                                    Беларусь (либо одной из компаний входящих в группу Acino International AG),
                                    охраняются законодательством об авторском праве, если не указано иное. Использовать
                                    тексты и файлы (например, пресс-релизы) с данного веб-сайта разрешается только при
                                    соблюдении следующих условий. Пользователям нашего Сайта не разрешается вносить
                                    исправления в документы, использовать компьютерные сети и веб-сайты третьих лиц для
                                    загрузки и копирования документов, а также копировать документы или публиковать их в
                                    других формах публикации. На всех копиях следует полностью указывать принадлежность
                                    авторского права и источники информации. Тем не менее, разрешается использование
                                    данного сайта и содержащихся в нем материалов для некоммерческих и/или личных целей.
                                    Распространение среди пациентов допускается только в том случае, если документ
                                    строго соответствует такой цели, и категорически не разрешается, если документ
                                    предназначен для предоставления рекомендации по назначению лекарственного средства.
                                    Для использования во всех иных случаях, не указанных выше, требуется получение
                                    предварительного письменного согласия Acino.
                                </p>
                                <p>
                                    Пользуясь веб-сайтом компании Acino и услугами, предоставляемыми компанией Acino, Вы
                                    в безусловном порядке принимаете условия их использования, установленные компанией
                                    Acino. Запрещается использование веб-сайта для целей, не предусмотренных прямо
                                    настоящими условиями и требованиями. Несоблюдение данного положения может повлечь
                                    возникновение гражданско-правовой и уголовной ответственности.
                                </p>
                                <p>
                                    Все данные и вся информация на данном веб-сайте имеют общий характер и предназначены
                                    только для личного ознакомления с компанией Acino, ее продукцией и услугами.
                                </p>
                                <p>
                                    Сайты не предназначены для предоставления медицинских или каких-либо иных
                                    рекомендаций и советов относительно продуктов и услуг компании Acino. Для получения
                                    конкретных рекомендаций относительно наших продуктов и услуг просим обращаться
                                    непосредственно в компанию Acino.
                                </p>
                                <p>
                                    Любые сведения, размещенные на данном веб-сайте, не могут толковаться как оферта или
                                    рекламное предложение приобрести акции компании Acino. Данный веб-сайт не является
                                    проспектом эмиссии любого рода в понимании швейцарского обязательственного права или
                                    в качестве публикации, предусмотренной правилами Швейцарской фондовой биржи. Не
                                    следует принимать инвестиционные решения на основе представленной на сайте
                                    информации. Настоятельно рекомендуем инвесторам, перед взятием на себя каких-либо
                                    обязательств, обратиться к профессиональному консультанту.
                                </p>
                                <p>
                                    Некоторые тексты на данном веб-сайте могут содержать заявления или комментарии,
                                    рассчитанные на перспективу. Читатели должны принимать во внимание тот факт, что на
                                    такие заявления и комментарии могут влиять непредвиденные факторы, поэтому их
                                    следует рассматривать только в качестве мнений.
                                </p>
                                <h3>2. Ограниченная ответственность</h3>
                                <p>
                                    Acino всегда стремится предоставлять точную актуальную информацию. Тем не менее,
                                    компания Acino не несет ответственности за какой-либо ущерб, возникший в связи с
                                    информацией, опубликованной на данном веб-сайте. Acino оставляет за собой право
                                    вносить изменения в информацию по своему усмотрению. Соответственно, мы не
                                    предоставляем какие-либо прямо выраженные или подразумеваемые гарантии, а также не
                                    даем какие-либо заверения относительно точности и полноты предоставленной информации
                                    или сведений, на которые мы ссылаемся. Пользователи пользуются веб-сайтом Acino или
                                    любыми иными связанными с этой компанией сайтами, а также информацией, указанной на
                                    нем, на собственный риск. Ни компания Acino, ни иные компании входящие в группу
                                    Acino International AG, ни какие-либо иные лица, связанные с производством,
                                    распространением, созданием или поддержанием работы веб-сайта или его частей или
                                    предложений, не берут на себя ответственность в какой-либо форме за прямые или
                                    косвенные убытки, невзирая на наличие прямой или непрямой вины, которые возникли
                                    вследствие доступа к Интернет-сервисам компании Acino или связанной информации.
                                    Acino не несет ответственность за доступность веб-сайтов третьих лиц или за их
                                    контент. Пользователи пользуются гиперссылками на такие веб-сайты на собственный
                                    риск и не возлагают ответственность на компанию Acino.
                                </p>
                                <p>
                                    Перед применением или использованием нашей продукции следует внимательно
                                    ознакомиться с инструкцией по применению, прилагаемой к каждому препарату. Некоторые
                                    права могут возникать только из руководства пользователя, предоставляемого вместе с
                                    продукцией, но ни в коем случае не из использования информации о продукте,
                                    размещенной на веб-сайте.
                                </p>
                                <h3>3. Информация третьих лиц</h3>
                                <p>
                                    На данном веб-сайте в необходимых случаях содержится информация третьих лиц, а также
                                    размещены ссылки на информацию третьих лиц, зачастую с помощью указателей перехода
                                    на веб-сайты третьих лиц. По возможности информация третьих лиц помечена
                                    соответствующим образом. У компании Acino не возникает право владения какой-либо
                                    информацией или веб-сайтом третьих лиц путем ссылки на них. Acino прямо заявляет о
                                    том, что компания не несет ответственности за контент таких веб-сайтов, и ссылки на
                                    них носят только рекомендательный характер.
                                </p>
                                <h3>4. Изменение контента веб-сайта</h3>
                                <p>
                                    Acino оставляет за собой право вносить в контент сайта изменения без уведомления и в
                                    тех случаях, когда она считает это необходимым. Посетители данного веб-сайта
                                    признают, что у Acino не возникает никакая ответственность по причине внесения
                                    какого бы то ни было изменения.
                                </p>
                                <h3>5. США</h3>
                                <p>
                                    Информация, предоставленная на данном веб-сайте, не предназначена для граждан или
                                    жителей Соединенных Штатов Америки (США) согласно положениям Закона о ценных бумагах
                                    США, и, следовательно, не должна умышленно направляться или распространяться в США.
                                </p>
                                <h3>6. Авторское право, бренды, наименования и дизайн</h3>
                                <p>
                                    Все изображения и информация на данном веб-сайте охраняются законодательством об
                                    авторском праве или иными коммерческими средствами защиты. Дизайн и структура
                                    данного веб-сайта определяются исключительно компанией Acino. Все наименования
                                    продуктов, ссылки заглавными буквами или ссылки, помеченные иным образом на данном
                                    веб-сайте, считаются зарегистрированными товарными знаками компании Acino (или
                                    какой-либо компании входящей в группу Acino International AG). Товарные знаки одних
                                    и тех же продуктов могут отличаться в разных странах.
                                </p>
                                <h3>7. Применимое право</h3>
                                <p>
                                    Посещая данный веб-сайт, вы обязуетесь соблюдать требования действующего
                                    законодательства Республики Беларусь (РБ). Кроме законодательства РБ, компания Acino
                                    руководствуется (в части не противоречащей законодательству РБ) юрисдикцией кантона
                                    Цюрих (Швейцария), т.е. кантона, в котором расположен центральный офис компании
                                    Acino International AG.
                                </p>
                                <p>Copyright© Acino International AG 2007 – 2016</p>
                            </CookiePageContainer>
                        </FlexColumn>
                    </Container>
                </ContainerFull>
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
};

export default Agreement;
