import styled from 'styled-components';
import { SecondaryColor } from '../../../../styles/colors';

export const CompanyValuesTitle = styled.h2`
    margin-bottom: 3.2rem;
    @media (max-width: 768px) {
        margin-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 2rem;
    }
`;
export const CompanyValuesCards = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3.2rem 2rem;
    @media (max-width: 768px) {
        gap: 1.6rem 0;
    }
`;
export const CompanyValuesCardsCard = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 65rem;
    height: auto;
`;
export const CompanyValuesCardsCardImg = styled.div`
    min-width: 6rem;
    min-height: 6rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
export const CompanyValuesCardsCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 3.2rem;
    @media (max-width: 768px) {
        margin-left: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-left: 1.6rem;
    }
`;
export const CompanyValuesCardsCardContentTitle = styled.h3`
    margin-bottom: 1.6rem;
    @media (max-width: 768px) {
        margin-bottom: 0.8rem;
    }
`;
export const CompanyValuesCardsCardContentSubtitle = styled.p`
    width: 55.8rem;
    color: ${SecondaryColor};
    @media (max-width: 480px) {
        width: 100%;
    }
`;
