import styled from 'styled-components';
import { Background, PrimaryColor } from '../../styles/colors';

export const PromohubInfoText = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.9rem;
    padding-bottom: 3.2rem;
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 2rem;
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
    svg {
        margin-right: 1.6rem;
        min-width: 4.6rem;
        min-height: 4.6rem;
    }
`;

export const PromohubSearchContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 480px) {
        flex-direction: column;
    }
`;
export const PromohubPageContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        padding-top: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-top: 2rem;
    }
`;
export const PromohubPagePaginationContainer = styled.div`
    width: 100%;
    padding-top: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3.2rem;
    span {
        min-width: 4rem;
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid #666b6e;
        border-radius: 0.8rem;
        border: 1px solid #d9dadb;
        border-radius: 0.8rem;
    }
    @media (max-width: 768px) {
        padding-top: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-top: 2rem;
    }
`;
export const PromohubCategoriesContainer = styled.div`
    width: 100%;
    padding-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: sticky;
    padding-bottom: 3.2rem;
    top: 10rem;
    background-color: ${Background};
    @media (max-width: 768px) {
        padding-top: 1.6rem;
        padding-bottom: 2.4rem;
        top: 7.7rem;
    }
    @media (max-width: 480px) {
        flex-wrap: nowrap;
        overflow-y: scroll;
        padding-bottom: 2rem;
        top: 6.4rem;
    }
`;

export const MatchedLetter = styled.span`
    color: ${Background};
    background-color: ${PrimaryColor};
    transition: 0.6s ease;
`;

export const ProductsListPageCardNothing = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #292c2f;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding-bottom: 1.6rem;
    }
`;
export const ProductsListPageCardNothingText = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #292c2f;
`;
