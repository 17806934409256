import styled from 'styled-components';

export const ProductsSectionSliderContainer = styled.div`
    position: relative;
    padding-bottom: 6.4rem;
    .products-section-slider-button-prev.swiper-button-disabled svg path {
        cursor: default;
        fill: #666b6e;
    }
    .products-section-slider-button-next.swiper-button-disabled:hover svg path {
        cursor: default;
        fill: #666b6e;
    }

    @media (max-width: 480px) {
    }
    img {
        width: 100%;
        height: 39.8rem;
        object-fit: contain;
        @media (max-width: 768px) {
            height: 27rem;
        }
        @media (max-width: 480px) {
            height: 21.1rem;
        }
    }
`;
export const ProductsSectionSliderContainerInner = styled.div`
    max-width: 132rem;
    margin: 0 auto;
    @media (max-width: 768px) {
        max-width: 76.8rem;
    }
`;

export const ProductsSectionSliderSlideTitle = styled.h3`
    margin-top: 2rem;
    text-transform: lowercase;
    &:first-letter {
        text-transform: uppercase;
    }
    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;
