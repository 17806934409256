import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { HoverColor, PrimaryColor } from '../../../styles/colors';

export const NewsPageContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const PromohubItemContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;
export const PromohubItemContent = styled.div`
    width: 87rem;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const PromohubItemContentTitle = styled.h2`
    padding-bottom: 1.6rem;
`;
export const PromohubItemContenDate = styled.p`
    padding-bottom: 4.4rem;
    color: #666b6e;
    font-size: 1.6rem;
    line-height: 2.1rem;
`;
export const PromohubItemContentImage = styled.div`
    width: 87.3rem;
    height: 58rem;
    margin-bottom: 6.4rem;
    div {
        width: 100% !important;
        height: 100% !important;
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 43rem;
    }
    @media (max-width: 480px) {
        height: 19.2rem;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
`;

export const PromohubItemContentText = styled.div`
    width: 100%;
    height: 100%;
    p {
        a {
            color: #292c2f;
            text-decoration: underline;
            transition: 0.6s ease;
            :hover {
                color: ${HoverColor}!important;
                transition: 0.6s ease;
                @media (max-width: 768px) {
                    color: ${PrimaryColor};
                }
            }
        }
    }
`;

export const Img = styled.img``;

export const StyledPlayer = styled(ReactPlayer)`
    width: 100%;
    height: 100%;
`;
