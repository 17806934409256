/* eslint-disable react/prop-types */

import React from 'react';
import {
    ButtonPopupOpen,
    HeaderSlideContainer,
    HeaderSlideImg,
    HeaderSlideSubtitle,
    HeaderSlideTitleH1,
} from './HeaderSlide.styles';
import Button from '../Button/Button';

const HeaderSlide = ({ images, data, button }) => {
    const { type, onClick, link, text, styles } = button || {};
    const { title, subtitle, bgColor } = data || {};
    return (
        <HeaderSlideImg images={images} backgroundColor={bgColor}>
            <HeaderSlideContainer>
                {title && <HeaderSlideTitleH1>{title}</HeaderSlideTitleH1>}
                {subtitle && <HeaderSlideSubtitle>{subtitle}</HeaderSlideSubtitle>}
                {type === 'button' && <ButtonPopupOpen onClick={onClick}>{text}</ButtonPopupOpen>}
                {type === 'link' && (
                    <Button link={link} title={text} backgroundcolorbutton={styles.bgColor} border={styles.border} />
                )}
                {!type && ''}
            </HeaderSlideContainer>
        </HeaderSlideImg>
    );
};

export default HeaderSlide;
