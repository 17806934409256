import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Background, HoverColor, PrimaryColor } from '../../styles/colors';
import { ButtonContainer, ButtonItem } from '../../components/common/Button/Button.styles';

export const ProductsListPageTitle = styled.h2`
    padding-bottom: 2rem;
    @media (max-width: 768px) {
        padding-bottom: 1.6rem;
    }
`;

export const ProductsListPageSearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ProductsListPageCardsSwitcher = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 8.8rem;
    margin-left: 12.6rem;
    @media (max-width: 480px) {
        display: none;
    }
`;
export const ProductsListPageCardsColumnButton = styled.div`
    cursor: pointer;
    &.active {
        svg {
            & > rect:first-child {
                fill: ${PrimaryColor};
                transition: 0.6s ease;
            }
            rect {
                stroke: ${Background};
                transition: 0.6s ease;
            }
        }
    }
    svg {
        & > rect:first-child {
            transition: 0.6s ease;
        }
        rect {
            transition: 0.6s ease;
        }
    }
    :hover {
        svg {
            & > rect:first-child {
                fill: ${HoverColor};
                transition: 0.6s ease;
                @media (max-width: 768px) {
                    fill: ${PrimaryColor};
                }
            }
            rect {
                stroke: ${Background};
                transition: 0.6s ease;
            }
        }
    }
`;
export const ProductsListPageCardsRowButton = styled.div`
    cursor: pointer;
    &.active {
        svg {
            & > rect:first-child {
                fill: ${PrimaryColor};
                transition: 0.6s ease;
            }
            rect {
                stroke: ${Background};
                transition: 0.6s ease;
            }
        }
    }
    svg {
        & > rect:first-child {
            transition: 0.6s ease;
        }
        rect {
            transition: 0.6s ease;
        }
    }
    :hover {
        svg {
            & > rect:first-child {
                fill: ${HoverColor};
                transition: 0.6s ease;
                @media (max-width: 768px) {
                    fill: ${PrimaryColor};
                }
            }
            rect {
                stroke: ${Background};
                transition: 0.6s ease;
            }
        }
    }
    svg {
        & > rect:first-child {
            fill: ${(props) => (props.active ? '#FF7100' : '#fff')};
        }
    }
`;
export const ProductsListPageCategories = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: sticky;
    top: 10rem;
    background-color: ${Background};
    z-index: 8;
    padding-top: 2rem;
    padding-bottom: 3.2rem;
    @media (max-width: 768px) {
        padding-top: 1.6rem;
        top: 7.7rem;
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        flex-wrap: nowrap;
        overflow-y: scroll;
        margin-bottom: 2rem;
        top: 6.4rem;
        padding-bottom: 2rem;
    }
`;
export const ProductsListPageAlphabet = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: ${PrimaryColor};
    transition: 0.6s ease;
    svg {
        margin-left: 0.4rem;
        transition: 0.6s ease;
        transform: ${({ isSvgRotated }) => (isSvgRotated === true ? 'rotate(180deg)' : '')};
    }
`;

export const ProductsListPageLettersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 110.5rem;
    @media (max-width: 768px) {
        max-width: 61.1rem;
    }
`;
export const ProductsListPageLetters = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
export const ProductsListPageCards = styled.div`
    width: 100%;
    padding-top: 6.4rem;
    display: flex;
    align-items: center;
    gap: ${({ displayMode }) => (displayMode === 'column' ? '2rem 0' : '1.9rem')};
    flex-direction: ${({ displayMode }) => (displayMode === 'column' ? 'column' : 'row')};
    flex-wrap: ${({ displayMode }) => (displayMode === 'column' ? 'nowrap' : 'wrap')};
    & > * {
        flex-grow: ${({ displayMode }) => (displayMode === 'column' ? '0' : '1')};
    }
    @media (max-width: 768px) {
        gap: ${({ displayMode }) => (displayMode === 'column' ? '2.4rem 0' : '1.9rem')};
    }
    @media (max-width: 480px) {
        padding-top: 4.4rem;
    }
`;
export const ProductsListPageLettersReset = styled.div`
    cursor: pointer;
    width: 10.6rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Background};
    background-color: ${PrimaryColor};
    text-transform: capitalize;
    margin-top: 0.8rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    border-radius: 0.8rem;
    &:hover {
        transition: 0.6s ease;
        background-color: ${HoverColor};
        color: ${Background};
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
        }
    }
`;
export const ProductsListPageCardImg = styled.div`
    min-width: 42.7rem;
    min-height: 42.7rem;
    padding-right: ${({ displayMode }) => (displayMode === 'column' ? '2rem' : '0')};
    display: flex;
    align-items: center;
    justidy-content: center;
    @media (max-width: 768px) {
        min-width: 27.5rem;
        min-height: 23.5rem;
        padding-right: ${({ displayMode }) => (displayMode === 'column' ? '4rem' : '0')};
    }
    @media (max-width: 480px) {
        min-width: 28.8rem;
        min-height: 23.5rem;
        padding-right: 0;
        padding-bottom: 2rem;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
`;

export const ProductsListPageCardContent = styled.div`
    min-width: ${({ displayMode }) => (displayMode === 'column' ? '87.3rem' : '100%')};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-basis: 100%;
    @media (max-width: 768px) {
        min-width: ${({ displayMode }) => (displayMode === 'column' ? '31.4rem' : '100%')};
    }
    @media (max-width: 480px) {
        min-width: 100%;
    }
    & > div {
        display: flex;
        align-items: flex-start;
        & > div {
            padding-bottom: ${({ displayMode }) => (displayMode === 'column' ? '6.4rem' : '3.2rem')};
            @media (max-width: 768px) {
                padding-bottom: ${({ displayMode }) => (displayMode === 'column' ? '1.6rem' : '2.4rem')};
            }
            @media (max-width: 480px) {
                padding-bottom: 1.6rem;
            }
        }
        p {
            br {
                padding-bottom: 0.8rem;
            }
        }
    }
    div:last-child {
        display: flex;
        align-items: flex-start;
        padding-bottom: 0;
    }
`;
export const ProductsListPageCardContentTitle = styled(Link)`
    font-size: 2.4rem;
    line-height: 2.9rem;
    font-weight: 550;
    display: flex;
    text-align: left;
    padding-bottom: 1.25rem;
    width: 100%;
    flex-wrap: wrap;
    transition: 0.6s ease;
    @media (max-width: 768px) {
        padding-bottom: 1.6rem;
        font-size: 1.8rem;
    }
    @media (max-width: 480px) {
        font-size: 1.8rem
        padding-bottom: 1.6rem;
    }
    :hover {
        color: ${HoverColor};
        transition: 0.6s ease;
        @media (max-width: 768px) {
            color: ${PrimaryColor};
        }
    }
`;
export const ProductsListPageCardContentInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
export const ProductsListPageCardContentButtonsContainer = styled.div`
    @media (max-width: 768px) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0 0.8rem;
        & > a {
            width: 50%;
            height: 5rem;
        }
        & > div {
            width: 50%;
            a {
                padding: 1.6rem 3.2rem;
                margin-bottom: 0;
            }
        }
    }
    @media (max-width: 480px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0 2rem;
        & > div {
            width: 50%;
            height: 100%;
            a {
                padding: 1.6rem 1.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        & > a {
            width: 50%;
        }
    }
    width: 100%;
    a:first-child {
        margin-right: 1.6rem;
        @media (max-width: 768px) {
            margin-right: 0;
        }
        @media (max-width: 480px) {
            margin-bottom: 0;
        }
    }
`;
export const ProductsListPageCardsPagination = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3.2rem;
    span {
        min-width: 4rem;
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid #666b6e;
        border-radius: 0.8rem;
        border: 1px solid #d9dadb;
        border-radius: 0.8rem;
    }
    @media (max-width: 768px) {
        padding-top: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-top: 2rem;
    }
`;

export const ProductsListPageCardNothing = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #292c2f;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding-bottom: 1.6rem;
    }
`;
export const ProductsListPageCardNothingText = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #292c2f;
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
`;

export const ProductsListPageLetter = styled.div`
    cursor: pointer;
    text-transform: uppercase;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    line-height: 3.6rem;
    border-radius: 0.8rem;
    border: 0.1rem solid #d9dadb;
    transition: 0.6s ease;
    background-color: ${({ selected }) => (selected ? '#FF7100' : '#fff')};
    color: ${({ selected }) => (selected ? '#fff' : '#666B6E')};
    margin-right: 0.8rem;
    margin-top: 0.8rem;
    &:hover {
        transition: 0.6s ease;
        background-color: ${HoverColor};
        border: 0.1rem solid ${HoverColor};
        color: ${Background};
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
            border: 0.1rem solid ${PrimaryColor};
        }
    }
    @media (max-width: 768px) {
        width: 3.7rem;
        height: 3.7rem;
        font-size: 2rem;
        line-height: 3rem;
        margin-right: 0.3rem;
    }
`;

export const ButtonImg = styled.a`
    width: 16.8rem;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3eb75d;
    @media (max-width: 480px) {
        height: 4.8rem;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
export const MatchedLetter = styled.span`
    color: ${Background};
    background-color: ${PrimaryColor};
    transition: 0.6s ease;
`;

export const ProductsListPageCard = styled.div`
    display: flex;
    flex-direction: ${({ displayMode }) => (displayMode === 'column' ? 'row' : 'column')};
    align-items: flex-start;
    justify-content: space-between;
    max-width: ${({ displayMode }) => (displayMode === 'column' ? '100%' : '42.7rem')};
    flex-basis: ${({ displayMode }) => (displayMode === 'column' ? '' : '100%')};
    align-self: ${({ displayMode }) => (displayMode === 'column' ? '' : 'stretch')};
    @media (max-width: 768px) {
        max-width: ${({ displayMode }) => (displayMode === 'column' ? '100%' : '31.4rem')};
    }
    @media (max-width: 480px) {
        max-width: 100%;
        flex-direction: column;
    }
    &:hover {
         ${ProductsListPageCardContent} ${ProductsListPageCardContentTitle} {
            color: ${HoverColor};
            transition: 0.6s ease;
        }
         ${ProductsListPageCardContent} ${ProductsListPageCardContentInner} ${ProductsListPageCardContentButtonsContainer} ${ButtonContainer} ${ButtonItem} {
            background-color: ${HoverColor};
            transition: 0.6s ease;
        }
    }
`;
