/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable */
import React from 'react';
import pdf_icon from 'assets/images/pdf_icon.png';
import { content } from 'commons/content';
import { ButtonContainer, Icon, Text } from './DownloadPdfButton.styles';

const DownloadPdfButton = (link) => {
    return (
        <ButtonContainer href={link.link}>
            <Icon src={pdf_icon} />
            <Text>{content.CATALOG_PDF_BUTTON}</Text>
        </ButtonContainer>
    );
};

export default DownloadPdfButton;
