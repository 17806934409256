/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
    ProductsListPageCard,
    ProductsListPageCardContent,
    ProductsListPageCardContentButtonsContainer,
    ProductsListPageCardContentInner,
    ProductsListPageCardContentTitle,
    ProductsListPageCardImg,
} from '../../../pages/ProductsListPage/ProductsListPage.styles';
import { PublicationContentBottom, PublicationContentMore } from '../../common/Publication/Publication.styles';
import { MatchedLetter } from '../styles';
import {
    ProductLinkWrapper,
    ProductSearchCardContainer,
    ProductSearchCardContent,
    ProductSearchCardContentBottom,
    ProductSearchCardImage,
} from './styles';

const SingleSearchProduct = ({ item, term }) => {
    const highlightMatches = (text, search) => {
        const regex = new RegExp(`(${search})`, 'gi');
        return text.split(regex).map((match) => {
            if (match.toLowerCase() === search.toLowerCase()) {
                return <MatchedLetter>{match}</MatchedLetter>;
            }
            return match;
        });
    };
    return (
        <ProductLinkWrapper to={`products/${item.cpu}`}>
            <ProductSearchCardContainer displayMode="column">
                <ProductSearchCardImage displayMode="column">
                    <img src={item.img1} alt={item.title} />
                </ProductSearchCardImage>

                <ProductSearchCardContent displayMode="column">
                    <div>
                        <ProductsListPageCardContentTitle to={`products/${item.cpu}`} data={item}>
                            {highlightMatches(item.title, term)}
                        </ProductsListPageCardContentTitle>
                    </div>
                    <ProductsListPageCardContentInner>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: item.short_text,
                            }}
                        />
                        <ProductSearchCardContentBottom>
                            <PublicationContentMore to={`products/${item.cpu}`}>
                                Подробнее
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.6rem"
                                    height="1.6rem"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path d="M6 12L10 8L6 4" stroke="#FF7100" strokeWidth="2" />
                                </svg>
                            </PublicationContentMore>
                        </ProductSearchCardContentBottom>
                    </ProductsListPageCardContentInner>
                </ProductSearchCardContent>
            </ProductSearchCardContainer>
        </ProductLinkWrapper>
    );
};

export default SingleSearchProduct;
