/* eslint-disable prettier/prettier */
import React from 'react';
import { MissionSectionWrapper } from './MissionSection.styles';
import MissionSlider from './MissionSlider/MissionSlider';
import { ContainerFull } from '../../../../styles/commons';

export const MissionSection = () => {
    return (
        <ContainerFull>
            <MissionSectionWrapper>
                <MissionSlider />
            </MissionSectionWrapper>
        </ContainerFull>
    );
};

export default MissionSection;
