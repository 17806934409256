import React from 'react'; // , { useEffect, useState }
import { Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { ContainerFull, GlobalContainer, Container } from '../../../styles/commons';
// import { fetchpromohubId } from '../../services/NewsService';
import {
    StyledPlayer,
    PromohubItemContainer,
    PromohubItemContent,
    PromohubItemContentTitle,
    PromohubItemContentText,
    PromohubItemContentImage,
    PromohubItemContenDate,
} from './PromohubSingle.styles';
import { BreadCrumbs } from '../BreadCrumbs';
import { ImageAlt } from '../../HOC/ImageAlt';
import { PromohubSimilarItems } from './PromohubSimilarItems';
import DynamicTitle from '../../molecules/DynamicTitle/DynamicTitle';

// import DynamicTitle from '../../../components/molecules/DynamicTitle/DynamicTitle';
import PromohubSingleSkeleton from './PromohubSingleSkeleton/PromohubSingleSkeleton';
import GoogleAnalitycs from '../../atoms/GoogleAnalitycs';
import { PromohubInfoText } from '../../../pages/PromohubPage/PromohubListPage.styles';
import PromohubInfoSvg from '../../../pages/PromohubPage/PromohubInfoSvg/PromohubInfoSvg';
import { content } from '../../../commons/content';

const PromohubSingle = ({ promohub, loader }) => {
    const dateParts = promohub.createdAt ? promohub.createdAt.split('-') : '';
    const formattedDate = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
    return (
        <>
            <DynamicTitle
                title={promohub.title}
                description={promohub && promohub.description}
                keywords={promohub && promohub.keywords}
            />
            {promohub ? <BreadCrumbs value={promohub.title} /> : <BreadCrumbs />}
            {!promohub && <Redirect to="/error" />}
            <GlobalContainer>
                <ContainerFull paddingBottom="12rem">
                    <Container>
                        {loader ? (
                            <PromohubSingleSkeleton />
                        ) : (
                            <PromohubItemContainer>
                                <PromohubItemContent>
                                    <PromohubInfoText>
                                        <PromohubInfoSvg />
                                        {content.PROMOHUB_LIST_PAGE_INFO}
                                    </PromohubInfoText>
                                    <PromohubItemContentTitle>{promohub.title}</PromohubItemContentTitle>
                                    <PromohubItemContenDate>{formattedDate}</PromohubItemContenDate>
                                    {promohub.videoLink ? (
                                        <PromohubItemContentImage>
                                            <StyledPlayer
                                                url={promohub.videoLink}
                                                width="100%"
                                                controls="true"
                                                config={{
                                                    youtube: {
                                                        playerVars: { modestbranding: 0 },
                                                    },
                                                }}
                                            />
                                        </PromohubItemContentImage>
                                    ) : (
                                        <PromohubItemContentImage>
                                            <ImageAlt src={promohub.image} alt={promohub.image} width="100%" />
                                        </PromohubItemContentImage>
                                    )}
                                    <PromohubItemContentText dangerouslySetInnerHTML={{ __html: promohub.text }} />
                                </PromohubItemContent>
                                <PromohubSimilarItems id={promohub.id} />
                            </PromohubItemContainer>
                        )}
                    </Container>
                </ContainerFull>
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
};

export default PromohubSingle;

PromohubSingle.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    promohub: PropTypes.arrayOf(PropTypes.any).isRequired,
    loader: PropTypes.bool.isRequired,
};
