/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useContext } from 'react';
import { BottomModalContext } from 'context/context';

import { Container, Row } from 'styles/commons';
import { Button } from '../../components/common/Button';
import {
    ErrorPageContainer,
    ErrorPageContainerTitle,
    ErrorPageContainerSubtitle,
    ErrorPageContainerText,
} from './ErrorPage.styles';
import DynamicTitle from '../../components/molecules/DynamicTitle/DynamicTitle';
import { ContainerFull, GlobalContainer } from '../../styles/commons';

const ErrorPage = () => {
    const { isBottomModal, modalBottomClose } = useContext(BottomModalContext);
    const { isBottomCookiesModal, modalBottomCookiesClose } = useContext(BottomModalContext);
    return (
        <>
            <DynamicTitle title="Страница не найдена" description="Страница не найдена" />
            <GlobalContainer>
                <ContainerFull paddingBottom="12rem">
                    <Container>
                        <ErrorPageContainer>
                            <ErrorPageContainerTitle>404</ErrorPageContainerTitle>
                            <ErrorPageContainerSubtitle>Страница не найдена</ErrorPageContainerSubtitle>
                            <ErrorPageContainerText>
                                Извините, запрашиваемая вами страница не существует. Проверьте правильность написания
                                адреса или перейдите на главную страницу.
                            </ErrorPageContainerText>
                            <Button title="Вернуться на главную" link="/" />
                        </ErrorPageContainer>
                    </Container>
                </ContainerFull>
            </GlobalContainer>
        </>
    );
};

export default ErrorPage;
