import styled from 'styled-components';
import { HoverColor, PrimaryColor } from '../../../../styles/colors';

export const FooterItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 8.1rem;
    @media (max-width: 768px) {
        margin-left: 0;
    }
    a {
        max-width: 26rem;
        margin-bottom: 2rem;
        transition: 0.6s ease;
        :hover {
            transition: 0.6s ease;
            color: ${HoverColor};
            @media (max-width: 768px) {
                color: ${PrimaryColor};
            }
        }
        @media (max-width: 768px) {
            max-width: 23rem;
            margin-bottom: 1.6rem;
        }
        @media (max-width: 480px) {
            margin-bottom: 0.8rem;
        }
    }
    h4 {
        cursor: default;
        max-width: 20.2rem;
        margin-bottom: 2rem;
        transition: 0.6s ease;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 400;
        @media (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 2.1rem;
            margin-bottom: 0.8rem;
        }
    }
    & > a:last-child {
        margin-bottom: 0;
    }
    & > div a {
        margin-bottom: 0;
    }
`;

export const FooterItemTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    svg {
        margin-bottom: 3.2rem;
        @media (max-width: 768px) {
            margin-bottom: 0;
            min-width: 13rem;
            min-height: 3.6rem;
            width: 13rem;
            height: 3.6rem;
            margin-bottom: 2.4rem;
        }
        @media (max-width: 480px) {
            width: 11.6rem;
            height: 3.2rem;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    @media (max-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 4.4rem;
    }
    @media (max-width: 480px) {
        flex-direction: column;
        padding-bottom: 3.2rem;
        & > a:last-child {
            display: block;
            margin-bottom: 0;
        }
    }
    & > a {
        transition: 0.6s ease;
        @media (max-width: 768px) {
            margin-bottom: 2.4rem;
            :hover {
                transition: 0.6s ease;
                color: ${PrimaryColor};
            }
        }
        @media (max-width: 480px) {
            margin-left: 0;
            margin-bottom: 0;
        }
        :hover {
            transition: 0.6s ease;
            color: ${HoverColor};
        }
    }
`;

export const FooterInfo = styled.div`
    width: 33.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    @media (max-width: 768px) {
        width: 49.3rem;
        margin-left: 2.4rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }
    a {
        transition: 0.6s ease;
        :hover {
            transition: 0.6s ease;
            color: ${HoverColor};
            @media (max-width: 768px) {
                stroke: ${PrimaryColor};
            }
        }
    }
    @media (max-width: 480px) {
        margin-left: 0;
        padding-top: 2rem;
        width: 100%;
        height: 100%;
    }
`;
export const FooterLinks = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
        width: 2.4rem;
        height: 2.4rem;
        svg {
            min-width: 2.4rem;
            min-height: 2.4rem;
            width: 2.4rem;
            height: 2.4rem;
        }
    }
    @media (max-width: 480px) {
        margin-left: 0;
    }
`;
export const FooterNav = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
        margin-left: 15.5rem;
    }
    @media (max-width: 480px) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 3.2rem 0;
        margin-left: 0;
    }
`;
export const FooterLink = styled.a`
    margin-right: 1.2rem;
    svg {
        margin-bottom: 0;
        path {
            transition: 0.6s ease;
        }
    }
    :hover {
        svg {
            path {
                transition: 0.6s ease;
                stroke: ${HoverColor};
                @media (max-width: 768px) {
                    stroke: ${PrimaryColor};
                }
            }
        }
    }
`;
