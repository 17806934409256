import React from 'react';
import { PropTypes } from 'prop-types';
import { WeOfferContainerItem, WeOfferText } from '../WeOffer.styles';
import { FlexColumn } from '../../../../../styles/commons';

export default function WeOfferItem({ img, text, border }) {
    return (
        <WeOfferContainerItem border={border}>
            <FlexColumn justify-content="center" alignItems="center">
                <img src={img} alt={text} />
                <WeOfferText>{text}</WeOfferText>
            </FlexColumn>
        </WeOfferContainerItem>
    );
}

WeOfferItem.propTypes = {
    img: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
};
