import { get } from 'commons/https';

export const fetchAll = (searchValue = '', sortOrder = 'desc', page = 1) => {
    return get(
        `/get-all-news.php?page=${page}&sortOrder=${sortOrder}${
            searchValue.trim() !== '' ? `&search=${searchValue}` : ''
        }`,
    );
};

export const fetch = (id) => {
    return get(`/get-news.php?id=${id}`);
};
export const fetchAllVideo = (searchValue = '', sortOrder = 'desc', page = 1, categoryValue = '') => {
    return get(
        `/get-all-video.php?page=${page}&category=${categoryValue || 0}&sortOrder=${sortOrder}${
            searchValue.trim() !== '' ? `&search=${searchValue}` : ''
        }`,
    );
};
export const fetchAllNews = (searchValue = '', sortOrder = 'desc', page = 1, categoryValue = '') => {
    return get(
        `/get-all-news.php?page=${page}&category=${categoryValue || 0}&sortOrder=${sortOrder}${
            searchValue.trim() !== '' ? `&search=${searchValue}` : ''
        }`,
    );
};

export const fetchVideoId = (id) => {
    return get(`/get-video.php?id=${id}`);
};

export const fetchAllPublics = (searchValue = '', sortOrder = 'desc', page = 1, categoryValue = '') => {
    return get(
        // `/get-all-publics.php?page=${page}&sortOrder=${sortOrder}${
        //     searchValue.trim() !== '' ? `&search=${searchValue}` : ''
        // }`,
        `/get-all-publics.php?page=${page}&category=${categoryValue || 0}&sortOrder=${sortOrder}${
            searchValue.trim() !== '' ? `&search=${searchValue}` : ''
        }`,
    );
};

export const fetchPublicsId = (id) => {
    return get(`/get-publics.php?id=${id}`);
};

export const fetchAllEvents = (searchValue = '', sortOrder = 'desc', page = 1) => {
    return get(
        `/get-all-events.php?page=${page}&sortOrder=${sortOrder}${
            searchValue.trim() !== '' ? `&search=${searchValue}` : ''
        }`,
    );
};

export const fetchEventsId = (id) => {
    return get(`/get-events.php?id=${id}`);
};

export const fetchAllCatalog = (searchValue = '', sortOrder = 'desc', page = 1, categoryValue = '') => {
    return get(
        // `/get-all-catalog.php?page=${page}&sortOrder=${sortOrder}${
        //     searchValue.trim() !== '' ? `&search=${searchValue}` : ''
        // }`,
        `/get-all-catalog.php?page=${page}&category=${categoryValue || 0}&sortOrder=${sortOrder}${
            searchValue.trim() !== '' ? `&search=${searchValue}` : ''
        }`,
    );
};

export const fetchCatalogId = (cpu) => {
    return get(`/get-catalog.php?cpu=${cpu}`);
};

export const fetchSearchData = ({ term, limit }) =>
    get(`${process.env.REACT_APP_BACKEND_URL}/search?page=1&limit=${limit}&term=${term}`);

export const fetchSearchAutocompleteData = ({ term }) =>
    get(`${process.env.REACT_APP_BACKEND_URL}/search/autocomplete?term=${term}`);

export const fetchPromohubData = () => {
    get(`${process.env.REACT_APP_BACKEND_URL}/promohub`);
};
