import React from 'react';
import { PropTypes } from 'prop-types';

export default function MenuCloseButton({ changeOpenState }) {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            changeOpenState();
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <div className="close-nav" onClick={changeOpenState} onKeyDown={handleKeyDown} role="button" tabIndex={0}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.5rem" viewBox="0 0 24 25" fill="none">
                <path
                    d="M18.3602 20.2797L12.0002 13.9097L5.64021 20.2797L4.22021 18.8597L10.5902 12.4997L4.22021 6.13973L5.64021 4.71973L12.0002 11.0897L18.3602 4.72973L19.7702 6.13973L13.4102 12.4997L19.7702 18.8597L18.3602 20.2797Z"
                    fill="#FF7100"
                />
            </svg>
        </div>
    );
}

MenuCloseButton.propTypes = {
    changeOpenState: PropTypes.func.isRequired,
};
