import styled from 'styled-components';
import { HoverColor, PrimaryColor } from '../../../styles/colors';

export const PopupCareerFormContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
        &.close-popup {
            cursor: pointer;
            position: absolute;
            top: 3.2rem;
            right: 3.2rem;
            @media (max-width: 768px) {
                top: 1.6rem;
                right: 1.6rem;
            }
            svg {
                path {
                    transition: 0.6s ease;
                    fill: ${PrimaryColor};
                }
            }
            :hover {
                svg {
                    path {
                        transition: 0.6s ease;
                        fill: ${HoverColor};
                        @media (max-width: 768px) {
                            fill: ${PrimaryColor};
                        }
                    }
                }
            }
        }
    }
`;
export const PopupCareerFormBackground = styled.div`
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
`;
export const PopupCareerFormContent = styled.div`
    overflow-y: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 77.8rem;
    heigth: 62.6rem;
    background-color: #ffffff;
    padding: 6.4rem;
    @media (max-width: 768px) {
        width: 64.8rem;
        height: 59rem;
        padding: 4.4rem;
    }
    @media (max-width: 480px) {
        width: 100%;
        height: 100%;
        padding: 10rem 1.6rem 4.4rem 1.6rem;
        overflow-y: scroll;
    }
`;
export const PopupCareerFormTitle = styled.h2`
    padding-bottom: 1.6rem;
`;
export const PopupCareerFormSubtitle = styled.p`
    padding-bottom: 3.2rem;
    @media (max-width: 768px) {
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 2rem;
    }
`;
export const PopupCareerForm = styled.form`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem 0;
    & > div:not(:last-child) {
        flex-basis: calc(50% - 1rem);
        @media (max-width: 480px) {
            flex-basis: 100%;
        }
        position: relative;
         {
            div {
                position: absolute;
            }
        }
    }
`;
export const PopupCareerFormInput = styled.input`
    border: 0;
    padding: 1.6rem;
    padding-left: 0;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #666b6e;
    border-bottom: 0.1rem solid #d9dadb;
    transition: 0.6s ease;
    width: 100%;
    @media (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
    :hover {
        transition: 0.6s ease;
        border-bottom: 0.1rem solid ${HoverColor};
        @media (max-width: 768px) {
            border-bottom: 0.1rem solid ${PrimaryColor};
        }
    }
    :focus {
        outline: 0;
        transition: 0.6s ease;
        border-bottom: 0.1rem solid ${PrimaryColor};
    }
`;
export const PopupCareerFormSubmit = styled.button`
    cursor: pointer;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;
    color: #ffffff;
    padding: 2rem 5.6rem;
    background-color: ${PrimaryColor};
    transition: 0.6s ease;
    border: 0;
    outline: 0;
    margin-top: 6.4rem;
    border-radius: 3.2rem;
    @media (max-width: 768px) {
        margin-top: 4.4rem;
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    @media (max-width: 480px) {
        display: block;
        width: 100%;
        font-size: 1.6rem;
        line-height: 1.6rem;
        padding: 1.6rem 7.9rem;
    }
    :hover {
        background-color: ${HoverColor};
        transition: 0.6s ease;
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
        }
    }
    :focus {
        background-color: ${PrimaryColor};
        transition: 0.6s ease;
    }
`;
export const PopupCareerFormConfirmationText = styled.p`
    padding-top: 1.6rem;
    a {
        font-size: inherit;
        line-height: inherit;
        color: ${PrimaryColor};
        transition: 0.6s ease;
        :hover {
            transition: 0.6s ease;
            color: ${HoverColor};
            @media (max-width: 768px) {
                color: ${PrimaryColor};
            }
        }
    }
`;
