import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HoverColor, PrimaryColor } from '../../../styles/colors';

export const PublicationItem = styled.li`
    list-style-type: none;
    width: 100%;
    border-bottom: 0.1rem solid #d9dadb;
    padding: 4.8rem 0;

    @media (max-width: 768px) {
        padding: 2.8rem 0;
    }
    @media (max-width: 480px) {
        height: auto;
    }
`;
export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
export const ImgWrapper = styled.div`
    width: 42.7rem;
    height: 21.2rem;
    margin-right: 2rem;
    @media (max-width: 768px) {
        width: 31.4rem;
        height: 18.9rem;
    }
    @media (max-width: 480px) {
        width: 28.8rem;
        height: 17.4rem;
        margin-right: 0;
    }
`;

export const PublicationContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @media (max-width: 480px) {
        height: auto;
    }
`;
export const PublcationContentTop = styled.div`
    width: 87.3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 31.4rem;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;
export const PublicationTitle = styled(Link)`
    font-weight: 550;
    font-style: normal;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #292c2f;
    margin-bottom: 2rem;
    transition: 0.6s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    :hover {
        color: ${HoverColor};
        transition: 0.6s ease;
        @media (max-width: 768px) {
            color: ${PrimaryColor};
        }
    }
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 1.6rem;
    }
    @media (max-width: 480px) {
        width: 100%;
        margin-top: 1.6rem;
    }
`;
export const PublicationSubtitle = styled.p`
    color: #292c2f;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    @media (max-width: 480px) {
        margin-bottom: 2rem;
    }
`;
export const PublicationContentBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const PublicationContentDate = styled.h4`
    color: #666b6e;
`;
export const PublicationContentMore = styled(Link)`
    font-size: 2rem;
    line-height: 2.6rem;
    transition: 0.6s ease;
    display: flex;
    align-items: center;
    color: ${PrimaryColor};
    @media (max-width: 768px) {
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
    svg {
        margin-left: 0.4rem;
        path {
            transition: 0.6s ease;
        }
    }
    :hover {
        transition: 0.6s ease;
        color: ${HoverColor};
        @media (max-width: 768px) {
            color: ${PrimaryColor};
        }
        svg {
            path {
                transition: 0.6s ease;
                stroke: ${HoverColor};
                @media (max-width: 768px) {
                    stroke: ${PrimaryColor};
                }
            }
        }
    }
`;

export const PublicationLinkWrapper = styled(Link)`
    display: flex;
    &:hover {
        ${PublicationItem} div ${PublicationContent} ${PublcationContentTop} ${PublicationTitle} {
            color: ${HoverColor};
            transition: 0.6s ease;
        }
        ${PublicationItem} div ${PublicationContent} ${PublicationContentBottom} ${PublicationContentMore} {
            transition: 0.6s ease;
        color: ${HoverColor};
        }
    }

    &:last-child li {
        border: none;
    }

    &:first-child li {
        padding-top: 0;
    }
`;
