/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { content } from 'commons/content';
import { PropTypes } from 'prop-types';
import { Input, SearchInputContainer, InputItem } from './SearchInput.styles';
import ResetInputSvg from './ResetInputSvg/ResetInputSvg';
import SearchInputSvg from './SearchInputSvg/SearchInputSvg';

// eslint-disable-next-line react/prop-types
const SearchInput = ({ resetSearch, isFocused, ...props }) => {
    return (
        <SearchInputContainer className={isFocused ? 'focused' : ''}>
            <InputItem>
                <SearchInputSvg />
                <Input {...props} type="text" placeholder={content.SEARCH} />
                {props.value && <ResetInputSvg onClick={resetSearch} />}
            </InputItem>
        </SearchInputContainer>
    );
};

SearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    resetSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default SearchInput;
