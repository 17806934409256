import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { SecondaryColor } from '../../../../../styles/colors';

export const MissionSectionSliderContainerOuter = styled.div`
    position: relative;
    width: 140.8rem;
    display: flex;
    justify-content: center;
    :hover {
        .mission-section-slider-button-prev,
        .mission-section-slider-button-next {
            visibility: visible;
            opacity: 1;
            transition: 0.6s ease;
        }
        .swiper-button-disabled {
            cursor: default;
            svg {
                cursor: default;
                path {
                    fill: #666b6e;
                }
            }
        }
    }
    .swiper-button-disabled {
        cursor: default;
        svg {
            cursor: default;
            path {
                fill: #666b6e;
            }
        }
    }

    @media (max-width: 768px) {
        .mission-section-slider-button-next {
            visibility: visible;
            opacity: 1;
            transition: 0.6s ease;
            bottom: 10rem;
            right: 6rem;
            top: auto;
        }
        :hover {
            .mission-section-slider-button-next {
                visibility: visible;
                opacity: 1;
                transition: 0.6s ease;
                bottom: 10rem;
                right: 6rem;
                top: auto;
            }
        }
        .mission-section-slider-button-prev {
            visibility: visible;
            opacity: 1;
            transition: 0.6s ease;
            bottom: 10rem;
            right: 10.4rem;
            left: auto;
            top: auto;
        }
        :hover {
            .mission-section-slider-button-prev {
                visibility: visible;
                opacity: 1;
                transition: 0.6s ease;
                bottom: 10rem;
                right: 10.4rem;
                left: auto;
                top: auto;
            }
        }
    }
    @media (max-width: 480px) {
        .mission-section-slider-button-next {
            visibility: visible;
            opacity: 1;
            transition: 0.6s ease;
            bottom: 8rem;
            right: 1.6rem;
            top: auto;
        }
        :hover {
            .mission-section-slider-button-next {
                visibility: visible;
                opacity: 1;
                transition: 0.6s ease;
                bottom: 8rem;
                right: 1.6rem;
                top: auto;
            }
        }
        .mission-section-slider-button-prev {
            visibility: visible;
            opacity: 1;
            transition: 0.6s ease;
            bottom: 8rem;
            right: 6.4rem;
            top: auto;
        }
        :hover {
            .mission-section-slider-button-prev {
                visibility: visible;
                opacity: 1;
                transition: 0.6s ease;
                bottom: 8rem;
                right: 6.4rem;
                top: auto;
            }
        }
    }
`;
export const MissionSectionSliderContainer = styled.div`
    padding-top: 12rem;
    padding-bottom: 12rem;
    @media (max-width: 768px) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
    @media (max-width: 480px) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
`;
export const MissionSectionSliderSlideContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
export const MissionSectionSliderSlideLeft = styled.div`
    position: relative;
    width: 65rem;
    height: 62rem;
    margin-right: 2rem;
    @media (max-width: 480px) {
        width: 28.8rem;
        height: 27.6rem;
    }
`;
export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
export const MissionSectionSliderSlideContent = styled.div`
    position: relative;
    width: 52.2rem;
    height: 62rem;
    margin: 0 auto;
    p:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        margin: 0;
        margin-top: 2.4rem;
        padding-bottom: 7.4rem;
    }
    @media (max-width: 480px) {
        margin-top: 2rem;
        padding-bottom: 5.4rem;
    }
`;
export const MissionSectionSliderSlidePreTitle = styled.p`
    font-size: 2rem;
    line-height: 2.6rem;
    color: ${SecondaryColor};
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
`;

export const MissionSectionSliderSlideOverhead = styled.h3`
    margin-bottom: 0.8rem;
`;
export const MissionSectionSliderSlideTitle = styled.h2`
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        margin-bottom: 1.6rem;
    }
`;
export const MissionSectionSliderSlideText = styled.p`
    margin-bottom: 1.6rem;
`;
export const MissionSectionSliderSlideSignature = styled.p`
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    @media (max-width: 768px) {
        margin-top: 4.4rem;
        margin-bottom: 4.4rem;
    }
`;

export const VideoBlock = styled.div`
    width: 100%;
    height: 100%;
`;

export const Player = styled(ReactPlayer)`
    width: 100%;
`;
