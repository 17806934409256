import React from 'react';
import { Container, ContainerFull } from '../../../../styles/commons';
import { ProductsSectionTitle } from './ProductsSection.styles';
import { content } from '../../../../commons/content/index';
import { Button } from '../../Button';
import { ProductsSectionSlider } from '../../../Sliders/ProductsSectionSlider';

export const ProductsSection = () => {
    return (
        <ContainerFull>
            <Container>
                <ProductsSectionTitle>Наши продукты</ProductsSectionTitle>
                <ProductsSectionSlider />
                <Button link="/products" title={content.PRODUCTS_SECTION_BUTTON} />
            </Container>
        </ContainerFull>
    );
};
export default ProductsSection;
