import styled from 'styled-components';
import { Pink } from 'styles/colors';

export const RiskRakaGrudiInfoBlock = styled.div`
    width: 100%;
`;
export const RiskRakaInfoTitleBg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #a64f7e1a;
`;
export const RiskRakaInfoTitle = styled.h2`
    width: 100%;
    padding: 1.5rem 0;
    color: ${(props) => props.color || '#292c2f'};
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || 'center'};
    text-align: ${(props) => props.textAlign || 'center'};
    font-size: 4.8rem;
    line-height: 5.8rem;
    @media (max-width: 768px) {
        font-size: 3.2rem;
        line-height: 3.8rem;
    }
    @media (max-width: 480px) {
        font-size: 2.4rem;
        line-height: 2.9rem;
    }
`;
export const RiskRakaColumnItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.8rem;
`;

export const RiskRakaColumnItemH3 = styled.h3`
    text-align: center;
    padding-bottom: 0.8rem;
    color: ${Pink};
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 0;
    }
`;
export const RiskRakaColumnItemP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;
    svg {
        min-width: 1.6rem;
        min-height: 1.6rem;
        width: 1.6rem;
        height: 1.6rem;
    }
`;

export const RiskRakaInfoContent = styled.div`
    padding-top: 3.2rem;
    padding-bottom: ${(props) => (props.paddingBottom ? '12rem' : '')};
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    @media (max-width: 768px) {
        padding-top: 2.4rem;
        gap: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-top: 2rem;
        gap: 2rem;
    }
`;

export const RiskRakaInfoBlocks = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || ''};
    @media (max-width: 480px) {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 2rem;
    }
`;

export const RiskRakaInfoBlockWithIcon = styled.div`
    width: 34.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    @media (max-width: 768px) {
        width: 18.9rem;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
    p {
        text-align: center;
    }
`;

export const IndinolImage = styled.img`
    width: 132rem;
    @media (max-width: 768px) {
        width: 64.8rem;
    }
    @media (max-width: 480px) {
        width: 28.8rem;
    }
`;
