import React from 'react';

export default function PromohubInfoSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
            <g clipPath="url(#clip0_1590_2609)">
                <path
                    d="M23 45C35.1503 45 45 35.1503 45 23C45 10.8497 35.1503 1 23 1C10.8497 1 1 10.8497 1 23C1 35.1503 10.8497 45 23 45Z"
                    stroke="#FF7100"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M23 33.7773H23.025V33.8023H23V33.7773Z"
                    stroke="#FF7100"
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
                <path d="M23 28V12" stroke="#FF7100" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1590_2609">
                    <rect width="46" height="46" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
