import React from 'react';
import { Container, ContainerFull } from '../../../../styles/commons';
import { OurPartnersSectionContainer, OurPartnersSectionTitle } from './OurPartnersSection.styles';
import Img1 from '../../../../assets/images/OurPartners/1.png';
import Img2 from '../../../../assets/images/OurPartners/2.png';
import Img3 from '../../../../assets/images/OurPartners/3.png';
import Img4 from '../../../../assets/images/OurPartners/4.png';
import Img5 from '../../../../assets/images/OurPartners/5.png';
import Img6 from '../../../../assets/images/OurPartners/6.png';
import Img7 from '../../../../assets/images/OurPartners/7.png';

export const OurPartnersSection = () => {
    return (
        <ContainerFull paddingBottom="12rem">
            <Container>
                <OurPartnersSectionTitle>Наши партнеры</OurPartnersSectionTitle>
                <OurPartnersSectionContainer>
                    <div>
                        <img src={Img1} alt="Merz" />
                    </div>
                    <div>
                        <img src={Img2} alt="Merck" />
                    </div>
                    <div>
                        <img src={Img3} alt="Ferrer" />
                    </div>
                    <div>
                        <img src={Img4} alt="Минская Фармация" />
                    </div>
                    <div>
                        <img src={Img5} alt="Аптека Групп" />
                    </div>
                    <div>
                        <img src={Img6} alt="Inter Farmax" />
                    </div>
                    <div>
                        <img src={Img7} alt="Gerot Lannach" />
                    </div>
                </OurPartnersSectionContainer>
            </Container>
        </ContainerFull>
    );
};

export default OurPartnersSection;
