import React from 'react';
import { Container, ContainerFull } from '../../../../styles/commons';
import { content } from '../../../../commons/content';
import { WeOfferContainer, WeOfferTitle } from './WeOffer.styles';
import Img1 from '../../../../assets/images/OurCareerPage/WeOffer/1.png';
import Img2 from '../../../../assets/images/OurCareerPage/WeOffer/2.png';
import Img3 from '../../../../assets/images/OurCareerPage/WeOffer/3.png';
import Img4 from '../../../../assets/images/OurCareerPage/WeOffer/4.png';
import Img5 from '../../../../assets/images/OurCareerPage/WeOffer/5.png';
import Img6 from '../../../../assets/images/OurCareerPage/WeOffer/6.png';
import WeOfferItem from './WeOfferItem/WeOfferItem';

export default function WeOffer() {
    return (
        <ContainerFull paddingBottom="12rem">
            <Container>
                <WeOfferTitle>{content.WE_OFFER_SECTION_CAREER_TITLE}</WeOfferTitle>
                <WeOfferContainer>
                    <WeOfferItem text={content.WE_OFFER_SECTION_CAREER_TEXT1} img={Img1} />
                    <WeOfferItem text={content.WE_OFFER_SECTION_CAREER_TEXT2} img={Img2} />
                    <WeOfferItem text={content.WE_OFFER_SECTION_CAREER_TEXT3} img={Img3} />
                    <WeOfferItem text={content.WE_OFFER_SECTION_CAREER_TEXT4} img={Img4} />
                    <WeOfferItem text={content.WE_OFFER_SECTION_CAREER_TEXT5} img={Img5} />
                    <WeOfferItem text={content.WE_OFFER_SECTION_CAREER_TEXT6} img={Img6} />
                </WeOfferContainer>
            </Container>
        </ContainerFull>
    );
}
