import React from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer, ButtonItem } from './Button.styles';

// eslint-disable-next-line react/prop-types
const Button = ({ title, link, backgroundcolorbutton, border, search, onClick, className, disabled }) => {
    return (
        <ButtonContainer search={search} onClick={onClick} className={className} disabled={disabled}>
            <ButtonItem search={search} to={link} backgroundcolorbutton={backgroundcolorbutton} border={border}>
                {title}
            </ButtonItem>
        </ButtonContainer>
    );
};

Button.defaultProps = {
    backgroundcolorbutton: undefined,
    border: undefined,
    search: false,
};

Button.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    backgroundcolorbutton: PropTypes.string,
    border: PropTypes.string,
    search: PropTypes.bool,
};

export default Button;
