import styled from 'styled-components';
import { White } from 'styles/colors';
import { HoverColor, PrimaryColor } from '../../../../styles/colors';

export const SearchInputContainer = styled.div`
    width: 100%;
    max-width: 110.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.2rem 0;
    border-bottom: 0.1rem solid #d9dadb;
    transition: 0.6s ease;
    position: relative;
    &.focused {
        transition: 0.6s ease;
        border-bottom: 0.1rem solid ${PrimaryColor};
    }
    :hover {
        border-bottom: 0.1rem solid ${HoverColor};
        transition: 0.6s ease;
        @media (max-width: 768px) {
            border-bottom: 0.1rem solid ${PrimaryColor};
        }
    }
    margin-bottom: 3.2rem;
    @media (max-width: 768px) {
        margin-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 2rem;
    }
    svg {
        min-width: 2.4rem;
        min-height: 2.4rem;
    }
`;

export const InputItem = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    svg {
        /* cursor: pointer; */
        path {
            transition: 0.3s ease;
        }
        /* &:hover {
            path {
                fill: ${HoverColor};
                transition: 0.3s ease;
                @media (max-width: 768px) {
                    fill: ${PrimaryColor};
                }
            }
        } */
    }
`;

export const Input = styled.input`
    font-family: 'HelveticaNeueCyr', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
    /* identical to box height, or 26px */
    color: #666b6e;
    border: 0;
    padding: 0.4rem 2rem;
    ::placeholder {
        color: #666b6e;
        overflow: none;
        font-size: 1.6rem;
        line-height: 2.4rem;
        min-height: 2.4rem;
        padding: 12rem 0;
    }
    background-color: ${White};
    position: relative;
    width: 100%;
    &:focus {
        outline: none;
        color: #292c2f;
    }
`;
