export const White = '#ffffff';
export const Default = '#000018';
export const DarkWhite = '#c4c2c2';
export const Black = '#000000';
export const LightBlack = '#2d3033';
export const Grey = '#666b6e';
export const Grey2 = '#d9dadb';
export const LightGrey = '#f8f8f8';
export const LightGrey2 = '#f0f0f0';
export const LightGrey3 = '#a3a3a3';
export const DarkGrey = '#3E3E40';
export const Orange = '#EF7C00';
export const LightOrange = '#fbf0d4';
export const Pink = '#C70B6F';
export const PinkLight = 'rgba(166, 79, 126, 0.1);';
export const PinkOpacity = 'rgba(220, 52, 67, 0.6);';
export const OrangeDark = '#E55829';
export const Burgundy = '#DC344E';
// Current in Use
export const Background = '#ffffff';
export const PrimaryColor = '#FF7100';
export const HoverColor = '#C70B6F';
export const SecondaryColor = '#666B6E';
export const TextColor = '#292C2F';
