import styled from 'styled-components';
import { Background, HoverColor, PrimaryColor } from '../../../../../styles/colors';

export const HistorySectionItemContainer = styled.div`
    display: flex;
    flex: 1 0 50%;
    flex-direction: column;
    @media (max-width: 768px) {
        flex: 0 0 100%;
        &:nth-child(4n + 2) {
            display: none;
        }
        &:nth-child(4n + 3) {
            display: none;
        }
    }
    &:nth-child(even) {
        h2,
        p {
            justify-content: flex-start;
            padding-left: 10rem;
            text-align: left;
            @media (max-width: 768px) {
                padding-left: 3.2rem;
            }
        }
    }
    &:nth-child(odd) {
        h2,
        p {
            padding-right: 10rem;
            text-align: right;
            justify-content: flex-end;
            @media (max-width: 768px) {
                justify-content: flex-start;
                padding-left: 3.2rem;
                text-align: left;
                padding-right: 0;
            }
        }
    }
    &:nth-child(4n + 4) {
        h2 {
            color: ${HoverColor};
        }
    }
    &:nth-child(4n + 1) {
        h2 {
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
                width: 2rem;
                height: 2rem;
                background-color: ${Background};
                border-radius: 100%;
                border: 0.4rem solid ${PrimaryColor};
                z-index: 3;
                @media (max-width: 768px) {
                    left: -2rem;
                    right: auto;
                }
            }
        }
    }
    &:nth-child(4n) {
        h2 {
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: -2rem;
                transform: translate(50%, -50%);
                width: 2rem;
                height: 2rem;
                background-color: ${Background};
                border-radius: 100%;
                border: 0.4rem solid ${HoverColor};
                z-index: 3;
            }
        }
    }
`;
export const HistorySectionItemTitle = styled.h2`
    display: flex;
    position: relative;
    color: ${PrimaryColor};
    margin-bottom: 1.6rem;
`;
export const HistorySectionItemText = styled.p`
    display: flex;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
        padding-bottom: 1.6rem;
    }
    &:last-child {
        padding-bottom: 0;
    }
`;
export const HistorySectionItemTextBold = styled.p`
    display: flex;
    font-weight: 550;
`;
