import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Background, HoverColor, PrimaryColor } from '../../../../styles/colors';

export const PublicationsSectionTitle = styled.h2`
    padding-bottom: 3.2rem;
    @media (max-width: 768px) {
        padding-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        padding-bottom: 2rem;
    }
`;

export const PublicationsSectionButton = styled(Link)`
    margin-top: 6.4rem;
    width: 26.6rem;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Background};
    background-color: ${PrimaryColor};
    transition: 0.6s ease;
    font-size: 2rem;
    line-height: 2rem;
    border-radius: 3.2rem;
    &:hover {
        background-color: ${HoverColor};
        transition: 0.6s ease;
        @media (max-width: 768px) {
            background-color: ${PrimaryColor};
        }
    }
    @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    @media (max-width: 480px) {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
`;
