/* eslint-disable no-debugger */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Container } from 'styles/commons';
import { staff } from 'commons/staff';
import {
    EmployeeContainer,
    EmployeePhoto,
    EmployeeName,
    EmployeePost,
    TeamSectionContainer,
} from './TeamSection.styles';
import ImageAlt from '../../../HOC/ImageAlt/ImageAlt';
import { ContainerFull } from '../../../../styles/commons';
import { isTablet } from '../../../../commons/constants';
import TeamSlider from './TeamSlider/TeamSlider';

export const TeamSection = () => {
    return (
        <ContainerFull paddingBottom="12rem">
            <Container id="team">
                {(isTablet && <TeamSlider staff={staff} />) ||
                    (!isTablet && (
                        <TeamSectionContainer>
                            {staff.map((item) => (
                                <EmployeeContainer>
                                    <EmployeePhoto>
                                        <ImageAlt src={item.photo} alt={item.photo} />
                                    </EmployeePhoto>
                                    <EmployeeName>{item.name}</EmployeeName>
                                    <EmployeePost>{item.post}</EmployeePost>
                                </EmployeeContainer>
                            ))}
                        </TeamSectionContainer>
                    ))}
            </Container>
        </ContainerFull>
    );
};

export default TeamSection;
