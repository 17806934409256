import styled from 'styled-components';

export const ProductsSectionTitle = styled.h2`
    margin-bottom: 3.2rem;
    @media (max-width: 768px) {
        margin-bottom: 2.4rem;
    }
    @media (max-width: 480px) {
        margin-bottom: 2rem;
    }
`;
