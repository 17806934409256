import styled from 'styled-components';
import { Background, HoverColor, PrimaryColor } from '../../../../styles/colors';

export const SlideImg = styled.div`
    max-height: 80rem;
    height: 100%;
    height: 80rem;
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: url(${({ imgWeb }) => imgWeb});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media (max-width: 768px) {
        background-image: url(${({ imgTab }) => imgTab});
        max-height: 64.9rem;
        height: 64.9rem;
    }
    @media (max-width: 480px) {
        background-image: url(${({ imgMob }) => imgMob});
        max-height: 53.2rem;
        height: 53.2rem;
    }
`;
export const ContainerMaxSlide = styled.div`
    padding-top: 14rem;
    width: 123.2rem;
    @media (max-width: 768px) {
        width: 64.8rem;
        padding-top: 10rem;
    }
    @media (max-width: 480px) {
        padding-top: 6rem;
        width: 28.8rem;
    }
`;
export const HomeSlideContainer = styled.div`
    max-width: 132rem;
    @media (max-width: 768px) {
        max-width: 64.8rem;
    }
    @media (max-width: 480px) {
        max-width: 28.8rem;
    }
`;

export const HomeSliderButtonNextContainer = styled.div`
    max-width: 132rem;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    bottom: 50%;
    @media (max-width: 768px) {
        max-width: 64.8rem;
        top: auto;
        bottom: 16%;
    }
    @media (max-width: 480px) {
        max-width: 28.8rem;
        bottom: 15%;
        top: auto;
    }
`;
export const HomeSliderPaginationContainer = styled.div`
    max-width: 132rem;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
    bottom: 13%;
    @media (max-width: 768px) {
        max-width: 64.8rem;
    }
    @media (max-width: 480px) {
        max-width: 28.8rem;
        bottom: 10%;
    }
`;
export const MainSliderContainer = styled.div`
    position: relative;
    overflow: hidden;
`;
export const HomeSliderButtons = styled.div`
    svg {
        cursor: pointer;
        path {
            transition: 0.6s ease;
        }
    }
    :hover {
        svg {
            path {
                transition: 0.6s ease;
                fill: ${HoverColor};
                @media (max-width: 768px) {
                    fill: ${PrimaryColor};
                }
            }
        }
    }
    .swiper-button-disabled:hover {
        cursor: default;
        svg {
            cursor: default;
            path {
                fill: #666b6e;
            }
        }
    }
`;

export const SlideTitleH1 = styled.h1`
    font-size: 6rem;
    line-height: 7.2rem;
    color: ${Background};
    font-size: 6rem;
    margin-bottom: 2rem;
    width: 76.2rem;
    @media (max-width: 768px) {
        width: 39.8rem;
        font-size: 4.4rem;
        line-height: 5.3rem;
        margin-bottom: 1.6rem;
    }
    @media (max-width: 480px) {
        font-size: 3.2rem;
        line-height: 3.8rem;
        width: 100%;
    }
`;

export const SlideTitle = styled.h2`
    font-size: 6rem;
    line-height: 7.2rem;
    color: ${Background};
    margin-bottom: 2rem;
    width: 76.2rem;
    @media (max-width: 768px) {
        width: 39.8rem;
        font-size: 4.4rem;
        line-height: 5.3rem;
        margin-bottom: 1.6rem;
    }
    @media (max-width: 480px) {
        font-size: 3.2rem;
        line-height: 3.8rem;
        width: 100%;
    }
`;

export const SlideSubtitle = styled.p`
    color: ${Background};
    font-size: 2rem;
    line-height: 2.6rem;
    margin-bottom: 6.4rem;
    width: 65rem;
    @media (max-width: 768px) {
        width: 39.8rem;
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-bottom: 4.4rem;
    }
    @media (max-width: 480px) {
        font-size: 1.4rem;
        line-height: 2.1rem;
        width: 100%;
    }
`;
