import React from 'react';
import ContentLoader from 'react-content-loader';
import { PublicationItem } from '../Publication.styles';
import { FlexRow } from '../../../../styles/commons';
import { isMobile, isTablet } from '../../../../commons/constants';

const PublicationFullSkeleton = () => {
    return (
        <PublicationItem>
            <FlexRow alignItems="flex-start" justifyContent="space-between">
                {isMobile && (
                    <ContentLoader
                        speed={3}
                        width="28.8rem"
                        height="43.1rem"
                        viewBox="0 0 288 431"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="0" y="0" rx="0" ry="0" width="28.8rem" height="17.4rem" />
                        <rect x="0" y="19.4rem" rx="0" ry="0" width="28.8rem" height="4.4rem" />
                        <rect x="0" y="25.4rem" rx="0" ry="0" width="28.8rem" height="8.4rem" />
                        <rect x="0" y="35.4rem" rx="0" ry="0" width="8.1rem" height="2.1rem" />
                        <rect x="18.4rem" y="35.4rem" rx="0" ry="0" width="10.4rem" height="2.1rem" />
                    </ContentLoader>
                )}

                {isTablet && !isMobile && (
                    <ContentLoader
                        speed={3}
                        width="76.8rem"
                        height="18rem"
                        viewBox="0 0 768 212"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="33.4rem" y="0" rx="3" ry="3" width="31.4rem" height="4.4rem" />
                        <rect x="33.4rem" y="6rem" rx="3" ry="3" width="31.4rem" height="8.4rem" />
                        <rect x="0" y="0" rx="3" ry="3" width="31.4rem" height="18.9rem" />
                        <rect x="33.4rem" y="16.8rem" rx="0" ry="0" width="8.1rem" height="2.1rem" />
                        <rect x="54.4rem" y="16.8rem" rx="0" ry="0" width="10.4rem" height="2.1rem" />
                    </ContentLoader>
                )}

                {!isMobile && !isTablet && (
                    <ContentLoader
                        speed={3}
                        width="100%"
                        height="21.2rem"
                        viewBox="0 0 1320 212"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="44.7rem" y="0" rx="0" ry="0" width="87.3rem" height="3rem" />
                        <rect x="44.7rem" y="5rem" rx="0" ry="0" width="87.3rem" height="5rem" />
                        <rect x="0" y="0" rx="0" ry="0" width="42.7rem" height="21.2rem" />
                        <rect x="44.7rem" y="18.6rem" rx="0" ry="0" width="10rem" height="2.6rem" />
                        <rect x="119.4rem" y="18.6rem" rx="0" ry="0" width="12.5rem" height="2.6rem" />
                    </ContentLoader>
                )}
            </FlexRow>
        </PublicationItem>
    );
};

export default PublicationFullSkeleton;
