/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './MissionSlider.styles.css';
import { Container } from 'styles/commons';
import {
    Img,
    MissionSectionSliderContainer,
    MissionSectionSliderContainerOuter,
    MissionSectionSliderSlideContainer,
    MissionSectionSliderSlideContent,
    MissionSectionSliderSlideLeft,
    MissionSectionSliderSlideOverhead,
    MissionSectionSliderSlidePreTitle,
    MissionSectionSliderSlideSignature,
    MissionSectionSliderSlideText,
    MissionSectionSliderSlideTitle,
} from './MissionSlider.styles';
import { content } from '../../../../../commons/content';
import MissionImg2 from '../../../../../assets/images/MissionSection/2.png';
import MissionImg3 from '../../../../../assets/images/MissionSection/3.png';
import MissionImg4 from '../../../../../assets/images/MissionSection/4.png';
import { Button } from '../../../Button';

export default function MissionSlider() {
    return (
        <MissionSectionSliderContainerOuter>
            <Container>
                <MissionSectionSliderContainer>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        slidesPerView={1}
                        navigation={{
                            nextEl: '.mission-section-slider-button-next',
                            prevEl: '.mission-section-slider-button-prev',
                        }}
                        spaceBetween={20}
                        pagination={{
                            el: '.mission-section-slider-pagination',
                            clickable: true,
                        }}
                    >
                        <SwiperSlide>
                            <MissionSectionSliderSlideContainer>
                                <MissionSectionSliderSlideLeft>
                                    <Img src={MissionImg2} />
                                </MissionSectionSliderSlideLeft>
                                <MissionSectionSliderSlideContent>
                                    <MissionSectionSliderSlidePreTitle>
                                        {content.MISSION_SECTION_PRETITLE}
                                    </MissionSectionSliderSlidePreTitle>
                                    <MissionSectionSliderSlideTitle>
                                        {content.MISSION_SECTION_TITLE2}
                                    </MissionSectionSliderSlideTitle>
                                    <MissionSectionSliderSlideText>
                                        {content.MISSION_SECTION_TEXT2}
                                    </MissionSectionSliderSlideText>
                                    <MissionSectionSliderSlideSignature />
                                </MissionSectionSliderSlideContent>
                            </MissionSectionSliderSlideContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                            <MissionSectionSliderSlideContainer>
                                <MissionSectionSliderSlideLeft>
                                    <Img src={MissionImg3} />
                                </MissionSectionSliderSlideLeft>
                                <MissionSectionSliderSlideContent>
                                    <MissionSectionSliderSlidePreTitle>
                                        {content.MISSION_SECTION_PRETITLE}
                                    </MissionSectionSliderSlidePreTitle>
                                    <MissionSectionSliderSlideTitle>
                                        {content.MISSION_SECTION_TITLE3}
                                    </MissionSectionSliderSlideTitle>
                                    <MissionSectionSliderSlideOverhead>
                                        {content.MISSION_SECTION_OVERHEAD3_1}
                                    </MissionSectionSliderSlideOverhead>
                                    <MissionSectionSliderSlideText>
                                        {content.MISSION_SECTION_TEXT3_1}
                                    </MissionSectionSliderSlideText>
                                    <MissionSectionSliderSlideOverhead>
                                        {content.MISSION_SECTION_OVERHEAD3_2}
                                    </MissionSectionSliderSlideOverhead>
                                    <MissionSectionSliderSlideText>
                                        {content.MISSION_SECTION_TEXT3_2}
                                    </MissionSectionSliderSlideText>
                                    <MissionSectionSliderSlideSignature>
                                        {content.MISSION_SECTION_SIGNATURE3}
                                    </MissionSectionSliderSlideSignature>
                                    <MissionSectionSliderSlideSignature />
                                    <Button title={content.MISSION_SECTION_BUTTON3} link="/our-values" />
                                </MissionSectionSliderSlideContent>
                            </MissionSectionSliderSlideContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                            <MissionSectionSliderSlideContainer>
                                <MissionSectionSliderSlideLeft>
                                    <Img src={MissionImg4} />
                                </MissionSectionSliderSlideLeft>
                                <MissionSectionSliderSlideContent>
                                    <MissionSectionSliderSlidePreTitle>
                                        {content.MISSION_SECTION_PRETITLE}
                                    </MissionSectionSliderSlidePreTitle>
                                    <MissionSectionSliderSlideTitle>
                                        {content.MISSION_SECTION_TITLE4}
                                    </MissionSectionSliderSlideTitle>
                                    <MissionSectionSliderSlideText>
                                        {content.MISSION_SECTION_TEXT4}
                                    </MissionSectionSliderSlideText>
                                    <MissionSectionSliderSlideSignature />
                                    <Button title={content.MISSION_SECTION_BUTTON4} link="/our-history" />
                                </MissionSectionSliderSlideContent>
                            </MissionSectionSliderSlideContainer>
                        </SwiperSlide>
                        <div className="mission-section-slider-pagination" />
                    </Swiper>
                </MissionSectionSliderContainer>
            </Container>
            <div className="mission-section-slider-button-prev">
                <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                    <path d="M15.975 2L17.75 3.775L9.525 12L17.75 20.225L15.975 22L5.975 12L15.975 2Z" fill="#FF7100" />
                </svg>
            </div>
            <div className="mission-section-slider-button-next">
                <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                    <path d="M8.025 22L6.25 20.225L14.475 12L6.25 3.775L8.025 2L18.025 12L8.025 22Z" fill="#FF7100" />
                </svg>
            </div>
        </MissionSectionSliderContainerOuter>
    );
}
