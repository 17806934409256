export const currentVacancies = [
    // {
    //     id: 1,
    //     title: 'Медицинский представитель по г. Минск',
    //     category: 'Продажи и маркетинг',
    //     workingFormat: 'Полное рабочее время',
    //     postDate: '22.02.2023',
    //     location: 'Минск',
    //     buttonLink: '/',
    // },
    // {
    //     id: 2,
    //     title: 'Медицинский представитель по г. Минск',
    //     category: 'Продажи и маркетинг',
    //     workingFormat: 'Полное рабочее время',
    //     postDate: '22.02.2023',
    //     location: 'Минск',
    //     buttonLink: '/',
    // },
    // {
    //     id: 3,
    //     title: 'Медицинский представитель по г. Минск',
    //     category: 'Продажи и маркетинг',
    //     workingFormat: 'Полное рабочее время',
    //     postDate: '22.02.2023',
    //     location: 'Минск',
    //     buttonLink: '/',
    // },
    // {
    //     id: 4,
    //     title: 'Медицинский представитель по г. Минск',
    //     category: 'Продажи и маркетинг',
    //     workingFormat: 'Полное рабочее время',
    //     postDate: '22.02.2023',
    //     location: 'Минск',
    //     buttonLink: '/',
    // },
    // {
    //     id: 5,
    //     title: 'Медицинский представитель по г. Минск',
    //     category: 'Продажи и маркетинг',
    //     workingFormat: 'Полное рабочее время',
    //     postDate: '22.02.2023',
    //     location: 'Минск',
    //     buttonLink: '/',
    // },
    // {
    //     id: 6,
    //     title: 'Медицинский представитель по г. Минск',
    //     category: 'Продажи и маркетинг',
    //     workingFormat: 'Полное рабочее время',
    //     postDate: '22.02.2023',
    //     location: 'Минск',
    //     buttonLink: '/',
    // },
];
