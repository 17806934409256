/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-use-before-define */
import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CountryBar, CountryHeaderBar, LogoBar, MenuBar, NavContainer, SearchAll } from './styles';
import './Header.css';
// eslint-disable-next-line import/no-named-as-default

import LogoHeader from './LogoHeader';
import Menu from './Menu/Menu';
import DropDownMenu from './DropDownMenuCountry/DropDownMenuCountry';
import Burger from './Menu/Burger/Burger';
import { MenuContext } from '../../../context/context';
import { GlobalSearch } from '../../GlobalSearch/GlobalSearch';
import MobileMenu from './MobileMenu/MobileMenu';

const Header = ({ selectCountry }) => {
    const [GlobalSearchState, setGlobalSearchState] = useState(false);
    const handleClick = () => {
        setGlobalSearchState(!GlobalSearchState);
    };
    const { changeOpenState, changeVisibleState, open, setOpenStateFalse } = useContext(MenuContext);

    const location = useLocation();

    useEffect(() => {
        setGlobalSearchState(false);
    }, [location]);

    return (
        <>
            <div className="header">
                <NavContainer>
                    <LogoBar>
                        <Link to="/">
                            <LogoHeader changeOpenState={setOpenStateFalse} />
                        </Link>
                    </LogoBar>
                    <MenuBar>
                        <Menu />
                        <MobileMenu open={open} changeOpenState={changeOpenState} />
                    </MenuBar>
                    <CountryBar>
                        <SearchAll onClick={handleClick}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2.4rem"
                                height="2.4rem"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M15 15L19 19M1 9C1 11.1217 1.84285 13.1566 3.34315 14.6569C4.84344 16.1571 6.87827 17 9 17C11.1217 17 13.1566 16.1571 14.6569 14.6569C16.1571 13.1566 17 11.1217 17 9C17 6.87827 16.1571 4.84344 14.6569 3.34315C13.1566 1.84285 11.1217 1 9 1C6.87827 1 4.84344 1.84285 3.34315 3.34315C1.84285 4.84344 1 6.87827 1 9Z"
                                    stroke="#666B6E"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </SearchAll>
                        <CountryHeaderBar>{selectCountry === 'none' ? null : <DropDownMenu />}</CountryHeaderBar>
                        <Burger open={open} onClick={changeOpenState} />
                    </CountryBar>
                </NavContainer>
            </div>
            {GlobalSearchState && <GlobalSearch onClick={handleClick} />}
        </>
    );
};

export default Header;
