// import React from 'react';
import styled from 'styled-components';
import { screenSizeSmall } from 'styles/screenSize';
import { HoverColor, PrimaryColor } from './colors';

export const SectionBlock = styled.section`
    padding: 5rem;
`;
export const GlobalContainer = styled.div`
    padding-top: 10rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    @media (max-width: 768px) {
        padding-top: 7.7rem;
    }
    @media (max-width: 480px) {
        padding-top: 6.4rem;
    }
`;
export const ContainerFull = styled.div`
    background: ${(props) => props.backgroundColor || ''};
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top: ${(props) =>
        (props.isTablet && props.paddingTop === '12rem' && '10rem') ||
        (props.isMobile && props.paddingTop === '12rem' && '8rem') ||
        props.paddingTop ||
        '12rem'};
    padding-bottom: ${(props) => props.paddingBottom || ''};
    @media (max-width: 768px) {
        padding-top: ${(props) =>
            (props.isTablet && props.paddingTop === '12rem' && '10rem') ||
            (props.isMobile && props.paddingTop === '12rem' && '8rem') ||
            props.paddingTop ||
            '10rem'};
        padding-bottom: ${(props) =>
            (props.isTablet && props.paddingBottom === '12rem' && '10rem') ||
            (props.isMobile && props.paddingBottom === '12rem' && '8rem') ||
            (props.paddingBottom === '12rem' && '10rem') ||
            ''};
    }
    @media (max-width: 480px) {
        padding-top: ${(props) =>
            (props.isTablet && props.paddingTop === '12rem' && '10rem') ||
            (props.isMobile && props.paddingTop === '12rem' && '8rem') ||
            props.paddingTop ||
            '8rem'};
        padding-bottom: ${(props) =>
            (props.isTablet && props.paddingBottom === '12rem' && '10rem') ||
            (props.isMobile && props.paddingBottom === '12rem' && '8rem') ||
            (props.paddingBottom === '12rem' && '8rem') ||
            ''};
    }
`;
export const Container = styled.div`
    display: flex;
    width: 132rem;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 64.8rem;
    }
    @media (max-width: 480px) {
        width: 28.8rem;
    }
`;

export const FlexRow = styled.div`
    height: 100%;
    display: flex;
    align-items: ${(props) => props.alignItems || 'center'};
    justify-content: ${(props) => props.justifyContent || 'center'};
    flex-wrap: wrap;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.alignItems || 'center'};
`;

export const ExternalLink = styled.a`
    :hover {
        color: ${HoverColor};
        transition: 0.6s ease;
        @media (max-width: 768px) {
            color: ${PrimaryColor};
        }
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
`;

export const RowAlignCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: no-wrap;
    height: 70vh;

    @media screen and (max-width: 1600) {
        height: 65vh;
    }

    @media screen and (max-width: 400px) {
        height: 60vh;
    }

    @media screen and (min-width: 2000px) {
        height: 75vh;
    }
`;

export const TotalCountText = styled.p`
    color: #666b6e;
    font-family: HelveticaNeueCyr;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.1rem;
    margin-bottom: 40px;

    @media screen and (max-width: 400px) {
        margin-bottom: 1.25rem;
    }
`;

export const FlexItem = styled.div`
    display: -webkit-flex;
    -webkit-flex-direction: row;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: ${screenSizeSmall}) {
        display: -webkit-flex;
        -webkit-flex-direction: column;
        display: flex;
        flex-direction: column;
        width: auto;
        margin: 0 auto;
    }
`;

export const RowCenter = styled(Row)`
    align-items: center;
`;

export const H1 = styled.h1`
    font-size: 3.2rem;
`;

export const H2 = styled.h2`
    font-size: 2.8rem;
`;

export const H3 = styled.h3`
    font-size: 2rem;
    color: #080000;
`;

export const P = styled.p`
    font-size: 1.6rem;
    line-height: 1.6;
    color: #000000;
`;

export const Span = styled.span`
    display: inline-block;
`;

export const Li = styled.li`
    line-height: 1.6;
`;

const Button = styled.button``;

export const ButtonLarge = styled(Button)``;
